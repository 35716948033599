import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Skeleton } from '@mantine/core';

import { sidebars } from '~/consts/sidebars';
import { CoachContext, useCoachContext } from '~/context/CoachContext';

const IndexRoute = () => {
  const { coachMeLoading } = useCoachContext();
  const coachContext = useContext(CoachContext);

  const navbar = sidebars({ educationFormat: coachContext.school?.educationFormat }).find(
    () => true,
  );

  if (navbar?.link) return <Navigate replace to={navbar.link} />;

  if (coachMeLoading) {
    return (
      <div className="bg-white p-10 md:p-15 mt-5 rounded-2xl">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );
  }

  return <div />;
};

export default IndexRoute;
