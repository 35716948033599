import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Skeleton } from '@mantine/core';

import { IStudentsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { AddIcon, SchoolIcon } from '@chess-class/sandbox/icons';

import { CoachContext, useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { GroupCard } from '~mGroups/groups/components/molecules/GroupCard';
import { GroupCreateModal } from '~mGroups/groups/components/organisms/GroupCreateModal';
import { GroupDeleteModal } from '~mGroups/groups/components/organisms/GroupDeleteModal';
import { GroupEditModal } from '~mGroups/groups/components/organisms/GroupEditModal';
import { classColors } from '~mGroups/groups/consts/classColors';
import {
  groupsMyPageActions,
  IGroupsMyPageActions,
} from '~mGroups/groups/consts/pageActions/IGroupsMyPageActions';
import StudentsTable from '~mGroups/students/components/organisms/StudentsTable';
import { studentsFilters } from '~mGroups/students/consts/filters/studentsFilters';
import { IStudentsPageActions } from '~mGroups/students/consts/pageActions/IStudentsPageActions';

export const GroupsMyPage: FC = () => {
  const { t } = useTranslation();
  const { myClasses, school, schoolLoading } = useCoachContext();
  const { actioned, filtered, onFilter, setFiltered, setPartialActioned } = useUrlFilteredActioned<
    IStudentsRequest,
    IGroupsMyPageActions
  >(studentsFilters, groupsMyPageActions);
  const coachContext = useContext(CoachContext);
  const educationFormat = coachContext.school?.educationFormat;

  return (
    <div className="flex flex-col gap-8">
      <PageMeta openMenuKeys={['classes']} selectedMenuKeys={['classes-my']} title={t('classes')} />

      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="font-bold mb-0">
            {educationFormat == 'GENERAL' ? t('classesPage.myClasses') : t('classesPage.myGroups')}
          </h1>
          <div className="flex items-center gap-2 text-gray-500 font-medium">
            <SchoolIcon /> {t('school')}{' '}
            {schoolLoading ? <Skeleton height={18} width={80} /> : school?.schoolName}
          </div>
        </div>
        <Button
          disabled={schoolLoading}
          onClick={() => setPartialActioned({ action: 'createClass' })}
          rightIcon={<AddIcon className="w-4 h-4" />}
        >
          {educationFormat == 'GENERAL' ? t('classesPage.addClass') : t('classesPage.addGroup')}
        </Button>
      </div>

      <div className="flex flex-wrap gap-6">
        {!myClasses ? (
          <>
            <Skeleton className="h-22 w-64 rounded-xl" />
            <Skeleton className="h-22 w-64 rounded-xl" />
          </>
        ) : (
          myClasses?.map((classItem, index) => (
            <GroupCard
              color={classColors[index % classColors.length]}
              group={classItem}
              key={index}
              onDelete={(id) => setPartialActioned({ action: 'deleteClass', actionId: id })}
              onEdit={(id) => setPartialActioned({ action: 'editClass', actionId: id })}
            />
          ))
        )}
      </div>

      <StudentsTable
        actioned={actioned as IStudentsPageActions}
        addStudent
        educationFormat={school?.educationFormat}
        filtered={filtered}
        groupIds={myClasses?.map((group) => group.id)}
        isLoading={!myClasses}
        isNotFound={!myClasses?.length}
        onFilter={onFilter}
        schoolId={school?.schoolId}
        setFiltered={setFiltered}
        setPartialActioned={setPartialActioned}
        showTabs
      />

      {/* Class Modals */}

      <GroupCreateModal
        isOpen={actioned.action === 'createClass'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />

      <GroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action === 'editClass'}
        onCancel={() => setPartialActioned({ action: undefined, actionId: undefined })}
      />

      <GroupDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'deleteClass'}
        onCancel={() => setPartialActioned({ action: undefined, actionId: undefined })}
      />
    </div>
  );
};
