import { IPageActions } from '@chess-class/sandbox';

export enum IEnumStudentHomeworksPageActions {
  'editStudent',
  'assignStudent',
  'createStudent',
  'activateStudent',
  'deactivateStudent',
  'excludeStudentFromClass',
  'excludeStudentFromSchool',
}

export type IStudentHomeworksPageActions = {
  action: keyof typeof IEnumStudentHomeworksPageActions;
  actionId: string;
};

export const studentHomeworksPageActions: IPageActions<IStudentHomeworksPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumStudentHomeworksPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
