import { useContext, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Skeleton, Tabs } from '@mantine/core';
import { modals } from '@mantine/modals';

import {
  api,
  IEnumReviewContentType,
  IExercise,
  IExercisesRequest,
  ILocalization,
} from '@chess-class/sandbox';
import { NotFoundResult, PlyrPlayer, QueryTable } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { IllustrationShieldIcon, Note2Icon } from '@chess-class/sandbox/icons';

import { ReviewContent } from '~/components/organisms/ReviewContent';
import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { ExerciseProblemModal } from '~mClassQuizzes/components/organisms/ExerciseProblemModal';
import { ExerciseTestModal } from '~mClassQuizzes/components/organisms/ExerciseTestModal';

import { exerciseFilters } from '../consts/filters/exercisesFilters';
import {
  exercisePageActions,
  IExercisePageActions,
} from '../consts/pageActions/IExercisePageActions';

export default function ChapterPage() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const privateSchoolGrades = {
    1: `${t('lessonsPage.firstLevel')}`,
    2: `${t('lessonsPage.secondLevel')}`,
    3: `${t('lessonsPage.thirdLevel')}`,
    4: `${t('lessonsPage.fourthLevel')}`,
  };

  const { id } = useParams<{ id: string }>();
  const { school } = useContext(CoachContext);
  const [exercise, setExercise] = useState<IExercise>();

  const chapterQuery = api.guideline.guidelineChapters.useGuidelineChapter({ id });
  const chapter = chapterQuery.data?.data;
  const [isVideoEnd, setIsVideoEnd] = useState(false);

  const guidelineQuery = api.guideline.guideline.useGuideline({ id: chapter?.guidelineId });

  const videos = useMemo(() => {
    return [...(chapter?.lessons || []), ...(chapter?.cartoons || [])];
  }, [chapter]);

  const { actioned, filtered, onFilter, setActioned, setFiltered } = useUrlFilteredActioned<
    IExercisesRequest,
    IExercisePageActions
  >(exerciseFilters, exercisePageActions);

  const exercisesQuery = api.content.exercises.useExercises({
    chapterId: [chapter?.rootChapterId ?? ''],
    status: 'ACTIVE',
    ...filtered,
  });

  const exercises = exercisesQuery.data?.data.content ?? [];

  const exerciseIndex = exercises.findIndex((ex) => ex.id === exercise?.id) ?? 0;

  const exerciseTotalPages = exercisesQuery.data?.data.totalPages ?? 0;

  const exerciseCurrentPage = exercisesQuery.data?.data.pageable.pageNumber ?? 0;

  useEffect(() => {
    if (isVideoEnd) {
      setActioned({ action: 'rate' });
    }
  }, [isVideoEnd]);

  if (chapterQuery.isLoading) {
    return (
      <div className="flex flex-col gap-8">
        <Skeleton className="w-full h-40 rounded-xl" />
        <Skeleton className="w-full h-24 rounded-xl" />
      </div>
    );
  }

  if (!chapter) {
    return <NotFoundResult />;
  }

  return (
    <div className="flex flex-col gap-8 pb-10">
      <PageMeta
        goBack={{
          i18Title: 'back',
          url: `/chapters?actionId=${
            school?.educationFormat == 'GENERAL'
              ? guidelineQuery.data?.data.id
              : guidelineQuery.data?.data.grade
          }`,
        }}
        selectedMenuKeys={['chapters']}
        title={`${t('chapter')} - ${chapter.translations?.[language]?.title || id}`}
      />

      <div className="flex gap-8 justify-between items-start p-8 bg-white rounded-xl">
        <div className="flex gap-8">
          <div className="flex justify-center items-start relative">
            <img
              alt={t('chapter')}
              className="w-32 h-24 object-cover rounded-xl"
              src="/images/illustrations/guideline-chapter.png"
            />
          </div>
          <div className="flex flex-col gap-0">
            <span className="font-bold text-lg text-primary-900">
              {t('chapter')}{' '}
              {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}:{' '}
              {chapter.translations?.[language]?.title}
            </span>
            <div className="flex flex-row items-center">
              <span className="text-[#B9B9B9] text-[14px] flex items-center mr-2">
                <Note2Icon className="mr-2" />
                {`${exercisesQuery.data?.data.totalElements || 0} ${t('homeworksPage.tasks')}`}
              </span>
              <ReviewContent
                contentId={id ?? ''}
                contentType={IEnumReviewContentType.CHAPTER}
                title={t('review.reviewChapter')}
              />
            </div>
            <span className="text-gray-500">{chapter.translations?.[language]?.description}</span>
          </div>
        </div>
        <span className="border border-gray-200 py-2 px-4 bg-gray-100 rounded-lg whitespace-nowrap">
          {school?.educationFormat == 'GENERAL'
            ? `${t('class')} ${guidelineQuery.data?.data.grade ?? '-'}`
            : guidelineQuery.data?.data.grade
              ? `${privateSchoolGrades[guidelineQuery.data?.data.grade]}`
              : '-'}
        </span>
      </div>

      {!!chapter.translations?.[language]?.notes && (
        <div className="bg-yellow-101 border-yellow-102 border rounded-lg p-6 flex gap-6">
          <IllustrationShieldIcon className="shrink-0 h-12 w-9" />
          <div className="flex flex-col gap-1">
            <span className="font-bold text-primary-900 text-base">
              {t('chaptersPage.recommendationForCoach')}
            </span>
            <span className="text-gray-700">{chapter.translations?.[language]?.notes}</span>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-7">
        {videos.map((video) => (
          <div className="w-full flex flex-col gap-6 rounded-lg bg-white mx-auto" key={video.id}>
            <PlyrPlayer
              className="w-full aspect-video rounded-t-lg overflow-hidden"
              localization={language as ILocalization}
              onEnd={(v) => setIsVideoEnd(v)}
              video={video}
            />
            <div className="flex flex-col px-4 pb-6">
              <h3 className="text-base font-bold">
                {video.translations?.[language]?.fullTitle ?? video.translations?.[language]?.title}
              </h3>
              {!!video.test && (
                <>
                  <span className="text-[#667080] font-medium">
                    {t('author')}: {video.translations?.[language]?.videoFile?.author?.name || '-'}
                  </span>
                  <span className="text-gray-600 font-semibold">
                    {video.translations?.[language]?.videoFile?.author?.rank || 'GM'}
                  </span>
                </>
              )}
            </div>
            <ReviewContent
              contentId={video.id ?? ''}
              contentType={IEnumReviewContentType.VIDEO}
              onClose={() => setActioned({ action: undefined })}
              open={actioned.action == 'rate'}
              title={t('review.reviewVideo')}
            />
          </div>
        ))}
      </div>

      <Tabs
        classNames={{ root: 'flex flex-col gap-6', tabsList: 'flex gap-4' }}
        defaultValue={chapter?.additionalMaterialImages?.length ? 'materials' : 'exercises'}
        keepMounted={false}
      >
        <Tabs.List>
          {!!chapter?.additionalMaterialImages?.length && (
            <Tabs.Tab value="materials">{t('materials')}</Tabs.Tab>
          )}
          <Tabs.Tab value="exercises">{t('homeworksPage.tasks')}</Tabs.Tab>
        </Tabs.List>

        {!!chapter?.additionalMaterialImages?.length && (
          <Tabs.Panel value="materials">
            <div className="flex flex-col gap-8">
              {chapter.additionalMaterialImages?.map((imgUrl, index) => (
                <div
                  className="bg-white p-8 flex gap-8 border rounded-lg items-center"
                  key={`chapter-additional-${index}`}
                >
                  {imgUrl && (
                    <img
                      className="h-52 w-auto max-w-[208px] object-contain rounded-lg cursor-pointer"
                      onClick={() => {
                        modals.open({
                          children: (
                            <img
                              className="h-[66vh] max-w-full w-auto object-contain rounded-lg m-auto"
                              src={imgUrl || ''}
                            />
                          ),
                          classNames: { body: 'flex' },
                          size: '75vh',
                        });
                      }}
                      src={imgUrl || ''}
                    />
                  )}
                  <div className="flex flex-col gap-3">
                    <span className="text-lg font-bold">
                      {chapter.translations[language]?.additionalMaterialTitles?.[index] || '-'}
                    </span>
                    <span className="text-gray-500">
                      {chapter.translations[language]?.additionalMaterialDescriptions?.[index] ||
                        '-'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Tabs.Panel>
        )}
        <Tabs.Panel value="exercises">
          <div>
            <h1 className="font-bold">{t('problems')}</h1>
            <div className="flex flex-wrap gap-7">
              <QueryTable<IExercise>
                columns={[
                  {
                    accessorFn: (exercise) => (
                      <span
                        className="text-primary cursor-pointer font-medium"
                        onClick={() => {
                          ReactGA.event({
                            action: 'task',
                            category: 'task',
                            label: 'task',
                          });
                          setExercise(exercise);
                        }}
                      >
                        {exercise.numId || exercise.id}
                      </span>
                    ),
                    accessorKey: 'id',
                    header: 'ID',
                  },
                  {
                    accessorFn: ({ type }) => (type == 'TEST' ? t('test') : t('exercise')),
                    accessorKey: 'type',
                    enableSorting: true,
                    header: t('type'),
                  },
                  {
                    accessorFn: ({ target }) => (target == 'CLASSWORK' ? t('yes') : t('no')),
                    accessorKey: 'status',
                    header: t('classWork'),
                  },
                ]}
                empty={{ title: t('notFoundResult.noChapterExercises') }}
                query={exercisesQuery}
                tableFilter={{
                  filtered,
                  filters: exerciseFilters,
                  onFilter,
                }}
              />
            </div>
          </div>
        </Tabs.Panel>
      </Tabs>

      <ExerciseProblemModal
        exerciseId={exercise?.id.toString() || ''}
        isOpen={exercise?.type == 'CUSTOM_PROBLEM'}
        isView
        onCancel={() => setExercise(undefined)}
      />

      <ExerciseProblemModal
        exerciseId={exercise?.id.toString() || ''}
        isOpen={exercise?.type == 'PROBLEM'}
        isView
        onCancel={() => setExercise(undefined)}
      />

      <ExerciseTestModal
        exerciseId={exercise?.id.toString() || ''}
        isBackButtonDisabled={exerciseIndex == 0 && exerciseCurrentPage == 1}
        isNextButtonDisabled={
          exerciseIndex >= exercises?.length - 1 && exerciseTotalPages - 1 == exerciseCurrentPage
        }
        isOpen={exercise?.type == 'TEST'}
        isView
        onBackButtonClick={() => {
          if (exerciseCurrentPage > 0 && exerciseIndex > 0) {
            setFiltered({ page: exerciseCurrentPage - 1 });
            setExercise(exercises[exercises.length - 1]);
          }
          setExercise(exercises[exerciseIndex - 1]);
        }}
        onCancel={() => setExercise(undefined)}
        onNextButtonClick={() => {
          if (
            exerciseTotalPages - 1 > exerciseCurrentPage &&
            exerciseIndex >= exercises.length - 1
          ) {
            setFiltered({ page: exerciseCurrentPage + 1 });
            setExercise(exercises[0]);
          } else {
            setExercise(exercises[exerciseIndex + 1]);
          }
        }}
      />
    </div>
  );
}
