import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Menu } from '@mantine/core';

import { api, IClassQuiz } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import { ClockIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';

import { ChaptersPopover } from '~mClassQuizzes/components/molecules/ChaptersPopover';

export default function ClassQuizBox({ classQuiz }: { classQuiz?: IClassQuiz }) {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);
  const classQuery = api.schools.groups.useGroup({
    enabled: !!classQuiz?.classId,
    id: classQuiz?.classId ?? '',
  });
  const classData = classQuery.data?.data;

  if (!classQuiz) {
    return <div />;
  }

  return (
    <div className="flex justify-between items-center rounded-lg bg-white p-8 w-full self-start border border-[#F1F1F1]">
      {' '}
      <div className="flex gap-8">
        <img
          alt="Theme"
          className="!max-w-25 w-25 max-h-25 object-contain place-self-start"
          src="/images/illustrations/assignment-bg.png"
        />
        <div>
          <h4 className="font-semibold text-base text-black mb-2">
            {t('assignment')} #{classQuiz?.orderByClass}
          </h4>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row">
              <span className="text-[#667080] flex items-center gap-1 mr-8">
                <span className="font-medium">
                  {school?.educationFormat == 'PRIVATE' ? t('group') : t('class')}:
                </span>
                {` ${classData?.grade || ''} ${classData?.symbol || ''}`}
              </span>
              {classQuiz.chapterIds.length != 0 && (
                <span className="text-[#667080] flex items-center gap-1">
                  {t('assignmentsPage.assignmentConsistOf')}:
                  <Menu
                    closeDelay={400}
                    openDelay={100}
                    trigger="hover"
                    variant="actions"
                    withArrow
                  >
                    <Menu.Target>
                      <span className="text-[#5D70FF] cursor-pointer">
                        {classQuiz.chapterIds.length}{' '}
                        {t('chapter', { count: classQuiz.chapterIds.length })}
                      </span>
                    </Menu.Target>
                    <ChaptersPopover chapterIds={classQuiz.chapterIds} />
                  </Menu>
                </span>
              )}
            </div>
            <div className="flex flex-row flex-wrap gap-x-6 gap-y-2">
              <span className="text-[#667080] flex flex-wrap items-center gap-1 whitespace-nowrap">
                <ClockIcon className="w-4 h-4" />
                <span className="font-medium">{t('homeworksPage.issued')}:</span>
                {moment(classQuiz.startAt).format('DD.MM.YYYY HH:mm')}
              </span>
              <span className="text-[#667080] flex flex-wrap items-center gap-1 whitespace-nowrap">
                <ClockIcon className="w-4 h-4" />
                <span className="font-medium">{t('finishDate')}:</span>
                {moment(classQuiz.finishAt).format('DD.MM.YYYY HH:mm')}
              </span>
            </div>
          </div>
        </div>
      </div>
      <StatusTag classNames={{ root: 'shrink-0' }} status={classQuiz.status} />
    </div>
  );
}
