import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { mantineTheme } from '@chess-class/sandbox/consts';
import { consoleErrorConfig, sandboxAxiosConfig } from '@chess-class/sandbox/utils';

import App from '~/App';
import { PageMetaContextProvider } from '~/context/PageMetaContext';
import reportWebVitals from '~/utils/reportWebVitals';

import { CoachContextProvider } from './context/CoachContext';

import '~/assets/styles/index.scss';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';

sandboxAxiosConfig({ type: 'coach', url: process.env.REACT_APP_API_URL });

if (process.env.NODE_ENV === 'development') {
  consoleErrorConfig();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <MantineProvider theme={mantineTheme} withGlobalStyles withNormalizeCSS>
        <Notifications position="top-right" zIndex={1001} />
        <ModalsProvider>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <PageMetaContextProvider>
                <CoachContextProvider>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </CoachContextProvider>
              </PageMetaContextProvider>
            </HelmetProvider>
            <ReactQueryDevtools initialIsOpen={false} panelProps={{ className: 'min-h-10' }} />
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </StrictMode>,
  );
}

reportWebVitals();
