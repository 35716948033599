import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { Menu } from '@mantine/core';

import { IClassQuiz, IEnumEducationFormatKeys } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';

import { ChaptersPopover } from '../../components/molecules/ChaptersPopover';

export const classQuizzesColumns: (
  educationFormat?: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IClassQuiz>[] = (educationFormat) => {
  return [
    {
      accessorFn: (row) => (
        <Link to={`/classQuiz/${row.id}`}>{`${t('assignment')} #${row.orderByClass}`}</Link>
      ),
      accessorKey: 'id',
      header: t('title'),
    },
    {
      accessorFn: (row) => row.className || '-',
      accessorKey: 'className',
      header: educationFormat == 'GENERAL' ? t('class') : t('group'),
    },
    {
      accessorFn: (row) =>
        row.chapterIds && row.chapterIds.length != 0 ? (
          <Menu closeDelay={400} openDelay={100} trigger="hover" variant="actions" withArrow>
            <Menu.Target>
              <span className="cursor-pointer text-[#5D70FF]">{`${row.chapterIds.length} ${t(
                'chapter',
                { count: row.chapterIds.length },
              )}`}</span>
            </Menu.Target>
            <ChaptersPopover chapterIds={row.chapterIds} />
          </Menu>
        ) : (
          '-'
        ),
      accessorKey: 'chapterIds',
      header: t('chapter'),
    },
    {
      accessorFn: (row) =>
        moment.utc(row.startAt).utcOffset('GMT+05:00').format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'startAt',
      header: t('startAt'),
    },
    {
      accessorFn: (row) =>
        moment.utc(row.finishAt).utcOffset('GMT+05:00').format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'finishAt',
      header: t('finishDate'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      header: t('status'),
    },
  ];
};
