import { t } from 'i18next';

import { IEnumClassQuizStatus, IEnumClassQuizStatusKeys, IFilters } from '@chess-class/sandbox';
import { CheckboxFilter } from '@chess-class/sandbox/components';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const classQuizzesFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.education.coachClassQuiz.status]: {
    input: (
      <CheckboxFilter
        options={Object.entries(IEnumClassQuizStatus).map(([value]) => ({
          label: t(`statuses.${value as IEnumClassQuizStatusKeys}`),
          value,
        }))}
      />
    ),
    sort: true,
  },
  [modelKeys.education.coachClassQuiz.startAt]: {
    sort: true,
  },
  [modelKeys.education.coachClassQuiz.finishAt]: {
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
