import { FC } from 'react';
import { t } from 'i18next';
import { Modal } from '@mantine/core';

import { ChessAnalyseEditor } from '@chess-class/sandbox/components';

type IProps = {
  onClose: () => void;
  opened: boolean;
};

export const BoardEditorModal: FC<IProps> = ({ onClose, opened }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={opened}
      size="lg"
      title={t('boardEditorPage.boardEditor')}
    >
      <form className="flex flex-wrap gap-8 justify-center">
        <ChessAnalyseEditor isOpen={opened} onClose={onClose} />
      </form>
    </Modal>
  );
};
