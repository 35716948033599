import { type FC, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { array, number, object, string } from 'yup';
import {
  Accordion,
  Button,
  Checkbox,
  Divider,
  Modal,
  Radio,
  ScrollArea,
  Select,
  Skeleton,
  Switch,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';

import { api } from '@chess-class/sandbox';
import { ChevronDownIcon, FiltersIcon, LockIcon } from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

import { useCoachContext } from '~/context/CoachContext';
import useClassGuidelineChapters from '~/hooks/useClassGuidelineChapters';

import 'react-datepicker/dist/react-datepicker.css';

type IHomeworkCreateModal = {
  chapterId?: string;
  classId?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const HomeworkCreateModal: FC<IHomeworkCreateModal> = ({
  chapterId,
  classId,
  isOpen,
  onCancel,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { coachMe, myClasses, school } = useCoachContext();
  const homeworkCreate = api.education.homework.useCoachHomeworkCreate();
  const randomExercises = api.content.exercises.useExercisesRandom();
  const [selectAllStudents, setSelectAllStudents] = useState<boolean>(true);
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      finishAtDate: new Date(),
      finishAtTime: new Date(),
      ...api.education.homework.helpers.coachHomeworkCreateInitial,
    },
    validate: yupResolver(
      object({
        chapterId: string().required(),
        exercises: array().of(
          object({
            id: string().required(),
            numId: number().required(),
            type: string().required(),
          }),
        ),
        finishAtDate: string().required(),
        finishAtTime: string().required(),
        groupId: string().required(),
        name: string().nullable().optional(),
        studentIds: array().min(1, t('messages.studentNotSelected')),
      }),
    ),
  });

  const { classGuidelineChapters, isLoading } = useClassGuidelineChapters({
    groupId: form.values.groupId,
  });

  //REMOVE AFTER DEMO
  const demoItems = [1, 2, 3, 19, 20, 21, 37, 38, 39, 61, 62, 63];

  const sortedClassGuidelineChapters =
    school?.educationFormat == 'GENERAL'
      ? classGuidelineChapters?.sort((a, b) => {
          const statusOrder = ['GIVEN', 'AVAILABLE', undefined];
          const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
          if (statusComparison !== 0) return statusComparison;
          return a.numId - b.numId;
        })
      : coachMe?.email == 'demochessclass+2@gmail.com' ||
          coachMe?.email == 'demochessclass+5@gmail.com'
        ? classGuidelineChapters
            ?.sort((a, b) => {
              const statusOrder = ['GIVEN', 'AVAILABLE', undefined];
              const statusComparison =
                statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
              if (statusComparison !== 0) return statusComparison;
              return a.numId - b.numId;
            })
            .filter((item) => demoItems.includes(item.numId))
        : classGuidelineChapters?.sort((a, b) => {
            const statusOrder = ['GIVEN', 'AVAILABLE', undefined];
            const statusComparison = statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
            if (statusComparison !== 0) return statusComparison;
            return a.numId - b.numId;
          });

  const filteredChapters = sortedClassGuidelineChapters?.filter((chapter) => {
    return statusFilter ? chapter.status === statusFilter : true;
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    } else {
      if (classId) {
        form.setFieldValue('groupId', classId);
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (form.values.groupId) {
      if (classId == form.values.groupId) {
        form.setFieldValue('chapterId', chapterId);
      } else {
        form.setFieldValue('chapterId', undefined);
      }
    }
  }, [form.values.groupId]);

  const studentsQuery = api.user.students.useStudents({
    assigned: true,
    enabled: !!school?.schoolId && !!form.values.groupId,
    groupIds: [form.values.groupId],
    page: 0,
    schoolId: school?.schoolId,
    size: 100,
  });

  const students = studentsQuery.data?.data.content;
  const activeStudents = useMemo(() => students?.filter((s) => s.status == 'ACTIVE'), [students]);

  useEffect(
    () =>
      form.setFieldValue(
        'studentIds',
        activeStudents?.map((s) => s.id),
      ),
    [activeStudents],
  );

  return (
    <Modal
      closeButtonProps={{
        iconSize: 24,
      }}
      onClose={onCancel}
      opened={isOpen}
      scrollAreaComponent={ScrollArea.Autosize}
      size={770}
      title={t('homeworksPage.addHomework')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          randomExercises
            .mutateAsync({
              chapterId: [form.values.chapterId ?? ''],
              status: 'ACTIVE',
            })
            .then((res) => {
              const exercisesList = res.data.map((item) => {
                return {
                  chapterId: item?.chapter?.id ?? '',
                  id: item.id,
                  numId: item.numId || 0,
                  type: item.type,
                };
              });
              homeworkCreate
                .mutateAsync({
                  chapterId: values.chapterId,
                  exercises: exercisesList ?? [],
                  finishAt:
                    moment(values.finishAtDate).startOf('day').format('YYYY-MM-DD') +
                    ' ' +
                    moment(values.finishAtTime).utcOffset('GMT+00:00').format('HH:mm:ss'),
                  groupId: values.groupId,
                  studentIds: values.studentIds,
                })
                .then(() => {
                  notify('success', t('messages.successHomeworkCreate'));
                  onCancel();
                  ReactGA.event({
                    action: 'homework_created',
                    category: 'homework_created',
                    label: 'homework_created',
                  });
                })
                .catch(() => {
                  !moment(values.finishAt).isBefore(moment()) &&
                    values.chapterId &&
                    notify('error', t('tryAgainLater'));
                  moment(values.finishAt).isBefore(moment()) &&
                    form.setFieldError(
                      'finishAt',
                      t('messages.dueDateCannotBeEarlierThanTheCurrentDate'),
                    );
                  !values.chapterId &&
                    form.setFieldError('chapterId', t('messages.warningHomeworkConsistOf'));
                  !selectAllStudents &&
                    values.studentIds &&
                    !values.studentIds.length &&
                    form.setFieldError('studentIds', t('messages.warningHomeworkConsistOf'));
                });
            });
        })}
      >
        <div className="p-5 pt-2 gap-4 grid">
          <p className="text-gray-110 font-medium mb-3">
            {school?.educationFormat == 'GENERAL'
              ? t('homeworksPage.chooseClass')
              : t('homeworksPage.chooseGroup')}
          </p>
          <Select
            data={
              myClasses?.map((c) => ({
                label: school?.educationFormat == 'GENERAL' ? `${c.grade} ${c.symbol}` : c.symbol,
                value: c.id,
              })) || []
            }
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            name="groupId"
            {...form.getInputProps('groupId')}
          />
          <Switch
            checked={selectAllStudents}
            className="mr-10"
            label={`${t('homeworksPage.toAllStudents')} (${activeStudents?.length})
            `}
            labelPosition="right"
            onChange={() => {
              setSelectAllStudents(!selectAllStudents);
            }}
          />
          {!selectAllStudents && (
            <Accordion className="px-0" defaultValue="exercise-answer">
              <Accordion.Item className="!border-none" value="exercise-answer">
                <Accordion.Control className="mb-0 h-[52px]">
                  <p className="text-center text-base mb-0">
                    {t('students')}{' '}
                    <span className="text-gray-111">({form.values.studentIds?.length ?? 0}) </span>
                  </p>
                </Accordion.Control>
                <Accordion.Panel>
                  <ScrollArea
                    classNames={{
                      viewport: form.errors.studentIds
                        ? 'border border-solid border-red min-h-[192px] max-h-[256px]'
                        : 'border border-solid border-grey min-h-[192px] max-h-[256px]',
                    }}
                  >
                    {isLoading ? (
                      <Skeleton className="w-full h-48" />
                    ) : activeStudents && activeStudents?.length ? (
                      <>
                        <Checkbox
                          checked={form.values.studentIds && form.values.studentIds?.length > 0}
                          indeterminate={form.values.studentIds?.length == activeStudents.length}
                          label={
                            <div className="flex gap-3">
                              <span>{t('homeworksPage.toAllStudents')}</span>
                            </div>
                          }
                          onChange={() =>
                            form.values.studentIds?.length == activeStudents.length
                              ? form.setFieldValue('studentIds', [])
                              : form.setFieldValue(
                                  'studentIds',
                                  activeStudents.map((s) => s.id),
                                )
                          }
                        />
                        <Checkbox.Group
                          withAsterisk
                          {...form.getInputProps('studentIds', { type: 'checkbox' })}
                          value={form.values.studentIds}
                        >
                          {activeStudents?.map((student) => {
                            return (
                              <Checkbox
                                checked={true}
                                key={student.id}
                                label={
                                  <div className="flex gap-3">
                                    <span>{`${student.firstName} ${student.lastName}`}</span>
                                  </div>
                                }
                                value={student.id}
                              />
                            );
                          })}
                        </Checkbox.Group>
                      </>
                    ) : (
                      <div className="flex items-center flex-col justify-center bg-white h-full py-8">
                        <img
                          className="w-28 h-40 object-contain"
                          src="/images/illustrations/pawn-search.png"
                        />
                        <span className="mt-4 text-lg text-gray-500 font-medium">
                          {school?.educationFormat == 'GENERAL'
                            ? form.values.groupId
                              ? t('homeworksPage.noTopicsForClass')
                              : t('homeworksPage.chooseClass')
                            : form.values.groupId
                              ? t('homeworksPage.noTopicsForGroup')
                              : t('homeworksPage.chooseGroup')}
                        </span>
                      </div>
                    )}
                  </ScrollArea>
                  <Typography className="text-red-600">{form.errors.studentIds}</Typography>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
          <Accordion className="px-0" defaultValue="exercise-answer">
            <Accordion.Item className="!border-none" value="exercise-answer">
              <Accordion.Control className="mb-0">
                <p className="text-center text-base mb-0">
                  {t('homeworksPage.chooseTopic')}{' '}
                  <span className="text-gray-111">
                    ({(classGuidelineChapters?.length || 0) < 10 ? '0' : ''}
                    {classGuidelineChapters?.length || 0})
                  </span>
                </p>
                <Select
                  className="bg-white"
                  clearable
                  data={[
                    { label: t('statuses.ALL'), value: '' },
                    { label: t(`statuses.NOT_PASSED`), value: 'GIVEN' },
                    { label: t('statuses.PASSED'), value: 'AVAILABLE' },
                  ]}
                  icon={<FiltersIcon className="w-5 h-5" />}
                  onChange={(value) => setStatusFilter(value)}
                  onClick={(e) => e.stopPropagation()}
                  placeholder={t('filter')}
                  prefix="prefix"
                  rightSection={
                    <ChevronDownIcon className="w-3.5 h-3.5 rotate-180 text-gray-109" />
                  }
                  value={statusFilter}
                />
              </Accordion.Control>
              <Accordion.Panel>
                <ScrollArea
                  classNames={{
                    viewport: form.errors.chapterId
                      ? 'border border-solid border-red min-h-[192px] max-h-[256px]'
                      : 'border border-solid border-grey min-h-[192px] max-h-[256px]',
                  }}
                >
                  {isLoading ? (
                    <Skeleton className="w-full h-48" />
                  ) : classGuidelineChapters && !!classGuidelineChapters?.length ? (
                    <Radio.Group withAsterisk {...form.getInputProps('chapterId')} error={false}>
                      {filteredChapters?.map((chapter) => {
                        const isChecked = form.values.chapterId == chapter.rootChapterId;
                        const isAvailable = chapter.status == 'GIVEN';

                        return (
                          <div className="flex justify-between" key={chapter.id}>
                            <Radio
                              disabled={!isAvailable}
                              label={
                                <span
                                  className={clsx({
                                    'text-primary': isChecked,
                                  })}
                                >
                                  {school?.educationFormat == 'GENERAL'
                                    ? `${chapter.priority < 10 ? '0' : ''}
                                  ${chapter.priority}`
                                    : chapter.numId}
                                  <span
                                    className={clsx('pl-3', {
                                      'text-[#11122C]': isChecked,
                                    })}
                                  >
                                    {chapter.translations?.[language]?.title ?? '-'}
                                  </span>
                                </span>
                              }
                              value={chapter.rootChapterId}
                              variant="chapters"
                            />
                            {!isAvailable && (
                              <LockIcon className="my-auto h-4 w-4 mr-5 text-gray-112/40" />
                            )}
                            {isAvailable && !isChecked && (
                              <span className="my-auto mr-5 text-gray-112 whitespace-nowrap">
                                {t('passed')}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </Radio.Group>
                  ) : (
                    <div className="flex items-center flex-col justify-center bg-white h-full py-8">
                      <img
                        className="w-28 h-40 object-contain"
                        src="/images/illustrations/pawn-search.png"
                      />
                      <span className="mt-4 text-lg text-gray-500 font-medium">
                        {school?.educationFormat == 'GENERAL'
                          ? form.values.groupId
                            ? t('homeworksPage.noTopicsForClass')
                            : t('homeworksPage.chooseClass')
                          : form.values.groupId
                            ? t('homeworksPage.noTopicsForGroup')
                            : t('homeworksPage.chooseGroup')}
                      </span>
                    </div>
                  )}
                </ScrollArea>
                <Typography className="text-red-600">{form.errors.chapterId}</Typography>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>

          <div className="flex flex-col justify-center mt-5">
            <Typography className="text-base">{t('finishDate')}</Typography>
            <div className="grid grid-cols-2 flex flex-row items-center gap-6 mt-4">
              <DatePickerInput
                label={t('date')}
                locale={language}
                minDate={new Date()}
                name="finishAtDate"
                // placeholder="01.01.2023"
                valueFormat="DD.MM.YYYY"
                {...form.getInputProps('finishAtDate')}
              />

              <div className="flex flex-col h-full">
                <Typography className="mb-[10px] text-label font-[500] text-[#4F5672] leading-[21.7px]">
                  {t('time')}
                </Typography>
                <DatePicker
                  className="w-full rounded-[8px] h-[52px] bg-[#FAFBFC] border-[#E4E6ED]"
                  dateFormat="HH:mm"
                  label={t('finishDate')}
                  locale="ru"
                  minDate={new Date()}
                  name="finishAtTime"
                  placeholder="12:00"
                  selected={form.values.finishAtTime}
                  {...form.getInputProps('finishAtTime')}
                  showTimeSelect
                  showTimeSelectOnly
                  timeCaption={null}
                  timeFormat="HH:mm"
                  valueFormat="HH:mm"
                />
              </div>
            </div>
          </div>
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="flex justify-center gap-4 pt-0">
          <Button className="w-[220px]" onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
          <Button
            className="w-[220px]"
            disabled={!form.values.groupId}
            loading={homeworkCreate.isLoading || randomExercises.isLoading}
            type="submit"
          >
            {t('save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
