import { Route, Routes } from 'react-router-dom';

import ChapterPage from '~mChapters/pages/ChapterPage';
import ChaptersPage from '~mChapters/pages/ChaptersPage';

export const ChaptersRouter = () => {
  return (
    <Routes>
      <Route element={<ChaptersPage />} index />
      <Route element={<ChapterPage />} path=":id" />
    </Routes>
  );
};
