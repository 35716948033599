import { Route, Routes } from 'react-router-dom';

import StudioGroupDemoPage from '~mStudio/pages/StudioGroupDemoPage';
import StudioGroupPage from '~mStudio/pages/StudioGroupPage';
import StudioPage from '~mStudio/pages/StudioPage';

export const StudioRouter = () => {
  return (
    <Routes>
      <Route element={<StudioPage />} index />
      <Route element={<StudioGroupPage />} path=":id" />
      <Route element={<StudioGroupDemoPage />} path=":id/:chapterId" />
    </Routes>
  );
};
