import { useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Select, Skeleton } from '@mantine/core';

import { api, IEnumGuidelineChapterGrade, IEnumReviewContentType } from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { Note2Icon } from '@chess-class/sandbox/icons';

import GroupNotFound from '~/components/atoms/GroupNotFound';
import { ReviewContent } from '~/components/organisms/ReviewContent';
import { CoachContext, useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';
import {
  chapterPageActions,
  IChapterPageActions,
} from '~/modules/chapters/consts/pageActions/IChapterPageActions';

export default function ChaptersPage() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { actioned, setActioned, setPartialActioned } = useUrlFilteredActioned<
    undefined,
    IChapterPageActions
  >(undefined, chapterPageActions);
  const { guidelines, myClasses, schoolLoading } = useCoachContext();
  const { coachMe, school } = useContext(CoachContext);

  const activeGuidelines = !guidelines
    ? null
    : guidelines?.filter((guide) =>
        school?.educationFormat == 'GENERAL'
          ? myClasses?.map((c) => c.grade)?.includes(guide.grade)
          : guidelines,
      );

  const activeGuideline =
    activeGuidelines === null
      ? null
      : activeGuidelines?.find((guide) =>
          school?.educationFormat == 'GENERAL'
            ? guide.id == actioned.actionId
            : guide.grade.toString() == actioned.actionId,
        );

  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    enabled: !!activeGuideline?.id,
    guidelineId: activeGuideline?.id,
    isDemo: coachMe?.isDemo,
    size: school?.educationFormat == 'GENERAL' ? 200 : 500,
    sort: 'priority',
  });
  const chapters = chaptersQuery.data?.data;

  useEffect(() => {
    if (activeGuidelines?.length) {
      if (!actioned.actionId) {
        setPartialActioned({
          actionId:
            school?.educationFormat == 'GENERAL'
              ? activeGuidelines[0].id
              : activeGuidelines[0].grade.toString(),
        });
      }
    }
  }, [actioned.actionId, activeGuidelines]);

  const content =
    school?.educationFormat == 'GENERAL'
      ? chapters?.content
      : coachMe?.email == 'demochessclass+2@gmail.com' ||
          coachMe?.email == 'demochessclass+5@gmail.com'
        ? chapters?.content.sort((a, b) => a.numId - b.numId).slice(0, 3)
        : chapters?.content.sort((a, b) => a.numId - b.numId);

  return (
    <div className="flex flex-col gap-10">
      <PageMeta selectedMenuKeys={['chapters']} title={t('chapters')} />
      <div className="w-full bg-[#CAE8FD] h-44 flex items-center justify-between p-9 rounded-xl">
        <span className="font-bold text-xl"> {t('chapters')}</span>
        <div className="flex gap-8 lg:gap-24">
          <img
            alt="chapter"
            className="h-24 lg:h-36"
            src="/images/illustrations/themes-banner.png"
          />
          <div className="flex items-center">
            {schoolLoading ? (
              <Skeleton className="h-14 w-56 rounded-lg" />
            ) : (
              !!guidelines?.length &&
              !!myClasses?.length && (
                <Select
                  className="w-28 lg:w-56"
                  classNames={{ input: 'bg-white' }}
                  data={
                    activeGuidelines?.map(({ grade, id }) => ({
                      label:
                        school?.educationFormat == 'GENERAL'
                          ? `${t('class')} ${grade}`
                          : IEnumGuidelineChapterGrade[grade],
                      value: school?.educationFormat == 'GENERAL' ? id : grade.toString(),
                    })) || []
                  }
                  onChange={(value) => setPartialActioned({ actionId: value?.toString() })}
                  value={actioned.actionId}
                />
              )
            )}
          </div>
        </div>
      </div>
      <div className="rounded-xl w-full bg-white p-8">
        <div className="flex gap-4 justify-between">
          <h3 className="text-[#667080] font-bold">{t('chaptersPage.chapterList')}</h3>
          {!!chapters?.totalElements && (
            <span className="text-[#C7C6C7]">
              {t('total')} {chapters.totalElements}
            </span>
          )}
        </div>
        {schoolLoading ||
        chaptersQuery.isLoading ||
        activeGuideline === null ||
        !myClasses ||
        (!chaptersQuery?.data?.data.content && !!myClasses?.length) ? (
          <div className="flex flex-col gap-6 mt-6">
            <Skeleton className="w-full h-24" />
            <Skeleton className="w-full h-24" />
            <Skeleton className="w-full h-24" />
          </div>
        ) : !chaptersQuery?.data?.data.content.length ? (
          !myClasses?.length ? (
            <GroupNotFound />
          ) : (
            <NotFoundResult subTitle="" title={t('notFoundResult.noChapters')} />
          )
        ) : (
          content?.map(({ exercisesCount, id, numId, priority, translations }) => {
            return (
              <div className="flex gap-8 justify-between items-center py-6" key={`box-${id}`}>
                <div className="flex gap-8">
                  <Link
                    className="flex justify-center items-center relative"
                    onClick={() =>
                      ReactGA.event({
                        action: 'view_topic',
                        category: 'view_topic',
                        label: 'view_topic',
                      })
                    }
                    to={`/chapters/${id}`}
                  >
                    <img
                      alt="Theme"
                      className="w-28 h-24 object-contain"
                      src="/images/illustrations/themes-bg.png"
                    />
                    <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl font-bold">
                      {school?.educationFormat == 'GENERAL' ? priority : numId}
                    </span>
                  </Link>
                  <div>
                    <Link to={`/chapters/${id}`}>
                      <h4 className="font-semibold text-base text-black hover:text-primary">
                        {t('chapter')} {school?.educationFormat == 'GENERAL' ? priority : numId}:{' '}
                        {translations?.[language]?.title}
                      </h4>
                    </Link>
                    <div className="flex flex-row">
                      <p className="text-[#B9B9B9] text-[14px] flex items-center mt-1 mr-6">
                        {' '}
                        <Note2Icon className="mr-2" />
                        {`${exercisesCount} ${t('homeworksPage.tasks')}`}
                      </p>
                      <ReviewContent
                        contentId={id}
                        contentType={IEnumReviewContentType.CHAPTER}
                        onClose={() => setActioned({ action: undefined })}
                        title={t('review.reviewChapter')}
                      />
                    </div>
                  </div>
                </div>
                {activeGuideline && (
                  <span className="border border-gray-200 py-2 px-4 bg-gray-100 rounded-lg whitespace-nowrap">
                    {school?.educationFormat == 'GENERAL'
                      ? `${t('class')} ${activeGuideline?.grade}`
                      : IEnumGuidelineChapterGrade[activeGuideline.grade]}
                  </span>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
