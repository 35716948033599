import { FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Skeleton, Tabs, Title } from '@mantine/core';

import { api, ICoachHomeworksRequest } from '@chess-class/sandbox';
import { QueryTable } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { TrashIcon } from '@chess-class/sandbox/icons';

import GroupNotFound from '~/components/atoms/GroupNotFound';
import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';
import { getTimeSort, TimeSortEnum } from '~/modules/groups/groups/consts/timeSort';

import { HomeworkCreateModal } from '~mHomeworks/components/organisms/HomeworkCreateModal';
import { HomeworkDeleteModal } from '~mHomeworks/components/organisms/HomeworkDeleteModal';
import { homeworkCoachColumns } from '~mHomeworks/consts/columns/homeworkCoachColumns';
import { homeworksFilters } from '~mHomeworks/consts/filters/homeworksFilters';
import {
  homeworksPageActions,
  IHomeworksPageActions,
} from '~mHomeworks/consts/pageActions/IHomeworksPageActions';

import QuizGradeTable from '../components/organisms/QuizGradeTable';

enum IEnumHomeWorksTab {
  'grades' = 'grades',
  'homework' = 'homework',
}

const HomeworksPage: FC = () => {
  const { myClasses, school } = useCoachContext();
  const [tab, setTab] = useState<keyof typeof IEnumHomeWorksTab | null | string>('students');
  const [gradesOpened, setGradesOpened] = useState(false);

  const { actioned, filtered, onFilter, setPartialActioned } = useUrlFilteredActioned<
    ICoachHomeworksRequest,
    IHomeworksPageActions
  >(homeworksFilters, homeworksPageActions);
  const [timeSort, setTimeSort] = useState<keyof typeof TimeSortEnum>(TimeSortEnum.NEWEST);

  const homeworksQuery = api.education.homework.useCoachHomeworks({
    ...filtered,
    enabled: !!school?.schoolId && !!actioned.actionClassId,
    groupId: actioned.actionClassId,
    schoolId: school?.schoolId,
  });
  const currentClass = useMemo(() => myClasses?.find((cl) => cl.id == actioned.actionClassId), []);

  useEffect(() => {
    if (myClasses?.length) {
      if (!actioned.actionClassId || !myClasses.find((cl) => cl.id == actioned.actionClassId)?.id) {
        setPartialActioned({ actionClassId: myClasses[0].id });
      }
    }
  }, [myClasses, actioned.actionClassId]);

  useEffect(() => {
    if (tab == 'grades') {
      setGradesOpened(true);
    }
  }, [tab]);

  return (
    <div className="py-4 px-2 flex flex-col gap-11 h-full">
      <PageMeta
        openMenuKeys={['homework']}
        selectedMenuKeys={['homework']}
        title={t('homeworks')}
      />
      <div className="flex items-center gap-4 justify-between">
        <div>
          <Title order={2}>{t('homeworksPage.homework')}</Title>
          <span className="text-gray-108">
            {homeworksQuery.data?.data.totalElements ?? 0} {String(t('total')).toLowerCase()}
          </span>
        </div>
        {myClasses?.length ? (
          <div className="flex flex-row gap-6">
            <Select
              classNames={{ input: 'bg-white' }}
              data={myClasses.map((group) => ({
                label:
                  school?.educationFormat == 'GENERAL'
                    ? `${group.grade} ${group.symbol}`
                    : group.symbol,
                value: group.id,
              }))}
              onChange={(id) => {
                setPartialActioned({ actionClassId: id || '' });
              }}
              placeholder={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
              value={actioned.actionClassId}
            />
            <Button
              className="py-4 h-13"
              onClick={() => setPartialActioned({ action: 'create' })}
              rightIcon={<PlusOutlined />}
              variant="filled"
            >
              {t('homeworksPage.createHomework')}
            </Button>
          </div>
        ) : (
          !myClasses && (
            <div className="flex flex-row gap-6">
              <Skeleton className="h-14 w-56 rounded-lg" />
              <Skeleton className="h-14 w-44 rounded-lg" />
            </div>
          )
        )}
      </div>

      {!!myClasses && !myClasses.length ? (
        <GroupNotFound />
      ) : (
        <Tabs
          classNames={{
            root: 'flex flex-col gap-4',
            tab: 'h-10.5',
            tabsList: 'justify-between gap-4',
          }}
          defaultValue="homeworks"
          onTabChange={setTab}
        >
          <Tabs.List className="mb-5 bg-transparent">
            <div className="flex gap-4">
              <Tabs.Tab className="mr-4" value="homeworks">
                {t('homeworks')}
              </Tabs.Tab>
              <Tabs.Tab
                onClick={() =>
                  ReactGA.event({
                    action: 'homeworks_results',
                    category: 'homeworks_results',
                    label: 'homeworks_results',
                  })
                }
                value="grades"
              >
                {school?.educationFormat == 'GENERAL' ? t('grades') : t('results')}
              </Tabs.Tab>
            </div>
            {tab === 'grades' && (
              <Select
                classNames={{ input: 'h-10.5 bg-white' }}
                data={getTimeSort}
                onChange={(value: keyof typeof TimeSortEnum) => value && setTimeSort(value)}
                value={timeSort}
              />
            )}
          </Tabs.List>

          <Tabs.Panel value="homeworks">
            <QueryTable
              actions={(row) => [
                ...(row.status == 'ISSUED'
                  ? [
                      {
                        icon: <TrashIcon />,
                        onClick: () => {
                          setPartialActioned({ action: 'delete', actionId: row.id });
                        },
                        title: t('delete'),
                      },
                    ]
                  : []),
              ]}
              columns={homeworkCoachColumns(school?.educationFormat)}
              empty={{
                description: t('homeworksPage.callToActionCreate'),
                title: t('homeworksPage.createdList'),
              }}
              query={homeworksQuery}
              tableFilter={{ filtered, filters: homeworksFilters, onFilter }}
            />
          </Tabs.Panel>
          <Tabs.Panel value="grades">
            {actioned.actionClassId && (
              <QuizGradeTable
                enabled={gradesOpened}
                gradeType={currentClass?.school?.gradeType}
                groupId={actioned.actionClassId}
                isHomework
                timeSort={timeSort}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      )}

      <HomeworkCreateModal
        chapterId={actioned.actionChapterId}
        classId={actioned.actionClassId}
        isOpen={actioned.action === 'create'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />

      <HomeworkDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />
    </div>
  );
};

export default HomeworksPage;
