import { type FC, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { IMaskInput } from 'react-imask';
import { t } from 'i18next';
import moment from 'moment';
import * as yup from 'yup';
import styled from '@emotion/styled';
import {
  ActionIcon,
  Alert,
  AlertProps,
  Button,
  CopyButton,
  Divider,
  InputBase,
  Modal,
  Select,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconCalendar } from '@tabler/icons-react';

import { api, IGroup, IStudentInviteRequest } from '@chess-class/sandbox';
import { CheckIcon, CopyIcon, InfoIcon } from '@chess-class/sandbox/icons';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';

import { getStudentGenders } from '~mGroups/groups/consts/genders';

type CreateStudentModalProps = {
  classData?: IGroup;
  isOpen: boolean;
  onCancel: () => void;
};

const CustomAlert = styled(Alert)<AlertProps>`
  .mantine-Alert-wrapper {
    display: flex;
    align-items: center;
  }
`;

const birthDateValidation = (value: string) => {
  const date = moment(value, 'DD.MM.YYYY');
  if (!date.isValid()) {
    return false;
  }
  return date.isSameOrBefore(moment(), 'day');
};

const studentInviteValidationSchema = yup.object().shape({
  birthDate: yup
    .string()
    .required()
    .test('birthDate', t('messages.birthDateShouldNotBeInTheFuture'), birthDateValidation),
  email: yup.string().required().email('Invalid email'),
  firstName: yup.string().required(),
  gender: yup.string().required(),
  groupId: yup.string().required(),
  iin: yup.string().nullable(),
  lastName: yup.string().required(),
  middleName: yup.string().nullable(),
  phone: yup.string().nullable(),
});

export const CreateStudentModal: FC<CreateStudentModalProps> = ({
  classData,
  isOpen,
  onCancel,
}) => {
  const { coachMe, school } = useContext(CoachContext);

  const [generatedPassword, setGeneratedPassword] = useState<string>();

  const classesQuery = api.schools.groups.useGroups({
    coachIds: school?.isSuperCoach && !!coachMe ? [] : [coachMe?.id || ''],
    enabled: isOpen && !!coachMe?.id && !!school?.schoolId,
    schoolIds: school?.schoolId ? [school.schoolId] : [],
    size: 9999,
  });
  const classes = classesQuery.data?.data;

  const form = useForm<IStudentInviteRequest>({
    initialValues: api.user.students.helpers.studentInviteInitial,
    validate: yupResolver(studentInviteValidationSchema),
  });

  const studentCreate = api.user.students.useStudentInvite();

  useEffect(() => {
    if (form.values.birthDate) {
      const generatedPassword = moment(form.values.birthDate, 'DD.MM.YYYY').format('DDMMYYYY');

      setGeneratedPassword(generatedPassword);
    }
  }, [form.values.birthDate]);

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (classData) {
      form.setFieldValue('groupId', classData.id);
    }
  }, [classData, isOpen]);

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={isOpen}
      size={776}
      title={t('classesPage.addStudent')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          studentCreate
            .mutateAsync({
              ...values,
              birthDate: moment(values.birthDate, 'DD.MM.YYYY').format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
              ),
              phone: phoneMaskSanitizer(values.phone ?? ''),
            })
            .then(() => {
              notify('success', t('messages.successStudentCreate'));
              onCancel();
              ReactGA.event({
                action: 'add_student',
                category: 'add_student',
                label: 'add_student',
              });
            })
            .catch(() => {});
        })}
      >
        <div className="grid gap-6 grid-cols-6 p-5">
          <TextInput
            className="col-span-3"
            label={t('lastName')}
            name="lastName"
            withAsterisk
            {...form.getInputProps('lastName')}
          />
          <TextInput
            className="col-span-3"
            label={t('firstName')}
            name="firstName"
            withAsterisk
            {...form.getInputProps('firstName')}
          />
          <TextInput
            className="col-span-3"
            label={t('middleName')}
            name="middleName"
            {...form.getInputProps('middleName')}
          />
          <TextInput
            className="col-span-3"
            label={t('iin')}
            maxLength={12}
            name="iin"
            {...form.getInputProps('iin')}
          />
          <InputBase
            className="col-span-2"
            component={IMaskInput}
            icon={<IconCalendar />}
            label={t('birthDate')}
            mask="00.00.0000"
            name="birthDate"
            placeholder="18.04.1999"
            {...form.getInputProps('birthDate')}
          />
          <Select
            className="col-span-2"
            data={getStudentGenders()}
            label={t('gender.title')}
            name="gender"
            required
            {...form.getInputProps('gender')}
          />
          <Select
            className="col-span-2"
            data={(classes?.content ?? []).map((itemClass) => ({
              label: itemClass.name,
              value: itemClass.id,
            }))}
            disabled={!!classData}
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            name="groupId"
            {...form.getInputProps('groupId')}
          />
          <TextInput
            className="col-span-3"
            label={t('email')}
            name="email"
            withAsterisk
            {...form.getInputProps('email')}
          />
          <InputBase
            className="col-span-3"
            component={IMaskInput}
            label={t('phone')}
            mask="+7 000 000 00 00"
            name="phone"
            {...form.getInputProps('phone')}
          />

          {generatedPassword && (
            <div className="col-span-6">
              <CustomAlert
                color="violet"
                icon={<InfoIcon className="w-4 h-4" />}
                onClose={() => setGeneratedPassword(undefined)}
                withCloseButton
              >
                <div className="flex items-center px-4">
                  <span className="text-base font-medium">
                    {t('classesPage.studentPasswordGenerate')}
                  </span>
                  <div className="flex items-center max-w-[200px] ml-8 bg-white p-4 rounded-lg">
                    <span>{generatedPassword}</span>
                    <div className="ml-8">
                      <CopyButton value={generatedPassword}>
                        {({ copied, copy }) => (
                          <Tooltip
                            label={copied ? t('copied') : t('copy')}
                            position="top"
                            withArrow
                          >
                            <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                              {copied ? (
                                <CheckIcon className="w-4 h-4" />
                              ) : (
                                <CopyIcon className="w-4 h-4" />
                              )}
                            </ActionIcon>
                          </Tooltip>
                        )}
                      </CopyButton>
                    </div>
                  </div>
                </div>
              </CustomAlert>
            </div>
          )}
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="flex justify-center gap-4">
          <Button className="w-[220px]" loading={studentCreate.isLoading} type="submit">
            {t('create')}
          </Button>
          <Button className="w-[220px]" onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
