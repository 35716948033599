import { useEffect } from 'react';
import { t } from 'i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Skeleton, Tabs } from '@mantine/core';

import { api, IEnumEducationFormatKeys, IGroup, IStudentsRequest } from '@chess-class/sandbox';
import { QueryTable } from '@chess-class/sandbox/components';
import { AddIcon } from '@chess-class/sandbox/icons';

import GroupNotFound from '~/components/atoms/GroupNotFound';

import { IEnumGroupsMyActionType } from '~mGroups/groups/consts/pageActions/IGroupsMyPageActions';
import { ActivateStudentModal } from '~mGroups/students/components/molecules/ActivateStudentModal';
import { ExcludeStudentModal } from '~mGroups/students/components/molecules/ExcludeStudentModal';
import { AssignStudentClassModal } from '~mGroups/students/components/organisms/AssignStudentClassModal';
import { CreateStudentModal } from '~mGroups/students/components/organisms/CreateStudentModal';
import { EditStudentModal } from '~mGroups/students/components/organisms/EditStudentModal';
import {
  studentsColumnActions,
  studentsColumns,
} from '~mGroups/students/consts/columns/studentsColumns';
import { studentsFilters } from '~mGroups/students/consts/filters/studentsFilters';
import { IStudentsPageActions } from '~mGroups/students/consts/pageActions/IStudentsPageActions';

export default function StudentsTable({
  actioned,
  addStudent,
  classData,
  educationFormat,
  filtered,
  groupIds,
  isLoading,
  isNotFound,
  onFilter,
  schoolId,
  setFiltered,
  setPartialActioned,
  setTotalStudents,
  showTabs,
}: {
  actioned: Partial<IStudentsPageActions>;
  addStudent?: boolean;
  classData?: IGroup;
  educationFormat?: IEnumEducationFormatKeys;
  filtered: Partial<IStudentsRequest>;
  groupIds?: string[];
  isLoading?: boolean;
  isNotFound?: boolean;
  onFilter: (keys: Partial<IStudentsRequest>) => void;
  schoolId?: string;
  setFiltered: (keys: Partial<IStudentsRequest>) => void;
  setPartialActioned: (keys: Partial<IStudentsPageActions>) => void;
  setTotalStudents?: (total: number | undefined, assigned?: number | undefined) => void;
  showTabs?: boolean;
}) {
  const studentsQuery = api.user.students.useStudents({
    ...filtered,
    assigned: actioned.actionType == 'ASSIGNED',
    enabled: !!schoolId && !isNotFound && !!groupIds,
    groupIds,
    schoolId,
  });
  const students = studentsQuery.data?.data;

  useEffect(() => {
    setTotalStudents?.(
      studentsQuery.isLoading
        ? undefined
        : (students?.additionalData?.totalAssigned || 0) +
            (students?.additionalData?.totalUnassigned || 0),
      studentsQuery.isLoading ? undefined : students?.additionalData?.totalAssigned,
    );
  }, [
    studentsQuery.isLoading,
    students?.additionalData?.totalAssigned,
    students?.additionalData?.totalUnassigned,
  ]);

  return (
    <div className="flex flex-col gap-6">
      {isLoading ? (
        <Skeleton className="w-full h-64" />
      ) : isNotFound ? (
        <GroupNotFound />
      ) : (
        <>
          {showTabs && (
            <Tabs
              classNames={{ root: 'flex flex-col gap-6', tab: 'h-10.5', tabsList: 'gap-4' }}
              onTabChange={(value: keyof typeof IEnumGroupsMyActionType) => {
                setFiltered({});
                setPartialActioned({ actionType: value });
              }}
              value={actioned.actionType}
            >
              <Tabs.List>
                <Tabs.Tab value={IEnumGroupsMyActionType.ASSIGNED}>
                  {t('classesPage.myStudents')}{' '}
                  <span className="text-primary-400">
                    {studentsQuery.isLoading ? (
                      <LoadingOutlined />
                    ) : (
                      students?.additionalData.totalAssigned || 0
                    )}
                  </span>
                </Tabs.Tab>
                <Tabs.Tab value={IEnumGroupsMyActionType.UNASSIGNED}>
                  {t('classesPage.unassigned')}{' '}
                  <span className="text-primary-400">
                    {studentsQuery.isLoading ? (
                      <LoadingOutlined />
                    ) : (
                      students?.additionalData.totalUnassigned || 0
                    )}
                  </span>
                </Tabs.Tab>

                {addStudent && (
                  <Button
                    className="ml-auto"
                    onClick={() => setPartialActioned({ action: 'createStudent' })}
                    rightIcon={<AddIcon className="w-4 h-4" />}
                    variant="secondary"
                  >
                    {t('classesPage.addStudent')}
                  </Button>
                )}
              </Tabs.List>
            </Tabs>
          )}

          <QueryTable
            actions={(row) =>
              studentsColumnActions(
                row,
                actioned.actionType || 'ASSIGNED',
                setPartialActioned,
                educationFormat,
              )
            }
            columns={studentsColumns(educationFormat)}
            empty={{
              description: t('classesPage.tableEmptyPlaceholder'),
              title: t('classesPage.noStudents'),
            }}
            query={studentsQuery}
            tableFilter={{
              filtered,
              filters: studentsFilters,
              onFilter,
            }}
          />
        </>
      )}

      <AssignStudentClassModal
        id={actioned.actionId}
        isOpen={actioned.action === 'assignStudent'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />

      <CreateStudentModal
        classData={classData}
        isOpen={actioned.action === 'createStudent'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />

      <EditStudentModal
        id={actioned.actionId}
        isOpen={actioned.action === 'editStudent'}
        onCancel={() => setPartialActioned({ action: undefined, actionId: undefined })}
      />

      <ExcludeStudentModal
        actionType={actioned.action}
        id={actioned.actionId}
        isOpen={
          actioned.action === 'excludeStudentFromClass' ||
          actioned.action === 'excludeStudentFromSchool'
        }
        onCancel={() => setPartialActioned({ action: undefined, actionId: undefined })}
      />

      <ActivateStudentModal
        id={actioned.actionId}
        isOpen={actioned.action === 'deactivateStudent' || actioned.action === 'activateStudent'}
        onClose={() => setPartialActioned({ action: undefined, actionId: undefined })}
      />
    </div>
  );
}
