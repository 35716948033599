import { Link } from 'react-router-dom';
import i18n, { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { Menu } from '@mantine/core';

import { IEnumEducationFormatKeys, IHomeworkCoach } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';

import { ChaptersPopover } from '~mClassQuizzes/components/molecules/ChaptersPopover';

export const homeworkCoachColumns: (
  educationFormat?: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IHomeworkCoach>[] = (educationFormat?: IEnumEducationFormatKeys) => {
  return [
    {
      accessorFn: (row) => (
        <Link to={`/homework/${row.id}`}>
          {t('homework')} #{row?.orderByGroup}
        </Link>
      ),
      accessorKey: 'chapter.priority',
      header: t('title'),
    },
    {
      accessorKey: 'groupName',
      header: educationFormat == 'GENERAL' ? t('class') : t('group'),
    },
    {
      accessorFn: (row) => {
        if (row.chapter) {
          return (
            <Menu closeDelay={400} openDelay={100} trigger="hover" withArrow>
              <Menu.Target>
                <span className="text-[#5D70FF] cursor-pointer">
                  {row.chapter.translations
                    ? row.chapter.translations?.[i18n.language]?.title
                    : `${t('chapter')} #${row.chapter.priority}`}
                </span>
              </Menu.Target>
              <ChaptersPopover chapterIds={[row.chapterId ?? '']} />
            </Menu>
          );
        }

        return '-';
      },
      accessorKey: 'chapter',
      header: t('chapter'),
    },
    {
      accessorFn: (row) => moment(row.givenDate).utcOffset('GMT+05:00').format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'givenDate',
      header: t('startAt'),
    },
    {
      accessorFn: (row) =>
        moment.utc(row.finishAt).utcOffset('GMT+05:00').format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'finishAt',
      header: t('finishDate'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      header: t('status'),
    },
  ];
};
