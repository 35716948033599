import { Route, Routes } from 'react-router-dom';

import { DevPage } from '~mDev/pages/DevPage';

export const DevRouter = () => {
  return (
    <Routes>
      <Route element={<DevPage />} index />
    </Routes>
  );
};
