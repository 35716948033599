import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';

import { IEnumEducationFormatKeys, IQueryTableAction, IStudent } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import {
  ContactsIcon,
  EditIcon,
  PauseIcon,
  PlayCircleIcon,
  TrashIcon,
} from '@chess-class/sandbox/icons';

import { IEnumGroupsMyActionType } from '~mGroups/groups/consts/pageActions/IGroupsMyPageActions';
import { IStudentsPageActions } from '~mGroups/students/consts/pageActions/IStudentsPageActions';

export const studentsColumns: (
  educationFormat?: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IStudent>[] = (educationFormat?: IEnumEducationFormatKeys) => {
  return [
    {
      accessorFn: (row) => {
        return row ? (
          <Link
            className={'whitespace-nowrap'}
            onClick={() =>
              ReactGA.event({
                action: 'student_profile',
                category: 'student_profile',
                label: 'student_profile',
              })
            }
            to={`/classes/student/${row.id}`}
          >{`${row.lastName} ${row.firstName} ${row.middleName}`}</Link>
        ) : (
          '-'
        );
      },
      accessorKey: 'firstName',
      header: t('fio'),
    },
    {
      accessorFn: ({ email }) => <span className="text-gray-500">{email}</span>,
      accessorKey: 'email',
      header: t('email'),
    },
    {
      accessorFn: (row) => row.groupName || '-',
      accessorKey: 'groupName',
      header: educationFormat == 'GENERAL' ? t('class') : t('group'),
    },
    {
      accessorFn: (row) =>
        row.lastActivityAt ? moment(row.lastActivityAt).format('DD/MM/YYYY, HH:mm') : '-',
      accessorKey: 'lastActivityAt',
      header: t('activity'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      header: t('status'),
    },
  ];
};

export const studentsColumnActions: (
  row: IStudent,
  type: keyof typeof IEnumGroupsMyActionType,
  setPartialActioned: (keys: Partial<IStudentsPageActions>) => void,
  educationFormat?: IEnumEducationFormatKeys,
) => IQueryTableAction[] = (row, type, setPartialActioned, educationFormat) => [
  ...(type === 'ASSIGNED'
    ? [
        {
          icon: <EditIcon />,
          onClick: () => {
            setPartialActioned({ action: 'editStudent', actionId: row.id });
          },
          title: t('edit'),
        },
        {
          icon: <TrashIcon />,
          onClick: () => {
            setPartialActioned({
              action: 'excludeStudentFromClass',
              actionId: row.id,
            });
          },
          title:
            educationFormat == 'GENERAL'
              ? t('classesPage.excludeFromClass')
              : t('classesPage.excludeFromGroup'),
        },
      ]
    : []),
  {
    icon: <TrashIcon />,
    onClick: () => {
      setPartialActioned({
        action: 'excludeStudentFromSchool',
        actionId: row.id,
      });
    },
    title: t('classesPage.excludeFromSchool'),
  },
  ...(row.status == 'INVITED'
    ? []
    : [
        {
          icon: row.status === 'ACTIVE' ? <PauseIcon /> : <PlayCircleIcon />,
          onClick: () => {
            setPartialActioned({
              action: row.status === 'ACTIVE' ? 'deactivateStudent' : 'activateStudent',
              actionId: row.id ?? '',
            });
          },
          title: row.status === 'ACTIVE' ? t('pause') : t('activate'),
        },
      ]),
  ...(type == 'UNASSIGNED'
    ? [
        {
          icon: <ContactsIcon />,
          onClick: () => {
            setPartialActioned({
              action: 'assignStudent',
              actionId: row.id,
            });
          },
          title:
            educationFormat == 'GENERAL'
              ? t('classesPage.assignClass')
              : t('classesPage.assignGroup'),
        },
      ]
    : []),
];
