import { FC, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Skeleton, Tabs, Title } from '@mantine/core';

import { api, ICoachClassQuizzesRequest } from '@chess-class/sandbox';
import { QueryTable } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { EditIcon, TrashIcon } from '@chess-class/sandbox/icons';

import GroupNotFound from '~/components/atoms/GroupNotFound';
import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { ClassQuizCreateModal } from '~mClassQuizzes/components/organisms/ClassQuizCreateModal';
import { ClassQuizEditModal } from '~mClassQuizzes/components/organisms/ClassQuizEditModal';
import { classQuizzesColumns } from '~mClassQuizzes/consts/columns/classQuizzesColumns';
import { classQuizzesFilters } from '~mClassQuizzes/consts/filters/classQuizzesFilters';
import {
  classQuizzesPageActions,
  IClassQuizzesPageActions,
} from '~mClassQuizzes/consts/pageActions/IClassQuizzesPageActions';
import QuizGradeTable from '~mHomeworks/components/organisms/QuizGradeTable';

const ClassQuizzesPage: FC = () => {
  const { myClasses, school } = useCoachContext();
  const { actioned, filtered, onFilter, setPartialActioned } = useUrlFilteredActioned<
    ICoachClassQuizzesRequest,
    IClassQuizzesPageActions
  >(classQuizzesFilters, classQuizzesPageActions);

  const [gradesOpened, setGradesOpened] = useState(false);

  const classQuizDelete = api.education.classQuiz.useCoachClassQuizDelete();
  const classQuizzesQuery = api.education.classQuiz.useCoachClassQuizzes({
    ...filtered,
    classId: actioned.actionClassId,
    enabled: !!actioned.actionClassId,
    refetchOnMount: false,
  });

  const activeClass = myClasses?.find((cl) => cl.id == actioned.actionClassId);

  useEffect(() => {}, []);

  return (
    <div className="py-4 px-2 flex flex-col gap-8 h-full">
      <PageMeta
        openMenuKeys={['classQuiz']}
        selectedMenuKeys={['classQuiz']}
        title={t('assignments')}
      />
      <div className="flex items-center gap-4 justify-between">
        <div>
          <Title order={2}>{t('assignment')}</Title>
          <span className="text-gray-108">
            {classQuizzesQuery.data?.data.totalElements ?? 0} {String(t('total')).toLowerCase()}
          </span>
        </div>
        {myClasses?.length ? (
          <div className="flex flex-row gap-6">
            <Select
              classNames={{ input: 'bg-white' }}
              data={myClasses.map((group) => ({
                label:
                  school?.educationFormat == 'GENERAL'
                    ? `${group.grade} ${group.symbol}`
                    : group.symbol,
                value: group.id,
              }))}
              onChange={(id) => {
                setPartialActioned({ actionClassId: id || '' });
              }}
              placeholder={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
              value={actioned.actionClassId}
            />
            <Button
              className="py-4 h-13"
              onClick={() => setPartialActioned({ action: 'create' })}
              rightIcon={<PlusOutlined />}
              variant="filled"
            >
              {t('assignmentsPage.createAssignment')}
            </Button>
          </div>
        ) : (
          !myClasses && (
            <div className="flex flex-row gap-6">
              <Skeleton className="h-14 w-56 rounded-lg" />
              <Skeleton className="h-14 w-44 rounded-lg" />
            </div>
          )
        )}
      </div>

      {!!myClasses && !myClasses.length ? (
        <GroupNotFound />
      ) : (
        <Tabs
          defaultValue="classQuiz"
          onTabChange={(e) => {
            if (e == 'grades') setGradesOpened(true);
          }}
          unstyled
        >
          <Tabs.List className="mb-5 bg-transparent">
            <Tabs.Tab className="mr-4" value="classQuiz">
              {t('assignment')}
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() =>
                ReactGA.event({
                  action: 'tests_results',
                  category: 'tests_results',
                  label: 'tests_results',
                })
              }
              value="grades"
            >
              {school?.educationFormat == 'GENERAL' ? t('grades') : t('results')}
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="classQuiz">
            <QueryTable
              actions={(row) => [
                ...(row.status == 'ISSUED'
                  ? [
                      {
                        icon: <EditIcon />,
                        onClick: () => {
                          setPartialActioned({
                            action: 'edit',
                            actionId: row.id,
                          });
                        },
                        title: t('edit'),
                      },
                      {
                        icon: <TrashIcon />,
                        onClick: () => classQuizDelete.mutateAsync({ id: row.id }),
                        title: t('delete'),
                      },
                    ]
                  : []),
              ]}
              columns={classQuizzesColumns(school?.educationFormat)}
              empty={{
                description: t('assignmentsPage.createdList'),
                title: t('assignmentsPage.callToActionCreate'),
              }}
              query={classQuizzesQuery}
              tableFilter={{
                filtered,
                filters: classQuizzesFilters,
                onFilter,
              }}
            />
          </Tabs.Panel>

          <Tabs.Panel value="grades">
            {!!actioned.actionClassId && (
              <QuizGradeTable
                enabled={gradesOpened}
                gradeType={activeClass?.school?.gradeType}
                groupId={actioned.actionClassId}
              />
            )}
          </Tabs.Panel>
        </Tabs>
      )}

      <ClassQuizCreateModal
        groupId={actioned.actionClassId}
        isOpen={actioned.action === 'create'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />

      <ClassQuizEditModal
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onCancel={() => setPartialActioned({ action: undefined })}
      />
    </div>
  );
};

export default ClassQuizzesPage;
