import { FC, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18next';
import { Button, clsx, Menu } from '@mantine/core';

import { ChevronDownIcon, WorldIcon } from '@chess-class/sandbox/icons';

import { storageKeys } from '~/consts/storageKeys';
import { CoachContext } from '~/context/CoachContext';

type IProps = {
  languages: string[];
};

const LanguageDropdown: FC<IProps> = ({ languages }) => {
  const {
    i18n: { changeLanguage, language },
    t,
  } = useTranslation();
  const { coachMe } = useContext(CoachContext);
  const [searchParams] = useSearchParams();
  const languageQuery = searchParams.get('lang');

  useEffect(() => {
    if (languageQuery) {
      changeLanguage(languageQuery);
      localStorage.setItem(storageKeys.language, searchParams.get('lang') as string);
    }
    if (!languageQuery && coachMe?.language && !localStorage.getItem(storageKeys.language)) {
      localStorage.setItem(storageKeys.language, coachMe.language);
      changeLanguage(coachMe.language);
    }
    if (!languages.includes(language)) {
      localStorage.setItem(storageKeys.language, 'ru');
      changeLanguage('ru');
    }
  }, [languages, coachMe, languageQuery]);

  const isDev =
    process.env.REACT_APP_API_URL.includes('api.dev') || process.env.NODE_ENV == 'development';

  const filteredLanguages = () => {
    if (isDev) {
      return languages;
    } else {
      return languages.filter((lan) => lan == 'en' || lan == 'ru' || lan == 'kz');
    }
  };

  return (
    <Menu classNames={{ dropdown: 'rounded-lg mt-4' }} keepMounted>
      <Menu.Target>
        <Button
          className="p-2 text-sm md:text-base text-gray-500 border-gray-101 capitalize"
          leftIcon={<WorldIcon className="h-5 w-5 md:h-6 md:w-6" />}
          rightIcon={
            <ChevronDownIcon className="rotate-180 text-[#a3a3a3] h-4 w-4 md:h-6 md:w-6" />
          }
          variant="outline"
        >
          {language}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label className="font-semibold text-gray-500"> {t('chooseLanguage')}</Menu.Label>

        <Menu.Divider />

        {filteredLanguages()?.map((locale) => (
          <Menu.Item
            className={clsx(
              'hover:text-primary hover:font-semibold',
              i18n.language == locale ? 'text-primary font-semibold' : 'font-medium',
            )}
            icon={
              <img
                alt={locale}
                className="w-6 h-6 object-contain"
                src={`/images/countries/512x512/${locale}.png`}
              />
            }
            key={`localization-${locale}`}
            onClick={() => {
              if (locale != i18n.language) {
                localStorage.setItem(storageKeys.language, locale);
                window.location.reload();
              }
              ReactGA.event({
                action: 'change_lang',
                category: 'change_lang',
                label: 'change_lang',
              });
            }}
          >
            {locale.toUpperCase()}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};

export default LanguageDropdown;
