import { useMemo } from 'react';

import { ILocalization } from '@chess-class/sandbox';
import { QueryTableStatic } from '@chess-class/sandbox/components';
import { enTranslations, kzTranslations, ruTranslations } from '@chess-class/sandbox/consts';

type IComponentTranslation = (Record<ILocalization, string> & { key: string })[];

const getDeepProperty = (obj: object, prop: string): string => {
  const deepValue = prop.split('.').reduce((r, e) => {
    return r[e];
  }, obj);
  return typeof deepValue == 'string' ? deepValue : '';
};

function objectRecursive(
  translations: Record<string, string | object> | object,
  startKey = '',
): IComponentTranslation {
  const transl: IComponentTranslation = [];

  Object.entries(translations).forEach(([key, value]) => {
    const valueKey = startKey ? `${startKey}.${key}` : key;

    if (typeof value == 'string') {
      transl.push({
        de: getDeepProperty(ruTranslations.translation, valueKey.replace('_many', '_other')),
        en: getDeepProperty(enTranslations.translation, valueKey.replace('_many', '_other')),
        fr: getDeepProperty(ruTranslations.translation, valueKey.replace('_many', '_other')),
        key: valueKey,
        kz: getDeepProperty(kzTranslations.translation, valueKey.replace('_many', '_other')),
        ru: getDeepProperty(ruTranslations.translation, valueKey.replace('_many', '_other')),
      });
    } else if (typeof value == 'object') {
      transl.push(...objectRecursive(value, valueKey));
    }
  });
  return transl;
}

export default function DevTranslationsTab() {
  const memoTranslations = useMemo(() => objectRecursive(ruTranslations.translation), []);

  return (
    <div className="flex flex-col gap-8">
      <QueryTableStatic
        columns={[
          { accessorKey: 'key', header: 'Ключ' },
          { accessorKey: 'ru', header: 'RU' },
          { accessorKey: 'en', header: 'EN' },
          { accessorKey: 'kz', header: 'KZ' },
          { accessorKey: 'fr', header: 'FR' },
          { accessorKey: 'de', header: 'DE' },
        ]}
        data={memoTranslations}
        empty={{ title: 'Нет языков' }}
        search
      />
    </div>
  );
}
