import * as Yup from 'yup';
import { Box, Button, Group, NumberInput, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

const schema = Yup.object().shape({
  age: Yup.number().min(18, 'You must be at least 18 to create an account'),
  email: Yup.string().email('Invalid email'),
  name: Yup.string().min(2, 'Name should have at least 2 letters'),
});

export default function DevFormTab() {
  const form = useForm({
    initialValues: {
      age: 18,
      email: '',
      name: '',
    },
    validate: yupResolver(schema),
  });

  return (
    <Box maw={340} mx="auto">
      <form onSubmit={form.onSubmit((values) => alert(values))}>
        <TextInput
          label="Email"
          placeholder="example@mail.com"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <TextInput
          label="Name"
          mt="sm"
          placeholder="John Doe"
          withAsterisk
          {...form.getInputProps('name')}
        />
        <NumberInput
          label="Age"
          mt="sm"
          placeholder="Your age"
          withAsterisk
          {...form.getInputProps('age')}
        />

        <Group mt="xl" position="right">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Box>
  );
}
