import { FC } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { Button, Modal, PasswordInput, Skeleton, TextInput, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useQueryClient } from '@tanstack/react-query';

import { api, ICoachPasswordUpdateRequest, ICoachProfileEditRequest } from '@chess-class/sandbox';
import { InputSkeleton, NotFoundResult } from '@chess-class/sandbox/components';
import { queryKeys } from '@chess-class/sandbox/consts';
import { notify } from '@chess-class/sandbox/utils';

import { useCoachContext } from '~/context/CoachContext';

export const ProfileModal: FC<{
  onClose: () => void;
  opened: boolean;
}> = ({ onClose, opened }) => {
  const { coachMe, coachMeLoading } = useCoachContext();
  const queryClient = useQueryClient();

  const form = useForm<ICoachProfileEditRequest & ICoachPasswordUpdateRequest>({
    initialValues: {
      confirmNewPassword: '',
      firstName: coachMe?.firstName ?? '',
      id: coachMe?.id ?? '',
      lastName: coachMe?.lastName ?? '',
      middleName: coachMe?.middleName ?? '',
      newPassword: '',
      phone: coachMe?.phone ?? '',
      resetPassword: false,
    },
    validate: yupResolver(
      api.user.coachToSchool.helpers.profileEditValidation.concat(
        api.user.coach.helpers.coachPasswordUpdateValidation,
      ),
    ),
    validateInputOnChange: true,
  });

  const profile = api.user.coachToSchool.useCoachProfileEdit();
  const profilePassword = api.user.coach.useCoachPasswordUpdate();

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={opened}
      size="80%"
      title={t('profilePage.profileSettings')}
    >
      {coachMeLoading ? (
        <div className="grid grid-cols-2 gap-y-6 gap-x-4">
          <Skeleton className="h-20 w-20 rounded-xl col-span-full" />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
          <InputSkeleton label />
        </div>
      ) : !coachMe ? (
        <NotFoundResult title={t('messages.errorProfileNotFound')} />
      ) : (
        <div className="flex flex-col gap-6 px-6 pt-6">
          <div className="border-4 border-gray-104 bg-gray-50 w-[85px] h-[85px] flex items-center justify-center text-primary text-3xl font-medium rounded-xl">
            {coachMe?.firstName?.[0]}
            {coachMe?.lastName?.[0]}
          </div>
          <form
            className="grid grid-cols-2 gap-y-6 gap-x-4"
            onSubmit={form.onSubmit(async (values) => {
              await profile
                .mutateAsync(values)
                .then(() => {
                  notify('success', t('messages.successCoachEdit'));
                  values.resetPassword &&
                    profilePassword
                      .mutateAsync({ id: values.id, newPassword: values.newPassword })
                      .then(() => {
                        ReactGA.event({
                          action: 'change_password',
                          category: 'change_password',
                          label: 'change_password',
                        });
                      });
                  queryClient.invalidateQueries([queryKeys.me]);
                  handleClose();
                })
                .catch(() => {
                  notify('error', t('tryAgainLater'));
                });
            })}
          >
            <TextInput label={t('lastName')} {...form.getInputProps('lastName')} withAsterisk />
            <TextInput label={t('firstName')} {...form.getInputProps('firstName')} withAsterisk />
            <TextInput label={t('middleName')} {...form.getInputProps('middleName')} />
            <TextInput
              disabled
              label={t('email')}
              placeholder="your@email.com"
              value={coachMe.email}
            />
            <TextInput
              label={t('phoneNumber')}
              left="+7"
              withAsterisk
              {...form.getInputProps('phone')}
            />
            {!form.values.resetPassword && (
              <Button
                className="self-end py-4"
                onClick={() => form.setFieldValue('resetPassword', true)}
                variant="default"
              >
                {t('auth.changePassword')}
              </Button>
            )}
            {form.values.resetPassword && (
              <>
                <div className="border-t col-span-full my-6" />
                <Title className="col-span-full" order={2}>
                  {t('auth.password')}
                </Title>
                <PasswordInput
                  label={t('auth.newPassword')}
                  placeholder={t('auth.passwordMinLength')}
                  withAsterisk
                  {...form.getInputProps('newPassword')}
                />
                <PasswordInput
                  label={t('auth.confirmPassword')}
                  withAsterisk
                  {...form.getInputProps('confirmNewPassword')}
                />
              </>
            )}

            <div className="border-t col-span-full mt-8 mb-2" />

            <div className="col-span-full flex gap-4">
              <Button className="w-44" disabled={!form.isDirty()} type="submit">
                {t('save')}
              </Button>
              <Button className="w-44" onClick={handleClose} variant="outline">
                {t('cancel')}
              </Button>
            </div>
          </form>
        </div>
      )}
    </Modal>
  );
};
