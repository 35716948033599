import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment';

import {
  api,
  IClassQuiz,
  IClassQuizStudentEvaluation,
  IEnumSchoolGradeTypeKeys,
  IHomeworkCoach,
  IHomeworkStudentEvaluation,
} from '@chess-class/sandbox';
import { GradeBox, QueryTableStatic, TableSkeleton } from '@chess-class/sandbox/components';

import { CoachContext } from '~/context/CoachContext';
import { TimeSortEnum } from '~/modules/groups/groups/consts/timeSort';

import { AntdChaptersPopover } from '~mClassQuizzes/components/molecules/AntdChaptersPopover';

import 'moment/locale/de';
import 'moment/locale/ru';
import 'moment/locale/fr';
import 'moment/locale/kk';

type GradeTableData = Record<string, number | string | undefined>;

const baseGradesColumn = (key: string, title: string): MRT_ColumnDef => ({
  Header: <span className="text-transparent">`</span>,
  columns: [
    {
      columns: [
        {
          Header: <span className="text-transparent">`</span>,
          accessorKey: key,
          enableSorting: false,
          header: `${key}-3`,
          size: 60,
        },
      ],
      enableSorting: false,
      header: title,
      size: 60,
    },
  ],
  enableSorting: false,
  header: `${key}-1`,
  size: 60,
});

export default function QuizGradeTable({
  enabled,
  gradeType = 'GRADE_TYPE_5',
  groupId,
  isHomework = false,
  timeSort = 'NEWEST',
}: {
  enabled: boolean;
  gradeType?: IEnumSchoolGradeTypeKeys;
  groupId: string;
  isHomework?: boolean;
  timeSort?: keyof typeof TimeSortEnum;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  moment.locale(language ?? 'en');

  const groupQuizzesQuery = isHomework
    ? api.education.homework.useCoachHomeworks({
        enabled,
        groupId: groupId,
        size: 9999,
        sort: timeSort == 'OLDEST' ? 'finishAt' : '-finishAt',
      })
    : api.education.classQuiz.useCoachClassQuizzes({
        classId: groupId,
        enabled,
        refetchOnMount: false,
        size: 9999,
        sort: timeSort == 'OLDEST' ? 'finishAt' : '-finishAt',
      });

  const gradesQuery = isHomework
    ? api.education.homework.useCoachHomeworkStudents({
        enabled: !!groupId && enabled,
        groupId: groupId ?? '',
        size: 9999,
      })
    : api.education.classQuiz.useCoachClassQuizzesStudents({
        enabled: !!groupId && enabled,
        groupId: groupId ?? '',
        size: 9999,
      });

  const groupQuizzes = groupQuizzesQuery.data?.data.content;

  const grades = gradesQuery.data?.data.content;
  const { school } = useContext(CoachContext);

  const gradesColumns = useMemo(() => {
    return (groupQuizzes || []).reduce<MRT_ColumnDef<GradeTableData>[]>(
      (columns, quiz) => {
        const date = new Date(quiz.finishAt);

        const monthYear = moment(`${date.getFullYear()}-${date.getMonth() + 1}`).format(
          'MMMM (YYYY)',
        );

        const columnFind = columns.find((col) => col.header == monthYear);

        const quizTitle = isHomework ? (
          `${t('homework')} #${(quiz as IHomeworkCoach).orderByGroup}`
        ) : (
          <AntdChaptersPopover
            chapterIds={(quiz as IClassQuiz).chapterIds}
            classQuizId={(quiz as IClassQuiz).id}
            classQuizIndex={(quiz as IClassQuiz).orderByClass}
          />
        );

        const quizColumn: MRT_ColumnDef<GradeTableData> = {
          Header: quizTitle,
          columns: [
            {
              accessorFn: (row) =>
                row[quiz.id] ? (
                  <GradeBox
                    className="mx-auto w-fit"
                    grade={(row[quiz.id] as number) || 0}
                    gradeType={school?.educationFormat == 'GENERAL' ? gradeType : 'GRADE_TYPE_10'}
                  />
                ) : (
                  '-'
                ),
              accessorKey: `${quiz.id}.evaluation`,
              header: moment(quiz.finishAt).format('DD.MM.YYYY'),
            },
          ],
          header: `${quiz.id}-title`,
        };

        if (!columnFind) {
          return [
            ...columns,
            {
              Header: <div className="m-auto">{monthYear}</div>,
              columns: [quizColumn],
              header: monthYear,
            },
          ];
        } else {
          columnFind.columns?.push(quizColumn);
        }

        return columns;
      },
      [baseGradesColumn('studentIndex', '№'), baseGradesColumn('fullName', t('student'))],
    );
  }, [groupQuizzes, timeSort]);

  const gradesTableData = useMemo(
    () =>
      (grades || []).reduce<GradeTableData[]>((dataArray, studentEvaluations, studentIndex) => {
        if (!groupQuizzes) return dataArray;

        const studentQuizzesEvaluations = isHomework
          ? studentEvaluations.homeWorkEvaluations
          : studentEvaluations.classQuizEvaluations;

        return [
          ...dataArray,
          groupQuizzes?.reduce<GradeTableData>(
            (data, quiz) => ({
              ...data,
              [quiz.id]: studentQuizzesEvaluations.find((evaluation) =>
                isHomework
                  ? (evaluation as IHomeworkStudentEvaluation).homeWork.id == quiz.id
                  : (evaluation as IClassQuizStudentEvaluation).classQuiz.id == quiz.id,
              )?.evaluation,
            }),
            {
              fullName: `${studentEvaluations.firstName} ${studentEvaluations.lastName}`,
              id: studentEvaluations.id,
              studentIndex: studentIndex + 1,
            },
          ),
        ];
      }, []),
    [grades, groupQuizzes, gradeType],
  );

  return groupQuizzesQuery.isLoading || gradesQuery.isLoading ? (
    <TableSkeleton />
  ) : (
    <QueryTableStatic
      columns={gradesColumns}
      data={gradesTableData}
      empty={{ description: '-', title: t('classesPage.noGrades') }}
      key={timeSort}
      props={{
        defaultColumn: { maxSize: 300, minSize: 60, size: undefined },
        mantineTableBodyCellProps: {
          className: 'gradesTableRow border-gray-200 text-center h-12 p-0',
        },
        mantineTableContainerProps: { className: 'border border-gray-200 border-solid rounded-xl' },
        mantineTableHeadCellProps: {
          className: 'px-2 py-1 border-[rgb(178_210_246)] text-primary-700 text-xs',
          sx: { svg: { height: '16px', width: '16px' } },
        },
        mantineTableHeadRowProps: { className: 'shadow-none bg-transparent' },
        mantineTableProps: { className: 'border-none', withBorder: true, withColumnBorders: true },
      }}
    />
  );
}
