import { type FC, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { Button, Divider, Modal, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IGroupUpdateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';
import { fullName } from '~/utils/fullName';

import { classLanguageList } from '~mGroups/groups/consts/languages';

type IGroupEditModal = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const GroupEditModal: FC<IGroupEditModal> = ({ id = '', isOpen, onCancel }) => {
  const { coachMe, school } = useContext(CoachContext);

  const classQuery = api.schools.groups.useGroup({ enabled: isOpen, id });
  const classData = classQuery.data?.data;

  const schoolCoachesQuery = api.user.coachToSchool.useCoachesToSchoolWithGroups({
    enabled: isOpen && school?.isSuperCoach,
    schoolIds: school && school?.isSuperCoach ? [school.schoolId] : [],
  });
  const schoolCoaches = schoolCoachesQuery.data?.data.content;

  const classUpdate = api.schools.groups.useGroupUpdate();

  const form = useForm<IGroupUpdateRequest>({
    initialValues: { ...api.schools.groups.helpers.groupCreateInitial, id },
    validate: yupResolver(api.schools.groups.helpers.groupUpdateValidation),
  });

  useEffect(() => {
    if (classData) {
      form.setValues({
        coachId: classData.coachId,
        grade: classData.grade,
        id: classData.id,
        language: classData.language,
        schoolId: classData.school?.id,
        symbol: classData.symbol,
      });
    }
  }, [classData]);

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={isOpen}
      size={570}
      title={
        school?.educationFormat == 'GENERAL'
          ? t('classesPage.editClass')
          : t('classesPage.editGroup')
      }
    >
      <form
        onSubmit={form.onSubmit((values) => {
          classUpdate
            .mutateAsync(values)
            .then(() => {
              notify(
                'success',
                school?.educationFormat == 'GENERAL'
                  ? t('messages.successClassEdit')
                  : t('messages.successGroupEdit'),
              );
              onCancel();
              ReactGA.event({
                action: 'edit_group',
                category: 'edit_group',
                label: 'edit_group',
              });
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-4 grid-cols-2 p-5">
          {school?.educationFormat == 'GENERAL' && (
            <Select
              data={['1', '2', '3', '4']}
              label={t('classesPage.classNumber')}
              name="grade"
              required
              {...form.getInputProps('grade')}
              value={form.values.grade?.toString()}
            />
          )}
          <TextInput
            className={school?.educationFormat == 'GENERAL' ? undefined : 'col-span-full'}
            label={school?.educationFormat == 'GENERAL' ? t('classesPage.classLetter') : t('group')}
            name="symbol"
            withAsterisk
            {...form.getInputProps('symbol')}
          />

          <Select
            className="col-span-2"
            data={
              school?.educationFormat == 'GENERAL'
                ? classLanguageList.filter((lan) => lan.value != 'en')
                : classLanguageList.filter((lan) => lan.value != 'kz')
            }
            label={t('classesPage.educationLanguage')}
            name="language"
            required
            {...form.getInputProps('language')}
          />

          <Select
            className="col-span-2"
            data={
              school?.isSuperCoach
                ? schoolCoaches?.map((coach) => ({
                    label: fullName(coach),
                    value: coach?.coachUserId || '',
                  })) || []
                : [{ label: fullName(coachMe), value: coachMe?.id || '' }]
            }
            disabled={!school?.isSuperCoach}
            label={t('coach')}
            name="coachId"
            {...form.getInputProps('coachId')}
          />
        </div>

        <Divider className="my-6 -mx-6" />

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button loading={classUpdate.isLoading} type="submit">
            {t('save')}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
