import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const exerciseFilters: IFilters = {
  ...paginationBaseFilters,
  sort: { queries: [{ default: 'id' }] },
  [modelKeys.content.exercise.id]: {
    sort: true,
  },
};
