import { IPageActions } from '@chess-class/sandbox';

enum IEnumGroupsPageActions {
  'create',
  'edit',
  'delete',
}

export type IGroupsPageActions = {
  action: keyof typeof IEnumGroupsPageActions;
  actionId: string;
  actionType: string;
};

export const groupsPageActions: IPageActions<IGroupsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumGroupsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: '' },
};
