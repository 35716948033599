import { type FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Dropdown } from 'antd';
import i18n from 'i18next';

import { api } from '@chess-class/sandbox';

import { CoachContext } from '~/context/CoachContext';

type AntdChaptersPopoverProps = {
  chapterIds: string[];
  classQuizId?: string;
  classQuizIndex?: number;
};

export const AntdChaptersPopover: FC<AntdChaptersPopoverProps> = ({
  chapterIds,
  classQuizId,
  classQuizIndex,
}) => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);

  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    enabled: chapterIds.length > 0,
    ids: chapterIds,
  });

  const chapters = chaptersQuery.data?.data.content ?? [];

  const sortedChapters =
    school?.educationFormat == 'GENERAL' ? chapters : chapters?.sort((a, b) => a.numId - b.numId);

  return (
    <Dropdown
      arrow
      menu={{
        items: sortedChapters.map((chapter) => ({
          key: chapter.id,
          label: (
            <NavLink to={`/chapters/${chapter.id}`}>
              <div className="flex items-center gap-3 text-base">
                <span className="text-primary-400 font-semibold">
                  {t('chapter')}{' '}
                  {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}
                </span>
                <span className="text-gray-500">
                  {chapter.translations ? chapter.translations[i18n.language]?.title : '-'}
                </span>
              </div>
            </NavLink>
          ),
        })),
      }}
      overlayClassName="chapters-dropdown"
      placement="bottom"
    >
      <NavLink
        className="cursor-pointer !text-primary-700 block text-ellipsis overflow-hidden whitespace-nowrap"
        to={`/classQuiz/${classQuizId}`}
      >
        {`${t('assignment')} #${classQuizIndex ?? 0}`}
      </NavLink>
    </Dropdown>
  );
};
