import { Route, Routes } from 'react-router-dom';

import AuthInvitationPage from '~mAuth/pages/AuthInvitationPage';
import AuthResetPage from '~mAuth/pages/AuthResetPage';
import AuthResetPasswordPage from '~mAuth/pages/AuthResetPasswordPage';
import AuthSigninPage from '~mAuth/pages/AuthSigninPage';

export default function AuthRouter() {
  return (
    <Routes>
      <Route element={<AuthSigninPage />} path="*" />
      <Route element={<AuthResetPage />} path="reset" />
      <Route element={<AuthResetPasswordPage />} path="reset/:link" />
      <Route element={<AuthInvitationPage />} path="invitation/:link" />
    </Routes>
  );
}
