import { useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import i18n, { t } from 'i18next';
import { ActionIcon, Button, Modal, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import {
  api,
  IEnumReviewContentType,
  IExercise,
  IGuidelineVideo,
  IVideo,
} from '@chess-class/sandbox';
import { NotFoundResult } from '@chess-class/sandbox/components';
import { ArrowBackIcon, FullScreenIcon } from '@chess-class/sandbox/icons';

import { ReviewContent } from '~/components/organisms/ReviewContent';
import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import StudioDemoExercise from '~mStudio/components/organisms/StudioDemoExercise';
import StudioDemoVideo from '~mStudio/components/organisms/StudioDemoVideo';

export default function StudioGroupDemoPage() {
  const { chapterId = '', id = '' } = useParams<{ chapterId: string; id: string }>();
  const { myClasses, school, schoolLoading } = useCoachContext();
  const [taskIndex, setTaskIndex] = useState(0);
  const [isFinishOpen, finishOpen] = useDisclosure(false);

  const classChaptersQuery = api.education.classChapters.useClassChapters({
    groupId: id,
    page: 0,
    size: 100,
  });
  const status = classChaptersQuery.data?.data.find((chap) => chap.chapterId == chapterId)?.status;

  const selectedClass = useMemo(
    () => (schoolLoading ? null : myClasses?.find((c) => c.id == id)),
    [myClasses],
  );

  const chapterQuery = api.guideline.guidelineChapters.useGuidelineChapter({ id: chapterId });
  const chapter = chapterQuery.data?.data;

  const tasks = useMemo(
    () => [
      ...(chapter?.lessons || []),
      ...(chapter?.cartoons || []),
      ...(chapter?.classWorkExercises || []),
    ],
    [chapter],
  );

  const currentTask: IGuidelineVideo | IExercise | undefined = useMemo(
    () => tasks[taskIndex],
    [tasks, taskIndex],
  );

  const onNext =
    status != 'AVAILABLE' && taskIndex + 1 >= tasks.length
      ? undefined
      : () => {
          if (taskIndex + 1 < tasks.length) {
            setTaskIndex((prev) => prev + 1);
          } else {
            finishOpen.open();
          }
        };

  const onBack =
    taskIndex < 1
      ? undefined
      : () => {
          setTaskIndex((prev) => prev - 1);
        };

  const currentVideo = useMemo(() => {
    const videoLength = (chapter?.cartoons?.length || 0) + (chapter?.lessons?.length || 0);
    if (taskIndex < videoLength) {
      return currentTask as IVideo;
    }
    return undefined;
  }, [currentTask]);

  const currentExercise = useMemo(() => {
    const videoLength = (chapter?.cartoons?.length || 0) + (chapter?.lessons?.length || 0);
    if (taskIndex >= videoLength) {
      return currentTask as IExercise;
    }
    return undefined;
  }, [currentTask]);

  const isLoading = chapterQuery.isFetching || schoolLoading || selectedClass === undefined;

  const studioGiveChapter = api.education.classChapters.useClassChaptersGive();

  return (
    <div
      className={clsx(
        'absolute bg-[#DBDBEA] w-full left-0 top-0 flex flex-col',
        currentExercise?.type == 'PROBLEM' ? 'h-full' : 'min-h-full',
      )}
    >
      <PageMeta selectedMenuKeys={['studio']} title={t('studiosPage.studioView')} />

      <div className="h-20 flex shrink-0 items-center gap-6 px-10 bg-white border-b shadow">
        <Link relative="path" to={`/studio/${id}/#${chapter?.numId}`}>
          <Button
            className="font-normal pl-3 border-gray-200 rounded-xl"
            leftIcon={<ArrowBackIcon className="w-4 h-4" />}
            variant="outline"
          >
            {t('goBack')}
          </Button>
        </Link>
        <div className="flex items-center flex-grow gap-4">
          <img
            className="w-12 h-12 object-cover rounded"
            src="/images/illustrations/guideline-chapter.png"
          />
          {!chapter ? (
            <Skeleton height={16} width={60} />
          ) : (
            <span className="text-base font-bold text-primary-800">
              {`${t('chapter')}
                  ${school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}: ${
                    chapter.translations?.[i18n.language]?.title || ''
                  }`}
            </span>
          )}
        </div>
        <ActionIcon
          className="w-12 h-12 rounded-lg border-gray-200"
          onClick={() => {
            if (document.fullscreenElement) {
              document.exitFullscreen();
            } else {
              document.body.requestFullscreen();
            }
          }}
          variant="default"
        >
          <FullScreenIcon />
        </ActionIcon>

        {!chapter ? (
          <Skeleton height={48} radius="lg" width={140} />
        ) : status == 'AVAILABLE' ? (
          <div className="flex flex-row items-center">
            <Button
              className="min-w-[120px] px-6 shrink-0"
              onClick={() => {
                ReactGA.event({
                  action: 'change_lang',
                  category: 'change_lang',
                  label: 'change_lang',
                });
                finishOpen.open();
              }}
            >
              {t('studiosPage.endLesson')}
            </Button>
            <ReviewContent
              contentId={chapter?.id ?? ''}
              contentType={IEnumReviewContentType.CHAPTER}
              title={t('review.reviewChapter')}
            />
          </div>
        ) : (
          status == 'GIVEN' && (
            <div className="flex flex-row items-center">
              <Link
                to={`/homework?action=create&actionClassId=${selectedClass?.id}&actionChapterId=${chapter?.rootChapterId}`}
              >
                <Button className="min-w-[120px] px-6 bg-primary-50 text-primary">
                  {t('studiosPage.giveHomeWork')}
                </Button>
              </Link>

              <ReviewContent
                contentId={chapter?.id ?? ''}
                contentType={IEnumReviewContentType.CHAPTER}
                title={t('review.reviewChapter')}
              />
            </div>
          )
        )}
      </div>

      {isLoading ? (
        <Skeleton className="w-2/3 mx-auto mt-10 h-2/3" />
      ) : !chapter === undefined ? (
        <NotFoundResult />
      ) : currentVideo ? (
        <StudioDemoVideo onBack={onBack} onNext={onNext} video={currentVideo} />
      ) : currentExercise ? (
        <StudioDemoExercise exercise={currentExercise} onBack={onBack} onNext={onNext} />
      ) : (
        <div className="flex flex-col flex-grow gap-8 items-center justify-center">
          <h1>Тема закончена</h1>
          <img className="w-40 h-40 object-contain" src="/images/illustrations/pawn-good.png" />
        </div>
      )}

      <Modal
        centered
        classNames={{ body: 'p-0' }}
        onClose={finishOpen.close}
        opened={isFinishOpen}
        size="lg"
      >
        <div className="border-y p-14 text-center">
          <span className="text-gray-600 font-semibold text-base">
            {t('studiosPage.alertEndLesson')}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-4 p-8">
          <Button
            className="min-w-0 w-full"
            loading={studioGiveChapter.isLoading}
            onClick={() => {
              studioGiveChapter.mutateAsync({ chapterId, groupId: id }).then(() => {
                finishOpen.close();
              });
            }}
          >
            {t('finish')}
          </Button>
          <Button className="min-w-0 w-full" onClick={finishOpen.close} variant="default">
            {t('cancel')}
          </Button>
        </div>
      </Modal>
    </div>
  );
}
