import { getHomeworkStatuses, IFilters } from '@chess-class/sandbox';
import { CheckboxFilter } from '@chess-class/sandbox/components';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const homeworksFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.education.homeworkCoach.status]: {
    input: (
      <CheckboxFilter
        options={getHomeworkStatuses().map((status) => ({
          label: status.label as string,
          value: status.value,
        }))}
      />
    ),
    sort: true,
  },
  [modelKeys.education.homeworkCoach.givenDate]: {
    sort: true,
  },
  [modelKeys.education.homeworkCoach.finishAt]: {
    sort: true,
  },
  [modelKeys.education.homeworkCoach.startAt]: {
    sort: true,
  },
  [modelKeys.education.homeworkCoach.chapterId]: {
    sort: true,
  },
  sort: { queries: [{ default: '-createdDate' }] },
};
