import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Skeleton } from '@mantine/core';

import { api, IHomeworkCoach } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import { ClockIcon } from '@chess-class/sandbox/icons';

export default function HomeworkBox({ homework }: { homework?: IHomeworkCoach }) {
  const { t } = useTranslation();
  const classQuery = api.schools.groups.useGroup({
    enabled: !!homework?.groupId,
    id: homework?.groupId ?? '',
  });
  const classData = classQuery.data?.data;

  if (!homework) {
    return <Skeleton />;
  }

  return (
    <div className="flex justify-between items-center rounded-lg bg-white p-8 gap-4 w-full self-start border border-[#F1F1F1]">
      <div className="flex gap-8">
        <img
          alt="Theme"
          className="!max-w-25 w-25 max-h-25 object-contain place-self-start"
          src="/images/illustrations/homework-bg.png"
        />
        <div>
          <h4 className="font-semibold text-base text-black mb-2">
            {homework.name ?? t('homework')}
          </h4>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row">
              <span className="text-[#667080] flex items-center gap-1 mr-8">
                <span className="font-medium">{t('class')}:</span>
                {` ${classData?.grade || ''} ${classData?.symbol || ''}`}
              </span>
            </div>
            <div className="flex flex-row flex-wrap gap-x-6 gap-y-2">
              <span className="text-[#667080] flex flex-wrap items-center gap-1 whitespace-nowrap">
                <ClockIcon className="w-4 h-4" />
                <span className="font-medium">{t('homeworksPage.issued')}:</span>
                {moment(homework.givenDate).format('DD.MM.YYYY HH:mm')}
              </span>
              <span className="text-[#667080] flex flex-wrap items-center gap-1 whitespace-nowrap">
                <ClockIcon className="w-4 h-4" />
                <span className="font-medium">{t('finishDate')}:</span>
                {moment(homework.finishAt).format('DD.MM.YYYY HH:mm')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <StatusTag status={homework.status} />
    </div>
  );
}
