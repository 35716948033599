import { IPageActions } from '@chess-class/sandbox';

enum IEnumClassQuizzesPageActions {
  'view',
  'create',
  'edit',
}

export type IClassQuizzesPageActions = {
  action: keyof typeof IEnumClassQuizzesPageActions;
  actionClassId: string;
  actionId: string;
};

export const classQuizzesPageActions: IPageActions<IClassQuizzesPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumClassQuizzesPageActions).includes(value),
    default: '',
  },
  actionClassId: { default: '' },
  actionId: { default: '' },
};
