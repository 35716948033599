import { FC } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { RingProgress, Table, Text } from '@mantine/core';

import { IClassQuizExercise } from '@chess-class/sandbox';
import { colors } from '@chess-class/sandbox/consts';

import { IExercisePageActions } from '~mClassQuizzes/consts/pageActions/IExercisePageActions';

type IClassQuizCardTable = {
  classQuiz: IClassQuizExercise[];
  index: number;
  setPartialActioned: (keys: Partial<IExercisePageActions>) => void;
};

const ClassQuizCardTable: FC<IClassQuizCardTable> = ({ classQuiz, index, setPartialActioned }) => {
  const { t } = useTranslation();

  //FIXME: map for exercises by chapterIds
  const ths = (
    <tr>
      <th>
        {t('chapter')} {index + 1}
      </th>
      <th>{t('solvedCount')}</th>
      <th>{t('executionReport')}</th>
      <th>Правильно / неправильно</th>
    </tr>
  );

  const rows = classQuiz?.map((exercise) => {
    const completedPercent = Math.round(
      (exercise.completedStudents / exercise.totalStudents) * 100 || 0,
    );

    const failPercent = !completedPercent
      ? 0
      : Math.round((exercise.failedStudents / exercise.completedStudents) * 100);
    const successPercent = !completedPercent ? 0 : 100 - failPercent;

    return (
      <tr
        className="cursor-pointer"
        key={exercise.id}
        onClick={() => {
          ReactGA.event({
            action: 'task',
            category: 'task',
            label: 'task',
          });
          setPartialActioned({
            action: 'view',
            actionId: `${exercise.id}`,
            actionType: exercise.type == 'TEST' ? 'test' : 'exercise',
          });
        }}
      >
        <td className="flex flex-row items-center w-[300px] bg-transparent">
          <img
            alt="Test"
            className="w-12 h-12 mr-6 object-contain"
            src={`/images/illustrations/${exercise.type == 'TEST' ? 'test-img' : 'problem-img'}.png`}
          />
          <Text className="text-base text-[#11122C] font-bold">
            {exercise.type == 'TEST' ? t('problem') : t('exercise')} #{exercise.numId}
          </Text>
        </td>
        <td className="bg-transparent font-semibold">
          <span className="text-primary">{exercise.completedStudents}</span>/
          {exercise.totalStudents}
        </td>
        <td className="flex flex-row items-center bg-transparent">
          <div className="flex flex-row items-center">
            <RingProgress
              sections={[
                {
                  color: '#5D70FF',
                  value: completedPercent,
                },
              ]}
              size={36}
              thickness={6}
            />
            <Text className="ml-3 font-semibold text-xl text-[#4F5672]">{completedPercent}%</Text>
          </div>
        </td>

        <td className="bg-transparent font-semibold">
          <div className="flex flex-row items-center">
            <RingProgress
              sections={[
                {
                  color: colors.red['500'],
                  value: failPercent,
                },
                {
                  color: colors.green['500'],
                  value: successPercent,
                },
              ]}
              size={36}
              thickness={6}
            />
            <Text className="ml-3 font-semibold text-xl text-[#4F5672]">
              {successPercent}%{failPercent ? ` / ${failPercent}%` : ''}
            </Text>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <Table className="bg-white card-table rounded-xl p-6 mb-5" highlightOnHover>
      <thead>{ths}</thead>
      <tbody>{rows}</tbody>
    </Table>
  );
};

export default ClassQuizCardTable;
