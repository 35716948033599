import { type FC, useContext } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal } from '@mantine/core';

import { api } from '@chess-class/sandbox';

import { CoachContext } from '~/context/CoachContext';

type DeleteStudentModalProps = {
  actionType?: string;
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const ExcludeStudentModal: FC<DeleteStudentModalProps> = ({
  actionType,
  id = '',
  isOpen,
  onCancel,
}) => {
  const { t } = useTranslation();
  const studentQuery = api.user.students.useStudent({ id });
  const studentDelete = api.user.students.useStudentDelete();
  const studentExcludeFromClass = api.user.students.useStudentRemoveGroup();
  const { school } = useContext(CoachContext);
  const student = studentQuery.data?.data;

  const fullName = student
    ? `${student?.lastName} ${student?.firstName} ${student?.middleName}`
    : '';

  const handleSubmit = () => {
    if (actionType == 'excludeStudentFromClass') {
      studentExcludeFromClass
        .mutateAsync({ educationFormat: school?.educationFormat, id: student?.id ?? '' })
        .then(() => {
          onCancel();
          ReactGA.event({
            action: 'delete_student',
            category: 'delete_student',
            label: 'delete_student',
          });
        });
    } else if (actionType == 'excludeStudentFromSchool') {
      studentDelete.mutateAsync({ id: student?.id ?? '' }).then(() => {
        onCancel();
        ReactGA.event({
          action: 'delete_student',
          category: 'delete_student',
          label: 'delete_student',
        });
      });
    }
  };

  return (
    <Modal centered onClose={onCancel} opened={isOpen} size={570}>
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold mb-13 mt-5">
        {actionType == 'excludeStudentFromClass' &&
          school?.educationFormat == 'GENERAL' &&
          t('classesPage.excludeStudentFromClass', { fullName })}
        {actionType == 'excludeStudentFromClass' &&
          school?.educationFormat == 'PRIVATE' &&
          t('classesPage.excludeStudentFromGroup', { fullName })}
        {actionType == 'excludeStudentFromSchool' &&
          t('classesPage.excludeStudentFromSchool', { fullName })}
      </div>

      <Divider className="mb-8 -mx-6" />

      <div className="grid gap-4 grid-cols-2">
        <Button
          loading={studentDelete.isLoading || studentExcludeFromClass.isLoading}
          onClick={handleSubmit}
        >
          {t('delete')}
        </Button>
        <Button onClick={onCancel} variant="outline">
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
