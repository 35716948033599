import clsx from 'clsx';
import { t } from 'i18next';
import { Menu, Skeleton, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useLogout } from '@chess-class/sandbox/hooks';
import {
  CheckIcon,
  ChevronDownIcon,
  FiltersIcon,
  LockIcon,
  SchoolIcon,
  SignOutIcon,
} from '@chess-class/sandbox/icons';

import ProfileAvatar from '~/components/atoms/ProfileAvatar';
import { ProfileModal } from '~/components/molecules/ProfileModal';
import { useCoachContext } from '~/context/CoachContext';

export default function ProfileDropdown() {
  const { logout } = useLogout();
  const { coachMe, coachMeLoading, onChangeSchool, school } = useCoachContext();

  const [isProfileOpen, profileOpen] = useDisclosure(false);
  const [isSchoolOpen, schoolOpen] = useDisclosure(false);

  if (coachMeLoading) {
    return (
      <div className="flex gap-3 items-center">
        <Skeleton circle height={40} />
        <div className="flex flex-col gap-2 items-center">
          <Skeleton height={8} radius="xl" width={80} />
          <Skeleton height={8} radius="xl" />
          <Skeleton height={8} radius="xl" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Menu
        classNames={{ dropdown: 'min-w-[200px] lg:min-w-[300px] mt-2.5' }}
        position="bottom-end"
        variant="default"
        withArrow
      >
        <Menu.Target>
          <UnstyledButton className="flex gap-1 md:gap-3 items-center">
            <ProfileAvatar hideSm showCaret showSchool />
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label className="flex items-center gap-4 p-4">
            <ProfileAvatar />
          </Menu.Label>

          <Menu.Divider />

          <Menu.Item icon={<FiltersIcon />} onClick={profileOpen.open}>
            {t('profilePage.profileSettings')}
          </Menu.Item>

          <Menu.Item
            className={clsx(
              'transition duration-300',
              isSchoolOpen ? 'text-primary-800 bg-gray-100' : '',
            )}
            closeMenuOnClick={false}
            icon={
              <SchoolIcon
                className={clsx('transition duration-300', isSchoolOpen ? 'text-primary' : '')}
              />
            }
            onClick={schoolOpen.toggle}
            rightSection={
              <ChevronDownIcon
                className={clsx(
                  'transition-all duration-300',
                  isSchoolOpen ? 'rotate-180' : 'rotate-90',
                )}
                height={16}
                width={16}
              />
            }
          >
            {t('schools')}
          </Menu.Item>

          {isSchoolOpen &&
            coachMe?.schools?.map((itemSchool) => (
              <Menu.Item
                disabled={itemSchool.status != 'ACTIVE'}
                icon={
                  itemSchool.schoolId == school?.schoolId ? (
                    <CheckIcon />
                  ) : itemSchool.status != 'ACTIVE' ? (
                    <LockIcon />
                  ) : (
                    <span className="w-6" />
                  )
                }
                key={itemSchool.schoolId}
                onClick={() => {
                  onChangeSchool(itemSchool);
                  schoolOpen.close();
                }}
              >
                {itemSchool.schoolName}
              </Menu.Item>
            ))}

          <Menu.Divider />

          <Menu.Item icon={<SignOutIcon />} onClick={() => logout()}>
            {t('exit')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <ProfileModal onClose={profileOpen.close} opened={isProfileOpen} />
    </>
  );
}
