import { type FC, useContext } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import i18n from 'i18next';
import { Menu } from '@mantine/core';

import { api } from '@chess-class/sandbox';

import { CoachContext } from '~/context/CoachContext';

type ChaptersPopoverProps = {
  chapterIds: string[];
};

export const ChaptersPopover: FC<ChaptersPopoverProps> = ({ chapterIds }) => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);

  const chaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({ ids: chapterIds });
  const chapters = chaptersQuery.data?.data.content ?? [];

  const sortedChapters =
    school?.educationFormat == 'GENERAL' ? chapters : chapters?.sort((a, b) => a.numId - b.numId);
  return (
    <Menu.Dropdown>
      {chapters &&
        sortedChapters.map((chapter, chapterIndex) => (
          <Menu.Item key={chapter.id}>
            <NavLink
              onClick={() =>
                ReactGA.event({
                  action: 'view_topic',
                  category: 'view_topic',
                  label: 'view_topic',
                })
              }
              to={`/chapters/${chapter.id}`}
            >
              <div className="flex items-center gap-3 text-base">
                <span className="text-primary-400 font-semibold">
                  {t('chapter')}{' '}
                  {school?.educationFormat == 'GENERAL' ? chapterIndex + 1 : chapter.numId}
                </span>
                <span className="text-gray-500">
                  {chapter.translations ? chapter.translations[i18n.language]?.title : '-'}
                </span>
              </div>
            </NavLink>
          </Menu.Item>
        ))}
    </Menu.Dropdown>
  );
};
