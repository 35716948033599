import { FC } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Button, PasswordInput, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachLoginRequest } from '@chess-class/sandbox';

import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

const AuthSigninPage: FC = () => {
  const login = api.user.coachAuth.useCoachLogin();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { coachMe, coachMeFetching } = useCoachContext();

  const form = useForm<ICoachLoginRequest>({
    initialValues: api.user.coachAuth.helpers.coachLoginInitial,
    validate: yupResolver(api.user.coachAuth.helpers.coachLoginValidation),
  });

  return (
    <>
      <PageMeta title={t('auth.auth')} />
      <form
        className="flex flex-col gap-8"
        onSubmit={form.onSubmit(({ email, password }) => {
          login
            .mutateAsync({ email: email.trim(), password: password.trim() })
            .then(() => {
              navigate('/');
              ReactGA.event({
                action: 'authorization',
                category: 'authorization',
                label: 'authorization',
              });
            })
            .catch((e) => {
              if (e.response?.status == 401) {
                form.setFieldError('email', t('auth.incorrectLogin'));
              }
            });
        })}
      >
        <div className="flex flex-col items-center">
          <h2
            className="text-center text-2xl sm:text-3xl md:text-4xl text-gray-900 w-full font-bold"
            dangerouslySetInnerHTML={{ __html: t('auth.welcomeToChess') }}
          />
        </div>

        <TextInput
          label={t('email')}
          name="email"
          placeholder="example@chessclass.ai"
          withAsterisk
          {...form.getInputProps('email')}
        />

        <PasswordInput
          label={t('auth.password')}
          name="password"
          placeholder="******"
          withAsterisk
          {...form.getInputProps('password')}
        />

        {(coachMeFetching || !!coachMe) && (
          <Button
            classNames={{
              label: 'flex gap-4',
              leftIcon: 'm-0',
              root: 'border border-gray-200 rounded-xl py-3 px-10 items-center group hover:bg-gray-100  self-center',
            }}
            loading={coachMeFetching}
            onClick={() => navigate('/')}
            variant="subtle"
          >
            {!!coachMe && (
              <>
                <div className="h-10 w-10 text-center grid content-center rounded-full border border-gray-200 bg-gray-100 group-hover:border-primary-100 group-hover:bg-white">
                  <span className="uppercase text-primary text-sm">
                    {coachMe?.firstName?.[0]}
                    {coachMe?.lastName?.[0]}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span className="font-bold capitalize text-primary-700">
                    {`${coachMe?.firstName} ${
                      coachMe?.middleName ? `${coachMe.middleName[0]}.` : ''
                    } ${coachMe?.lastName}`}
                  </span>
                  <span className="text-xs text-gray-400 font-medium">{coachMe.email}</span>
                </div>
              </>
            )}
          </Button>
        )}

        <div className="flex flex-col gap-4">
          <Button className="w-full" loading={login.isLoading} type="submit" variant="filled">
            {t('auth.enter')}
          </Button>

          <Link to="/auth/reset">
            <Button
              className="w-full bg-[#eeeeff] text-[#020246]"
              classNames={{ label: 'truncate block' }}
            >
              {t('auth.forgotPassword')}
            </Button>
          </Link>
        </div>
      </form>
    </>
  );
};

export default AuthSigninPage;
