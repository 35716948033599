import { isInteger } from 'formik';

import { IPageActions } from '@chess-class/sandbox';

enum IEnumCoachesPageActions {
  'invite',
  'edit',
  'view',
}

export type ICoachesPageActions = {
  action: keyof typeof IEnumCoachesPageActions;
  actionId: string;
};

export const coachesPageActions: IPageActions<ICoachesPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumCoachesPageActions).includes(value),
    default: '',
  },
  actionId: { allowed: (value) => isInteger(value), default: 0 },
};
