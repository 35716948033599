import { type FC, useContext, useEffect, useMemo } from 'react';
import { t } from 'i18next';
import { Button, Divider, Modal, Select } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IStudentGroupUpdateRequest } from '@chess-class/sandbox';

import { CoachContext } from '~/context/CoachContext';

type AssignStudentClassModalProps = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const AssignStudentClassModal: FC<AssignStudentClassModalProps> = ({
  id = '',
  isOpen,
  onCancel,
}) => {
  const { coachMe, school } = useContext(CoachContext);

  const assignStudentClass = api.user.students.useStudentGroupUpdate();

  const coachQuery = api.user.coachToSchool.useCoachesToSchoolWithGroups({
    enabled: isOpen && school && school.isSuperCoach && !!coachMe,
    schoolIds: school ? [school.schoolId] : [],
  });

  const form = useForm<IStudentGroupUpdateRequest>({
    initialValues: api.user.students.helpers.assignStudentClassInitial,
    validate: yupResolver(api.user.students.helpers.assignStudentClassValidation),
  });

  const classQuery = api.schools.groups.useGroups({
    coachId: form.values.coachId,
    enabled: isOpen && !!school?.schoolId && !!coachMe,
    schoolIds: school ? [school.schoolId] : [],
    size: 9999,
  });

  const classList = classQuery.data?.data.content;
  const coachList = coachQuery.data?.data.content;

  const coachValues = useMemo(() => {
    if (coachList && school?.isSuperCoach) {
      return coachList.map((coach) => ({
        label: `${coach.lastName} ${coach.firstName} ${coach.middleName}`,
        value: coach.coachUserId ?? '',
      }));
    } else {
      return [
        {
          label: `${coachMe?.lastName} ${coachMe?.firstName} ${coachMe?.middleName}`,
          value: coachMe?.id ?? '',
        },
      ];
    }
  }, [coachList, coachMe]);

  useEffect(() => {
    if (coachMe && id) {
      form.setFieldValue('coachId', coachMe.id);
      form.setFieldValue('studentId', id);
    }
  }, [coachMe, id]);

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={isOpen}
      size={570}
      title={
        school?.educationFormat == 'GENERAL'
          ? t('classesPage.assignClass')
          : t('classesPage.assignGroup')
      }
    >
      <form
        className="py-6 px-8"
        onSubmit={form.onSubmit((values) => {
          assignStudentClass
            .mutateAsync({
              groupId: values.groupId,
              studentId: id,
            })
            .then(() => {
              onCancel();
            });
        })}
      >
        <div className="grid gap-4">
          <Select
            data={coachValues}
            disabled={!school?.isSuperCoach}
            label={t('coach')}
            name="coachId"
            {...form.getInputProps('coachId')}
          />
          <Select
            data={(classList ?? [])?.map((itemClass) => ({
              label: itemClass.name,
              value: itemClass.id,
            }))}
            label={
              school?.educationFormat == 'GENERAL'
                ? t('assignmentsPage.chooseClass')
                : t('assignmentsPage.chooseGroup')
            }
            name="groupId"
            {...form.getInputProps('groupId')}
          />

          <Divider className="my-6" />

          <div className="grid grid-cols-2 gap-4">
            <Button type="submit">
              {school?.educationFormat == 'GENERAL'
                ? t('classesPage.assignClass')
                : t('classesPage.assignGroup')}
            </Button>
            <Button onClick={onCancel} variant="outline">
              {t('cancel')}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
