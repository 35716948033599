import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from '@mantine/core';

import { api, IGroup, IGroupsRequest } from '@chess-class/sandbox';
import { QueryTable } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { AddIcon, EditIcon, TrashIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { GroupCreateModal } from '~mGroups/groups/components/organisms/GroupCreateModal';
import { GroupDeleteModal } from '~mGroups/groups/components/organisms/GroupDeleteModal';
import { GroupEditModal } from '~mGroups/groups/components/organisms/GroupEditModal';
import { groupsFilters } from '~mGroups/groups/consts/filters/groupsFilters';
import { getLanguageList } from '~mGroups/groups/consts/languages';
import {
  groupsPageActions,
  IGroupsPageActions,
} from '~mGroups/groups/consts/pageActions/IGroupsPageActions';

export const SchoolGroupsPage: FC = () => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);

  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IGroupsRequest,
    IGroupsPageActions
  >(groupsFilters, groupsPageActions);

  const classesQuery = api.schools.groups.useGroups({
    ...filtered,
    enabled: !!school?.isSuperCoach,
    schoolIds: school?.isSuperCoach ? [school.schoolId] : [],
  });

  const classes = classesQuery.data?.data;

  const coachesQuery = api.user.coachToSchool.useCoachesToSchoolWithGroups({
    coachIds: classes?.content.map((itemClass) => itemClass.coachId ?? ''),
    enabled: !!classes?.content.length && !!school?.isSuperCoach,
    page: 0,
    schoolIds: !!classes?.content.length && !!school?.isSuperCoach ? [school.schoolId] : [],
    size: 10,
  });

  const coaches = coachesQuery.data?.data;

  const columns = useMemo<MRT_ColumnDef<IGroup>[]>(
    () => [
      {
        accessorKey: 'name',
        header: school?.educationFormat == 'GENERAL' ? t('class') : t('group'),
      },
      {
        accessorKey: 'studentsCount',
        header: t('classesPage.studentsCount'),
      },
      {
        Cell({ cell }) {
          const coach = coaches?.content.find((coach) => coach.coachUserId == cell.getValue());

          return coach ? `${coach.lastName} ${coach.firstName} ${coach.middleName}` : '-';
        },
        accessorKey: 'coachId',
        header: school?.educationFormat == 'GENERAL' ? t('teacher') : t('coach'),
      },
      {
        accessorFn: (row) => (
          <span>{`${
            getLanguageList().find((language) => language.value === row.language)?.label ?? '-'
          }`}</span>
        ),
        accessorKey: 'language',
        header: t('classesPage.educationLanguage'),
      },
    ],
    [coaches],
  );

  return (
    <div className="flex flex-col gap-8">
      <PageMeta
        openMenuKeys={['classes']}
        selectedMenuKeys={['classes-school']}
        title={t('classes')}
      />
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="font-bold">
            {school?.educationFormat == 'GENERAL' ? t('allSchoolClasses') : t('allSchoolGroups')}
          </h1>
          <span className="text-gray-108 font-medium">
            {t('total')}{' '}
            {classesQuery.isLoading ? <LoadingOutlined /> : classes?.totalElements || 0}
          </span>
        </div>
        <Button
          disabled={!coachesQuery.isFetched}
          onClick={() => setActioned({ action: 'create' })}
          rightIcon={<AddIcon className="w-4 h-4" />}
          variant="filled"
        >
          {school?.educationFormat == 'GENERAL'
            ? t('classesPage.addClass')
            : t('classesPage.addGroup')}
        </Button>
      </div>

      <QueryTable
        actions={(row) => [
          {
            icon: <EditIcon />,
            onClick: () => {
              setActioned({ action: 'edit', actionId: row.id });
            },
            title: t('edit'),
          },
          {
            icon: <TrashIcon />,
            onClick: () => {
              setActioned({ action: 'delete', actionId: row.id });
            },
            title: `${t('delete')}`,
          },
        ]}
        columns={columns}
        empty={{
          description: t('classesPage.tableEmptyPlaceholder'),
          title: t('classesPage.noStudents'),
        }}
        query={classesQuery}
        tableFilter={{
          filtered,
          filters: groupsFilters,
          onFilter,
        }}
      />

      <GroupCreateModal isOpen={actioned.action === 'create'} onCancel={clearParams} />

      <GroupEditModal
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onCancel={clearParams}
      />

      <GroupDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onCancel={clearParams}
      />
    </div>
  );
};
