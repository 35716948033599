import { type FC, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { Button, Divider, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachProfileEditRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

type CoachEditModalProps = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const CoachEditModal: FC<CoachEditModalProps> = ({ id = '', isOpen, onCancel }) => {
  const coachEdit = api.user.coachToSchool.useCoachProfileEdit();
  const coachQuery = api.user.coachToSchool.useCoachToSchool({ id });
  const coach = coachQuery.data?.data;

  const form = useForm<ICoachProfileEditRequest>({
    initialValues: { ...api.user.coachAuth.helpers.coachInviteInitial, id: '' },
    validate: yupResolver(api.user.coachToSchool.helpers.profileEditValidation),
  });

  useEffect(() => {
    if (coach) {
      form.setValues({
        firstName: coach.firstName,
        id: coach.id,
        lastName: coach.lastName,
        middleName: coach.middleName,
        phone: coach.phone,
      });
    }
  }, [coach]);

  return (
    <Modal onClose={onCancel} opened={isOpen} size={570} title={t('classesPage.editCoach')}>
      <form
        onSubmit={form.onSubmit((values) => {
          coachEdit
            .mutateAsync(values)
            .then(() => {
              notify('success', t('messages.successCoachEdit'));
              onCancel();
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
          ReactGA.event({
            action: 'edit_teacher',
            category: 'edit_teacher',
            label: 'edit_teacher',
          });
        })}
      >
        <div className="grid gap-4 grid-cols-2">
          <TextInput label={t('lastName')} name="symbol" {...form.getInputProps('lastName')} />

          <TextInput label={t('firstName')} name="symbol" {...form.getInputProps('firstName')} />

          <TextInput label={t('middleName')} name="symbol" {...form.getInputProps('middleName')} />

          <TextInput label={t('phoneNumber')} name="symbol" {...form.getInputProps('phone')} />

          <TextInput label={t('email')} name="symbol" {...form.getInputProps('email')} disabled />
        </div>

        <Divider className="my-6" />

        <div className="grid grid-cols-2 gap-4">
          <Button loading={coachEdit.isLoading} type="submit">
            {t('save')}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
