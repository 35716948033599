import { t } from 'i18next';

import { IEnumStudentStatus, IEnumStudentStatusKeys, IFilters } from '@chess-class/sandbox';
import { CheckboxFilter, InputFilter } from '@chess-class/sandbox/components';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const studentsFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.user.student.firstName]: {
    sort: true,
  },
  sort: { queries: [{ default: '-lastActivityAt' }] },
  [modelKeys.user.student.groupName]: {
    sort: true,
  },
  [modelKeys.user.student.email]: {
    input: <InputFilter />,
    sort: true,
  },
  [modelKeys.user.student.lastActivityAt]: {
    sort: true,
  },
  [modelKeys.user.student.status]: {
    input: (
      <CheckboxFilter
        options={Object.keys(IEnumStudentStatus).map((value) => ({
          label: t(`statuses.${value as IEnumStudentStatusKeys}`),
          value,
        }))}
      />
    ),
    queries: [{ key: 'statuses' }],
    sort: true,
  },
};
