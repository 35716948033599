import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const groupsFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.school.group.name]: {
    sort: true,
  },
  [modelKeys.school.group.coachId]: {
    sort: true,
  },
  [modelKeys.school.group.language]: {
    sort: true,
  },
};
