import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';

import { IExercise } from '@chess-class/sandbox';
import { ChessboardExercise, TestAnswersBox } from '@chess-class/sandbox/components';
import { EyeIcon, EyeOffIcon } from '@chess-class/sandbox/icons';
import { openAnswerResultModal } from '@chess-class/sandbox/utils';

export default function StudioDemoExercise({
  exercise,
  onBack,
  onNext,
}: {
  exercise: IExercise;
  onBack?: () => void;
  onNext?: () => void;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const [isTestShowAnswer, setIsTestShowAnswer] = useState(false);

  useEffect(() => {
    setIsTestShowAnswer(false);
    ReactGA.event({
      action: 'task',
      category: 'task',
      label: 'task',
    });
  }, [exercise]);

  if (exercise.problem) {
    return (
      <div className="py-5 px-5 md:py-10 md:px-10 lg:px-20 flex h-full gap-8 justify-center">
        <ChessboardExercise
          exercise={exercise}
          onMove={(type) => {
            if (type == 'fail') openAnswerResultModal('fail', true);
            if (type == 'win') openAnswerResultModal('win');
          }}
          sidebar={{
            moveActions: true,
            solutionBox: { show: true, solutionButton: true, stockfish: true },
            yourMoveBox: { hintButton: true, show: true },
          }}
        >
          <div className="flex gap-6 grow-0 shrink-0 mt-8">
            {!!onBack && (
              <Button className="max-w-[50%] w-[200px]" onClick={onBack} variant="outline">
                {t('goBack')}
              </Button>
            )}
            {!!onNext && (
              <Button className="max-w-[50%] w-[200px]" onClick={onNext} variant="primary">
                {t('next')}
              </Button>
            )}
          </div>
        </ChessboardExercise>
      </div>
    );
  }

  if (exercise.test) {
    return (
      <div className="py-10 px-20 lg:w-5/6 max-w-[1100px] flex-grow mx-auto grid grid-cols-3 gap-8">
        <div className="col-span-2 flex flex-col gap-6">
          <div className="p-6 rounded-lg bg-white flex flex-col gap-6">
            {isTestShowAnswer && !!exercise?.test?.answerImageUrl ? (
              <img className="max-h-[300px] object-contain" src={exercise.test.answerImageUrl} />
            ) : (
              <img className="max-h-[300px] object-contain" src={exercise.test.imageUrl} />
            )}
            <span className="font-bold text-base text-[#202124]">
              {exercise.test.translations?.[language]?.question}
            </span>
            <TestAnswersBox
              exercise={exercise}
              onAnswer={(correct) => {
                setIsTestShowAnswer(correct);
              }}
              showAnswer={isTestShowAnswer}
            />
          </div>

          <div className="flex gap-6 mt-8">
            {!!onBack && (
              <Button className="px-20" onClick={onBack} variant="outline">
                {t('goBack')}
              </Button>
            )}
            {!!onNext && (
              <Button className="px-20" onClick={onNext} variant="primary">
                {t('next')}
              </Button>
            )}
          </div>
        </div>
        <div>
          <div className="p-6 rounded-lg flex flex-col gap-8 bg-white items-center">
            <div className="p-8 flex flex-col gap-2 items-center">
              <img className="w-44 h-44 object-contain" src="/images/illustrations/rook.png" />
              <span className="font-semibold">{t('studiosPage.chooseAnswer')}</span>
            </div>

            <Button
              className="w-full bg-primary-50 text-primary"
              leftIcon={isTestShowAnswer ? <EyeOffIcon /> : <EyeIcon />}
              onClick={() => setIsTestShowAnswer((prev) => !prev)}
              variant="primary"
            >
              {isTestShowAnswer ? t('studiosPage.hideAnswer') : t('studiosPage.showAnswer')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return <div />;
}
