import { type FC, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { t } from 'i18next';
import { Button, Divider, Modal, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, IGroupCreateRequest } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';
import { fullName } from '~/utils/fullName';

import { classLanguageList } from '~mGroups/groups/consts/languages';

type IGroupCreateModal = {
  isOpen: boolean;
  onCancel: () => void;
};

export const GroupCreateModal: FC<IGroupCreateModal> = ({ isOpen, onCancel }) => {
  const { coachMe, school } = useContext(CoachContext);
  const classCreate = api.schools.groups.useGroupCreate();

  const form = useForm<IGroupCreateRequest>({
    initialValues: api.schools.groups.helpers.groupCreateInitial,
    validate: yupResolver(api.schools.groups.helpers.groupCreateValidation),
  });

  const schoolCoachesQuery = api.user.coachToSchool.useCoachesToSchoolWithGroups({
    enabled: isOpen && !!school?.isSuperCoach,
    schoolIds: school ? [school.schoolId] : [],
    statuses: ['ACTIVE'],
  });
  const schoolCoaches = schoolCoachesQuery.data?.data.content;

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    } else {
      form.setFieldValue('schoolId', school?.schoolId || '');
      form.setFieldValue('coachId', coachMe?.id || '');
      form.setFieldValue('educationFormat', school?.educationFormat);
    }
  }, [isOpen]);

  useEffect(() => {
    form.setFieldValue('schoolId', school?.schoolId || '');
  }, [school?.schoolId]);
  useEffect(() => {
    form.setFieldValue('coachId', coachMe?.id || '');
  }, [coachMe?.id]);

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={isOpen}
      size={570}
      title={
        school?.educationFormat == 'GENERAL'
          ? t('classesPage.createClass')
          : t('classesPage.createGroup')
      }
    >
      <form
        onSubmit={form.onSubmit((values) => {
          classCreate
            .mutateAsync({
              ...values,
              schoolId: school?.schoolId,
            })
            .then(() => {
              notify(
                'success',
                school?.educationFormat == 'GENERAL'
                  ? t('messages.successClassCreate')
                  : t('messages.successGroupCreate'),
              );
              onCancel();
              ReactGA.event({
                action: 'add_group',
                category: 'add_group',
                label: 'add_group',
              });
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-4 grid-cols-2 p-5">
          <TextInput
            className={school?.educationFormat == 'PRIVATE' ? 'col-span-full' : undefined}
            label={school?.educationFormat == 'GENERAL' ? t('classesPage.classLetter') : t('group')}
            maxLength={school?.educationFormat == 'PRIVATE' ? 50 : 10}
            name="symbol"
            withAsterisk={true}
            {...form.getInputProps('symbol')}
          />
          {school?.educationFormat == 'GENERAL' && (
            <Select
              data={['1', '2', '3', '4']}
              label={t('classesPage.classNumber')}
              name="grade"
              withAsterisk
              {...form.getInputProps('grade')}
            />
          )}

          <Select
            className="col-span-2"
            data={
              school?.educationFormat == 'GENERAL'
                ? classLanguageList.filter((lan) => lan.value != 'en')
                : classLanguageList
            }
            label={t('classesPage.educationLanguage')}
            name="language"
            required
            {...form.getInputProps('language')}
          />

          <Select
            className="col-span-2"
            data={
              school?.isSuperCoach
                ? schoolCoaches?.map((coach) => ({
                    label: fullName(coach),
                    value: coach?.coachUserId || '',
                  })) || []
                : [{ label: fullName(coachMe), value: coachMe?.id || '' }]
            }
            disabled={!school?.isSuperCoach}
            label={t('coach')}
            name="coachId"
            {...form.getInputProps('coachId')}
            value={coachMe?.id}
          />
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button loading={classCreate.isLoading} type="submit">
            {t('create')}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
