import { type FC, useContext } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal } from '@mantine/core';

import { api } from '@chess-class/sandbox';
import { notify } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';

type IGroupDeleteModal = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const GroupDeleteModal: FC<IGroupDeleteModal> = ({ id = '', isOpen, onCancel }) => {
  const { t } = useTranslation();
  const classQuery = api.schools.groups.useGroup({ enabled: isOpen, id });
  const classDelete = api.schools.groups.useGroupDelete();
  const { school } = useContext(CoachContext);
  const classData = classQuery.data?.data;

  return (
    <Modal centered onClose={onCancel} opened={isOpen} size={570}>
      <div className="mb-13 max-w-[220px] mx-auto mt-5 text-center font-semibold text-gray-600 text-base">
        {school?.educationFormat == 'GENERAL'
          ? t('classesPage.deleteClassMessage', {
              grade: classData?.grade,
              symbol: classData?.symbol,
            })
          : t('classesPage.deleteGroupMessage', {
              symbol: classData?.symbol,
            })}
      </div>

      <Divider className="mb-8 -mx-6" />

      <div className="grid grid-cols-2 gap-4">
        <Button
          loading={classDelete.isLoading}
          onClick={() => {
            classDelete.mutateAsync({ id }).then(() => {
              notify(
                'error',
                school?.educationFormat == 'GENERAL'
                  ? t('messages.successSchoolDelete')
                  : t('messages.successGroupDelete'),
              );
              onCancel();
              ReactGA.event({
                action: 'delete_group',
                category: 'delete_group',
                label: 'delete_group',
              });
            });
          }}
        >
          {t('delete')}
        </Button>
        <Button onClick={onCancel} variant="outline">
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
