import { FC, useContext, useMemo } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Badge } from '@mantine/core';

import { api, ICoachesToSchoolRequest, ICoachToSchool } from '@chess-class/sandbox';
import { QueryTable } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { EditIcon, TrashIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { CoachEditModal } from '~mCoaches/components/organisms/CoachEditModal';
import { coachFilters } from '~mCoaches/consts/filters/coachFilters';
import {
  coachesPageActions,
  ICoachesPageActions,
} from '~mCoaches/consts/pageActions/ICoachesPageActions';

export type Coach = {
  address: string;
  city: string;
  name: {
    firstName: string;
    lastName: string;
  };
  state: string;
};

const CoachPage: FC = () => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);
  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    ICoachesToSchoolRequest,
    ICoachesPageActions
  >(coachFilters, coachesPageActions);

  const coachesQuery = api.user.coachToSchool.useCoachesToSchoolWithGroups({
    enabled: !!school?.schoolId,
    page: 0,
    schoolIds: new Array(school?.schoolId ?? ''),
    size: 10,
  });
  const coachDelete = api.user.coachToSchool.useCoachDelete();

  const columns = useMemo<MRT_ColumnDef<ICoachToSchool>[]>(
    () => [
      {
        accessorFn: (row) => (
          <span className="text-[#32384E] font-medium">
            {`${row.lastName} ${row.firstName} ${row.middleName}`}
          </span>
        ),
        accessorKey: 'firstName',
        header: t('fio'),
      },
      {
        accessorFn: (row) => {
          return row.groups?.map((group) => (
            <Badge className="bg-[#F2F4F8] mr-3 text-[#21272A] mb-3" key={group.id}>
              {group.name}
            </Badge>
          ));
        },
        accessorKey: 'groups',
        header: school?.educationFormat == 'GENERAL' ? t('classes') : t('groups'),
      },
      {
        accessorFn: (row) => <span className="text-[#7D829A]">{row.phone}</span>,
        accessorKey: 'phone',
        header: t('phone'),
      },
      {
        accessorFn: (row) => <span className="text-[#32384E] font-normal">{row.email}</span>,
        accessorKey: 'email',
        header: t('email'),
      },
    ],
    [],
  );
  return (
    <div>
      <PageMeta selectedMenuKeys={['coaches']} title={t('coaches')} />
      <div className="flex justify-between mb-8">
        <div className="flex flex-col">
          <h1 className="mb-0 font-bold text-[#11122C]">{t('coaches')}</h1>
          <div className="flex items-center gap-2 text-[#7D829A] font-medium">
            {t('total')} {coachesQuery.data?.data.totalElements}
          </div>
        </div>
      </div>
      <div>
        <QueryTable
          actions={(row) => [
            {
              icon: <EditIcon />,
              onClick: () => {
                setActioned({
                  action: 'edit',
                  actionId: row.id,
                });
              },
              title: t('edit'),
            },
            {
              icon: <TrashIcon />,
              onClick: () => {
                coachDelete.mutateAsync({ id: row.coachUserId }).then(() => {
                  ReactGA.event({
                    action: 'delete_teacher',
                    category: 'delete_teacher',
                    label: 'delete_teacher',
                  });
                });
              },
              title: t('delete'),
            },
          ]}
          columns={columns}
          query={coachesQuery}
          tableFilter={{
            filtered,
            filters: coachFilters,
            onFilter,
          }}
        />
      </div>
      <CoachEditModal
        id={actioned.actionId}
        isOpen={actioned.action == 'edit'}
        onCancel={clearParams}
      />
    </div>
  );
};

export default CoachPage;
