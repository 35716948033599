import { FC, useContext, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal, Textarea } from '@mantine/core';
import { useForm } from '@mantine/form';

import { api, IEnumReviewContentType, ReviewContentTypeEnumKeys } from '@chess-class/sandbox';
import { IllustrationStarWhiteIcon, IllustrationStarYellowIcon } from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';

type ReviewContentProps = {
  contentId: string;
  contentType: ReviewContentTypeEnumKeys;
  onClose?: () => void;
  open?: boolean;
  title?: string;
};

export const ReviewContent: FC<ReviewContentProps> = ({
  contentId,
  contentType,
  onClose,
  open,
  title,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { coachMe, school } = useContext(CoachContext);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [rate, setRate] = useState(0);
  const [step, setStep] = useState(1);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  const form = useForm({});

  const postReview = api.reviews.reviews.useMutateReview();

  const reviewsQuery = api.reviews.reviews.useQueryStudentCoachReview({
    contentId: contentId,
    contentType: 'CHAPTER',
    educationFormat: school?.educationFormat ?? 'PRIVATE',
    userId: coachMe?.id,
  });

  const reviews = reviewsQuery.data?.data;

  return (
    <>
      {reviews &&
        !reviews.find((r) => r.contentId == contentId) &&
        school?.educationFormat == 'PRIVATE' &&
        contentType != IEnumReviewContentType.VIDEO && (
          <Button className="flex items-center" onClick={() => setIsOpenModal(true)} variant="text">
            <IllustrationStarYellowIcon className="mr-1" />
            <span className="text-[#B9B9B9] ml-1">{t('review.review')}</span>
          </Button>
        )}
      <Modal
        centered
        onClose={onClose ? onClose : () => setIsOpenModal(false)}
        opened={open ?? isOpenModal}
        size={570}
      >
        <form
          onSubmit={form.onSubmit((values) => {
            const actionData = {
              contentId: contentId,
              contentLanguage: language,
              contentType: contentType,
              educationFormat: 'PRIVATE',
              schoolId: school?.schoolId ?? '',
              schoolName: school?.schoolName ?? '',
              text: values.text as string,
              userFullName: `${coachMe?.lastName} ${coachMe?.firstName} ${coachMe?.middleName}`,
              userId: coachMe?.id ?? '',
              userRole: 'COACH',
              value: rate,
            };
            postReview
              .mutateAsync(actionData)
              .then((data) => {
                if (data.status == 201 || data.status == 200) {
                  notify('success', t('messages.successReviewPost'));
                  ReactGA.event({
                    action: 'review_sent',
                    category: 'review_sent',
                    label: 'review_sent',
                  });
                }
                setIsOpenModal(false);
              })
              .catch(() => {
                notify('error', t('messages.errorPostReview'));
              });
          })}
        >
          <div className="mb-13 mx-auto mt-5 text-center font-semibold text-gray-600 text-base">
            {step == 1 && (
              <>
                <span className="text-[24px] font-bold">
                  {title ?? ' Пожалуйста оцените контент'}
                </span>{' '}
                <br />
                {contentType != IEnumReviewContentType.VIDEO && (
                  <p className="text-[16px]">{t('review.thisWillHelpUpToImprove')}</p>
                )}
                <div className="flex flex-row items-center justify-center h-[130px] px-6">
                  {new Array(5).fill(null).map((element, index) => (
                    <div
                      className="w-[62px] h-[62px] mr-1 ml-1 cursor-pointer"
                      key={index + 1}
                      onClick={() => setRate(index + 1)}
                      onMouseEnter={() => handleMouseEnter(index + 1)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {(hoveredIndex !== null && index + 1 <= hoveredIndex) || index + 1 <= rate ? (
                        <IllustrationStarYellowIcon className="w-[64px] h-[64px]" />
                      ) : (
                        <IllustrationStarWhiteIcon className="w-[64px] h-[64px]" />
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}
            {step == 2 && (
              <>
                {rate <= 2 ? (
                  <>
                    <span className="text-[24px] font-bold">{t('review.whatYouDidntLike')}</span>
                    <br />{' '}
                    {contentType != IEnumReviewContentType.VIDEO && (
                      <p className="text-[16px]">{t('review.thisWillHelpUpToImprove')}</p>
                    )}
                  </>
                ) : (
                  <span className="text-[24px] font-bold">{t('review.whatDidYouLike')}</span>
                )}
                <div className="p-6">
                  <Textarea
                    {...form.getInputProps('text')}
                    autosize
                    className="text-[14px] font-normal"
                    minRows={5}
                    placeholder={t('review.tellUsMoreDetails')}
                    required={rate <= 2}
                  />
                </div>
              </>
            )}
          </div>

          <Divider className="mb-8 -mx-6" />
          <div className="flex items-center justify-center ">
            {step == 1 && (
              <Button className="w-[50%]" onClick={() => setStep(step + 1)} variant="primary">
                {t('next')}
              </Button>
            )}
            {step == 2 && (
              <div className="grid grid-cols-2 gap-6">
                <Button className="w-full" onClick={() => setStep(1)} variant="outline">
                  {t('back')}
                </Button>
                <Button
                  className="w-full"
                  loading={postReview.isLoading}
                  type="submit"
                  variant="primary"
                >
                  {t('auth.send')}
                </Button>
              </div>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};
