import { IPageActions } from '@chess-class/sandbox';

enum IEnumHomeworksPageActions {
  'create',
  'delete',
}

export type IHomeworksPageActions = {
  action: keyof typeof IEnumHomeworksPageActions;
  actionChapterId?: string;
  actionClassId?: string;
  actionId: string;
};

export const homeworksPageActions: IPageActions<IHomeworksPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumHomeworksPageActions).includes(value),
    default: '',
  },
  actionChapterId: { default: '' },
  actionClassId: { default: '' },
  actionId: { default: '' },
};
