import { FC, useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Select, Tabs } from '@mantine/core';

import { api, IStudentsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { AddIcon, DownloadIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import { getTimeSort, TimeSortEnum } from '~mGroups/groups/consts/timeSort';
import StudentsTable from '~mGroups/students/components/organisms/StudentsTable';
import { studentsFilters } from '~mGroups/students/consts/filters/studentsFilters';
import {
  IStudentsPageActions,
  studentsPageActions,
} from '~mGroups/students/consts/pageActions/IStudentsPageActions';
import QuizGradeTable from '~mHomeworks/components/organisms/QuizGradeTable';

enum IEnumClassDetailTab {
  'classQuiz' = 'classQuiz',
  'homework' = 'homework',
  'students' = 'students',
}

export const GroupDetailsPage: FC = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams();
  const { school } = useContext(CoachContext);
  const { actioned, filtered, onFilter, setFiltered, setPartialActioned } = useUrlFilteredActioned<
    IStudentsRequest,
    IStudentsPageActions
  >(studentsFilters, studentsPageActions);

  const [gradesOpened, setGradesOpened] = useState(false);
  const [tab, setTab] = useState<keyof typeof IEnumClassDetailTab | null | string>('students');
  const [totalStudents, setTotalStudents] = useState<number | undefined>();
  const [timeSort, setTimeSort] = useState<keyof typeof TimeSortEnum>(TimeSortEnum.NEWEST);

  const classQuery = api.schools.groups.useGroup({
    enabled: !!id,
    id: id ?? '',
  });
  const classData = classQuery.data?.data;

  const studentExportPdfQuery = api.user.students.useStudentExportPdf();

  useEffect(() => {
    if (tab == 'classQuiz' || tab == 'homework') {
      setGradesOpened(true);
    }
  }, [tab]);

  return (
    <div className="flex flex-col gap-4">
      <PageMeta
        goBack={{
          i18Title:
            school?.educationFormat == 'GENERAL'
              ? 'classesPage.backToClasses'
              : 'classesPage.backToGroups',
          url: '/classes',
        }}
        openMenuKeys={['classes']}
        selectedMenuKeys={['classes-my']}
        title={school?.educationFormat == 'GENERAL' ? t('classes') : t('groups')}
      />

      <div className="flex justify-between items-center">
        <div className="flex flex-col gap-2">
          <h1 className="mb-0 font-bold">
            {school?.educationFormat == 'GENERAL' ? t('class') : t('group')} {classData?.name}
          </h1>
          <span className="text-gray-108 font-medium">
            {t('total')} {totalStudents === undefined ? <LoadingOutlined /> : totalStudents || 0}
          </span>
        </div>

        {tab == 'students' && (
          <Button
            disabled={!classData}
            onClick={() => {
              setPartialActioned({
                action: 'createStudent',
              });
            }}
            rightIcon={<AddIcon className="w-4 h-4" />}
          >
            {t('classesPage.addStudent')}
          </Button>
        )}
      </div>

      <Tabs
        classNames={{
          root: 'flex flex-col gap-4',
          tab: 'h-10.5',
          tabsList: 'justify-between gap-4',
        }}
        onTabChange={setTab}
        value={tab}
      >
        <Tabs.List>
          <div className="flex gap-4">
            <Tabs.Tab value="students">{t('students')}</Tabs.Tab>
            <Tabs.Tab
              onClick={() =>
                ReactGA.event({
                  action: 'homeworks_results',
                  category: 'homeworks_results',
                  label: 'homeworks_results',
                })
              }
              value="homework"
            >
              {t('homeworks')}
            </Tabs.Tab>
            <Tabs.Tab
              onClick={() =>
                ReactGA.event({
                  action: 'tests_results',
                  category: 'tests_results',
                  label: 'tests_results',
                })
              }
              value="classQuiz"
            >
              {t('assignment')}
            </Tabs.Tab>
          </div>

          {tab == 'students' ? (
            <Button
              leftIcon={<DownloadIcon className="w-4 h-4" />}
              loading={studentExportPdfQuery.isLoading}
              onClick={() => {
                studentExportPdfQuery.mutateAsync({ groupId: id }).then((response) => {
                  if (response.data) {
                    saveAs(response.data, `${t('class')} ${classData?.name}.pdf`);
                  }
                });
              }}
              variant="outline"
            >
              {t('classesPage.exportPdf')}
            </Button>
          ) : (
            <Select
              classNames={{ input: 'h-10.5 bg-white' }}
              data={getTimeSort}
              onChange={(value: keyof typeof TimeSortEnum) => value && setTimeSort(value)}
              value={timeSort}
            />
          )}
        </Tabs.List>

        <Tabs.Panel value="students">
          <StudentsTable
            actioned={actioned as IStudentsPageActions}
            classData={classData}
            filtered={filtered}
            groupIds={[id]}
            onFilter={onFilter}
            schoolId={school?.schoolId}
            setFiltered={setFiltered}
            setPartialActioned={setPartialActioned}
            setTotalStudents={(_, assigned) => setTotalStudents(assigned)}
          />
        </Tabs.Panel>

        <Tabs.Panel value="homework">
          <QuizGradeTable
            enabled={gradesOpened}
            gradeType={classData?.school?.gradeType}
            groupId={id}
            isHomework
            timeSort={timeSort}
          />
        </Tabs.Panel>

        <Tabs.Panel value="classQuiz">
          <QuizGradeTable
            enabled={gradesOpened}
            gradeType={classData?.school?.gradeType}
            groupId={id}
            timeSort={timeSort}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
