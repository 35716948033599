import { FC, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { Skeleton, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { api, IExercisesRequest } from '@chess-class/sandbox';
import { NotFoundResult, QueryTableStatic } from '@chess-class/sandbox/components';
import { paginationBaseFilters } from '@chess-class/sandbox/consts';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { EditIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';
import { groupBy } from '~/utils/groupBy';

import { ExerciseProblemModal } from '~mClassQuizzes/components/organisms/ExerciseProblemModal';
import { ExerciseTestModal } from '~mClassQuizzes/components/organisms/ExerciseTestModal';
import {
  classQuizTestPageActions,
  IExercisePageActions,
} from '~mClassQuizzes/consts/pageActions/IExercisePageActions';
import HomeworkCardTable from '~mHomeworks/components/molecules/HomeworkCardTable';
import HomeworkBox from '~mHomeworks/components/organisms/HomeworkBox';
import { HomeworkEditModal } from '~mHomeworks/components/organisms/HomeworkEditModal';
import { homeworkSessionsColumns } from '~mHomeworks/consts/columns/homeworkSessionsColumns';

export const HomeworkDetailsPage: FC = () => {
  const [opened, { close, open }] = useDisclosure(false);
  const [studentId, setStudentId] = useState('');
  const { school } = useContext(CoachContext);

  const { id } = useParams();
  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IExercisesRequest,
    IExercisePageActions
  >(paginationBaseFilters, classQuizTestPageActions);

  const homeworkQuery = api.education.homework.useCoachHomework({ id });
  const homework = homeworkQuery.data?.data;

  const groupedHomeworks = useMemo(() => groupBy(homework?.exercises, 'chapterId'), [homework]);

  const exerciseIndex =
    homework?.exercises.findIndex((ex) => ex.exerciseId === actioned.actionId) ?? 0;

  return (
    <>
      <PageMeta
        goBack={{ i18Title: 'back', url: '/homework' }}
        openMenuKeys={['homework']}
        selectedMenuKeys={['homework']}
        title={t('homework')}
      />
      <div className="flex justify-between mb-8">
        <div className="flex flex-col">
          <h1 className="font-bold">
            {t('homework')} {homework ? `#${homework.orderByGroup}` : ''}
          </h1>
        </div>
      </div>
      {homeworkQuery.isLoading || !homeworkQuery.isFetched ? (
        <div className="flex flex-col gap-8">
          <Skeleton className="w-full h-40" />
          <Skeleton className="w-full h-40" />
        </div>
      ) : !homework ? (
        <NotFoundResult
          subTitle={t('notFoundResult.goBackToHomeworks')}
          title={t('notFoundResult.noHomework')}
        />
      ) : (
        <>
          {homeworkQuery.isSuccess && <HomeworkBox homework={homework} />}

          <Tabs defaultValue="students" unstyled>
            <Tabs.List className="mt-8 mb-5 bg-transparent">
              <Tabs.Tab className="mr-4" value="students">
                {t('students')}
              </Tabs.Tab>
              <Tabs.Tab value="problems">{t('problems')}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="students">
              {homework && (
                <QueryTableStatic
                  actions={(row) => [
                    {
                      icon: <EditIcon />,
                      onClick: () => {
                        setStudentId(row.id);
                        open();
                      },
                      title: t('homeworksPage.editDueDate'),
                    },
                  ]}
                  columns={homeworkSessionsColumns(school?.educationFormat)}
                  data={homework.sessions}
                  empty={{ title: t('classesPage.noStudents') }}
                  status={(row) => row}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel value="problems">
              {!homework ? (
                <Skeleton className="w-full h-56" />
              ) : Object.keys(groupedHomeworks).length ? (
                Object.values(groupedHomeworks).map((homework, index) => (
                  <HomeworkCardTable
                    homework={homework}
                    key={index}
                    setPartialActioned={setPartialActioned}
                  />
                ))
              ) : (
                <NotFoundResult subTitle="" title={t('notFoundResult.noHomeworkExercises')} />
              )}
            </Tabs.Panel>
          </Tabs>

          {homework && (
            <>
              <ExerciseTestModal
                exerciseId={actioned.actionId ?? ''}
                isBackButtonDisabled={exerciseIndex == 0}
                isHomeWork
                isNextButtonDisabled={exerciseIndex >= homework?.exercises.length - 1}
                isOpen={actioned.action === 'view' && actioned.actionType === 'test'}
                onBackButtonClick={() =>
                  setPartialActioned({
                    action: 'view',
                    actionId: `${homework.exercises[exerciseIndex - 1].exerciseId}`,
                    actionType: 'test',
                  })
                }
                onCancel={clearParams}
                onNextButtonClick={() => {
                  setPartialActioned({
                    action: 'view',
                    actionId: `${homework.exercises[exerciseIndex + 1].exerciseId}`,
                    actionType: 'test',
                  });
                }}
              />

              <ExerciseProblemModal
                exerciseId={actioned.actionId ?? ''}
                isHomeWork
                isOpen={actioned.action === 'view' && actioned.actionType === 'exercise'}
                onCancel={clearParams}
              />

              <HomeworkEditModal
                homeworkId={homework.id}
                id={studentId}
                isOpen={opened}
                onCancel={close}
                refetch={homeworkQuery.refetch}
                sessions={homework?.sessions}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
