import { type FC, useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import moment from 'moment';
import {
  Accordion,
  Button,
  Checkbox,
  clsx,
  Divider,
  Modal,
  ScrollArea,
  Select,
  Skeleton,
  Switch,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachClassQuizCreateRequest } from '@chess-class/sandbox';
import { LockIcon } from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

import { useCoachContext } from '~/context/CoachContext';
import useClassGuidelineChapters from '~/hooks/useClassGuidelineChapters';

type IClassQuizCreateModal = {
  groupId?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const ClassQuizCreateModal: FC<IClassQuizCreateModal> = ({ groupId, isOpen, onCancel }) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { coachMe, myClasses, school } = useCoachContext();
  const classQuizCreate = api.education.classQuiz.useCoachClassQuizCreate();
  const randomExercises = api.content.exercises.useExercisesRandom();
  const [selectAllStudents, setSelectAllStudents] = useState<boolean>(true);

  const form = useForm<ICoachClassQuizCreateRequest>({
    initialValues: api.education.classQuiz.helpers.coachClassQuizCreateInitial,
    validate: yupResolver(api.education.classQuiz.helpers.coachClassQuizCreateValidation),
  });

  const { classGuidelineChapters, isLoading } = useClassGuidelineChapters({
    groupId: form.values.classId,
  });

  //REMOVE AFTER DEMO
  const demoItems = [1, 2, 3, 19, 20, 21, 37, 38, 39, 61, 62, 63];

  const sortedClassGuidelineChapters =
    school?.educationFormat == 'GENERAL'
      ? classGuidelineChapters
      : coachMe?.email == 'demochessclass+2@gmail.com' ||
          coachMe?.email == 'demochessclass+5@gmail.com'
        ? classGuidelineChapters
            ?.sort((a, b) => a.numId - b.numId)
            .filter((item) => demoItems.includes(item.numId))
        : classGuidelineChapters?.sort((a, b) => a.numId - b.numId);

  const studentsQuery = api.user.students.useStudents({
    assigned: true,
    enabled: !!school?.schoolId && !!form.values.classId,
    groupIds: [form.values.classId],
    page: 0,
    schoolId: school?.schoolId,
    size: 100,
  });

  const students = studentsQuery.data?.data.content || [];
  const activeStudents = useMemo(
    () => students?.filter((s) => s.status == 'ACTIVE'),
    [studentsQuery.data?.data.content],
  );

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    } else {
      form.setFieldValue('classId', groupId || '');
    }
  }, [isOpen]);

  useEffect(
    () =>
      form.setFieldValue(
        'studentIds',
        activeStudents?.map((s) => s.id),
      ),
    [activeStudents],
  );

  return (
    <Modal
      centered
      closeButtonProps={{
        iconSize: 24,
      }}
      onClose={onCancel}
      opened={isOpen}
      scrollAreaComponent={ScrollArea.Autosize}
      size={770}
      title={t('assignmentsPage.addAssignment')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          randomExercises
            .mutateAsync({
              chapterId: form.values.chapterIds,
              type: 'TEST',
            })
            .then((res) => {
              const exercisesList = res.data.map((item) => {
                return {
                  chapterId: item?.chapter?.id ?? '',
                  id: item.id,
                  numId: item.numId || 0,
                  type: item.type,
                };
              });
              if (exercisesList.length) {
                classQuizCreate
                  .mutateAsync({
                    ...values,
                    exercises: exercisesList ?? [],
                    finishAt: moment(values.finishAt)
                      .utcOffset('GMT+00:00')
                      .format('yyyy-MM-DD HH:mm:ss'),
                    startAt: moment(values.startAt)
                      .utcOffset('GMT+00:00')
                      .format('yyyy-MM-DD HH:mm:ss'),
                  })
                  .then(() => {
                    notify('success', t('messages.successAssignmentCreate'));
                    onCancel();
                    ReactGA.event({
                      action: 'test_created',
                      category: 'test_created',
                      label: 'test_created',
                    });
                  });
              } else {
                notify('warning', t('messages.warningAssignmentConsistOf'), '', 5000);
              }
            });
        })}
      >
        <div className="p-5">
          <Select
            data={
              myClasses?.map((c) => ({
                label: school?.educationFormat == 'GENERAL' ? `${c.grade} ${c.symbol}` : c.symbol,
                value: c.id,
              })) || []
            }
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            name="classId"
            required
            {...form.getInputProps('classId')}
          />
          <Switch
            checked={selectAllStudents}
            className="mr-10 mt-6"
            label={`${t('homeworksPage.toAllStudents')} (${activeStudents?.length})
            `}
            labelPosition="right"
            onChange={() => {
              setSelectAllStudents(!selectAllStudents);
            }}
          />
          {!selectAllStudents && (
            <Accordion className="px-0" defaultValue="exercise-answer">
              <Accordion.Item className="!border-none" value="exercise-answer">
                <Accordion.Control className="mb-0 h-[52px]">
                  <p className="text-center text-base mb-0">
                    {t('students')}{' '}
                    <span className="text-gray-111">({form.values.studentIds?.length ?? 0}) </span>
                  </p>
                </Accordion.Control>
                <Accordion.Panel>
                  <ScrollArea
                    classNames={{
                      viewport: form.errors.studentIds
                        ? 'border border-solid border-red min-h-[192px] max-h-[256px]'
                        : 'border border-solid border-grey min-h-[192px] max-h-[256px]',
                    }}
                  >
                    {isLoading ? (
                      <Skeleton className="w-full h-48" />
                    ) : activeStudents && activeStudents?.length ? (
                      <>
                        <Checkbox
                          checked={form.values.studentIds && form.values.studentIds?.length > 0}
                          indeterminate={form.values.studentIds?.length == activeStudents.length}
                          label={
                            <div className="flex gap-3">
                              <span>{t('homeworksPage.toAllStudents')}</span>
                            </div>
                          }
                          onChange={() =>
                            form.values.studentIds?.length == activeStudents.length
                              ? form.setFieldValue('studentIds', [])
                              : form.setFieldValue(
                                  'studentIds',
                                  activeStudents.map((s) => s.id),
                                )
                          }
                        />
                        <Checkbox.Group
                          withAsterisk
                          {...form.getInputProps('studentIds', { type: 'checkbox' })}
                          value={form.values.studentIds}
                        >
                          {activeStudents?.map((student) => {
                            return (
                              <Checkbox
                                checked={true}
                                key={student.id}
                                label={
                                  <div className="flex gap-3">
                                    <span>{`${student.firstName} ${student.lastName}`}</span>
                                  </div>
                                }
                                value={student.id}
                              />
                            );
                          })}
                        </Checkbox.Group>
                      </>
                    ) : (
                      <div className="flex items-center flex-col justify-center bg-white h-full py-8">
                        <img
                          className="w-28 h-40 object-contain"
                          src="/images/illustrations/pawn-search.png"
                        />
                        <span className="mt-4 text-lg text-gray-500 font-medium">
                          {school?.educationFormat == 'GENERAL'
                            ? form.values.classId
                              ? t('homeworksPage.noTopicsForClass')
                              : t('homeworksPage.chooseClass')
                            : form.values.classId
                              ? t('homeworksPage.noTopicsForGroup')
                              : t('homeworksPage.chooseGroup')}
                        </span>
                      </div>
                    )}
                  </ScrollArea>
                  <Typography className="text-red-600">{form.errors.studentIds}</Typography>
                </Accordion.Panel>
              </Accordion.Item>
            </Accordion>
          )}
          <p className="text-center text-gray-500 mt-6 mb-4">
            {' '}
            {t('assignmentsPage.chooseTopics')}
          </p>
          <ScrollArea
            classNames={{
              viewport: form.errors.chapterIds
                ? 'border border-solid border-red min-h-[192px] max-h-[256px]'
                : 'border border-solid border-grey min-h-[192px] max-h-[256px]',
            }}
          >
            {isLoading ? (
              <Skeleton className="w-full h-48" />
            ) : classGuidelineChapters && classGuidelineChapters?.length != 0 ? (
              <Checkbox.Group
                withAsterisk
                {...form.getInputProps('chapterIds', { type: 'checkbox' })}
              >
                {sortedClassGuidelineChapters?.map((chapter) => {
                  const isAvailable = chapter.status == 'GIVEN';
                  // const isSelected = form.values.chapterIds.includes(chapter.rootChapterId);
                  return (
                    <Checkbox
                      disabled={!isAvailable}
                      key={chapter.id}
                      label={
                        <div className="flex gap-3">
                          <span className={clsx(isAvailable ? 'text-primary' : 'text-gray-200')}>
                            {school?.educationFormat == 'GENERAL'
                              ? `${chapter.priority < 10 ? '0' : ''}${chapter.priority}`
                              : chapter.numId}
                          </span>
                          {chapter.translations?.[language]?.title || '-'}

                          {!isAvailable && (
                            <LockIcon className="my-auto h-4 w-4 mr-5 text-gray-112/40 ml-auto" />
                          )}

                          {isAvailable && (
                            // && !isSelected
                            <span className="my-auto mr-5 text-gray-112 whitespace-nowrap ml-auto">
                              {t('passed')}
                            </span>
                          )}
                        </div>
                      }
                      max={5}
                      maxLength={5}
                      value={chapter.rootChapterId}
                    />
                  );
                })}
              </Checkbox.Group>
            ) : (
              <div className="flex items-center flex-col justify-center bg-white h-full py-8">
                <img
                  className="w-28 h-40 object-contain"
                  src="/images/illustrations/pawn-search.png"
                />
                <span className="mt-4 text-gray-500 font-medium">
                  {school?.educationFormat == 'GENERAL'
                    ? t('assignmentsPage.chooseClass')
                    : t('assignmentsPage.chooseGroup')}
                </span>
              </div>
            )}
          </ScrollArea>
          <Typography className="text-red-600">{form.errors.chapterIds}</Typography>
          <div className="grid grid-cols-2 gap-4 justify-center mt-6">
            <DateTimePicker
              label={t('startDate')}
              locale={language}
              minDate={new Date()}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder="01.01.2023, 12:00"
              valueFormat="DD.MM.YYYY, HH:mm"
              {...form.getInputProps('startAt')}
            />
            <DateTimePicker
              label={t('finishDate')}
              locale={language}
              minDate={new Date()}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder="01.01.2023, 12:01"
              valueFormat="DD.MM.YYYY, HH:mm"
              {...form.getInputProps('finishAt')}
            />
          </div>
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="flex justify-center gap-4 pt-0">
          <Button className="w-[220px]" onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
          <Button
            className="w-[220px]"
            disabled={!form.values.classId}
            loading={classQuizCreate.isLoading || randomExercises.isLoading}
            type="submit"
          >
            {t('save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
