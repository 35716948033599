import { type FC, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { IMaskInput } from 'react-imask';
import { t } from 'i18next';
import moment from 'moment';
import { Button, Divider, InputBase, Modal, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconCalendar } from '@tabler/icons-react';

import { api, IStudentUpdateRequest } from '@chess-class/sandbox';
import { notify, phoneMaskSanitizer } from '@chess-class/sandbox/utils';

import { CoachContext } from '~/context/CoachContext';

import { getStudentGenders } from '~mGroups/groups/consts/genders';

type EditStudentModalProps = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const EditStudentModal: FC<EditStudentModalProps> = ({ id = '', isOpen, onCancel }) => {
  const studentUpdate = api.user.students.useStudentUpdate();
  const studentPasswordReset = api.user.students.useStudentResetPassword();
  const { school } = useContext(CoachContext);
  const studentQuery = api.user.students.useStudent({ id });
  const student = studentQuery.data?.data;

  const form = useForm<IStudentUpdateRequest>({
    initialValues: api.user.students.helpers.studentUpdateInitial,
    validate: yupResolver(api.user.students.helpers.studentUpdateValidation),
  });

  useEffect(() => {
    if (student) {
      if (student.birthDate) {
        const date = new Date(student.birthDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const formattedBirthDay = `${day}.${month}.${year}`;
        form.setValues({ ...student, birthDate: formattedBirthDay });
      } else {
        form.setValues({
          ...student,
          birthDate: null,
        });
      }
    }
  }, [student]);

  return (
    <Modal
      centered
      onClose={onCancel}
      opened={isOpen}
      size={776}
      title={t('classesPage.editStudent')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          studentUpdate
            .mutateAsync({
              ...values,
              birthDate: moment(values.birthDate, 'DD.MM.YYYY').format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
              ),
              id,
              phone: phoneMaskSanitizer(values.phone ?? ''),
            })
            .then(() => {
              notify('success', t('messages.successStudentEdit'));
              onCancel();
              ReactGA.event({
                action: 'edit_student',
                category: 'edit_student',
                label: 'edit_student',
              });
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-6 grid-cols-6 p-5">
          <TextInput
            className="col-span-3"
            label={t('lastName')}
            name="lastName"
            withAsterisk
            {...form.getInputProps('lastName')}
          />
          <TextInput
            className="col-span-3"
            label={t('firstName')}
            name="firstName"
            withAsterisk
            {...form.getInputProps('firstName')}
          />
          <TextInput
            className="col-span-3"
            label={t('middleName')}
            name="middleName"
            {...form.getInputProps('middleName')}
          />
          <TextInput
            className="col-span-3"
            label={t('iin')}
            maxLength={12}
            name="iin"
            {...form.getInputProps('iin')}
          />
          <InputBase
            className="col-span-2"
            component={IMaskInput}
            icon={<IconCalendar />}
            label={t('birthDate')}
            mask="00.00.0000"
            name="birthDate"
            placeholder="18.04.1999"
            {...form.getInputProps('birthDate')}
          />
          <Select
            className="col-span-2"
            data={getStudentGenders()}
            label={t('gender.title')}
            name="gender"
            withAsterisk
            {...form.getInputProps('gender')}
          />
          <TextInput
            className="col-span-2"
            disabled
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            value={student?.groupName}
          />
          <TextInput
            className="col-span-3"
            label={t('email')}
            name="email"
            {...form.getInputProps('email')}
            disabled
          />
          <InputBase
            className="col-span-3"
            component={IMaskInput}
            label={t('phone')}
            mask="+7 000 000 00 00"
            name="phone"
            {...form.getInputProps('phone')}
          />

          <Button
            className="col-span-2"
            disabled={studentPasswordReset.isLoading}
            loading={studentPasswordReset.isLoading}
            onClick={() => studentPasswordReset.mutateAsync({ id })}
            variant="default"
          >
            {t('classesPage.resetPassword')}
          </Button>
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button loading={studentUpdate.isLoading} type="submit">
            {t('save')}
          </Button>
          <Button onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
