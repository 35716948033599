import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from '@mantine/core';

import { IStudentsRequest } from '@chess-class/sandbox';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import { AddIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

import StudentsTable from '~mGroups/students/components/organisms/StudentsTable';
import { studentsFilters } from '~mGroups/students/consts/filters/studentsFilters';
import {
  IStudentsPageActions,
  studentsPageActions,
} from '~mGroups/students/consts/pageActions/IStudentsPageActions';

export const SchoolStudentsPage: FC = () => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);

  const { actioned, filtered, onFilter, setFiltered, setPartialActioned } = useUrlFilteredActioned<
    IStudentsRequest,
    IStudentsPageActions
  >(studentsFilters, studentsPageActions);
  const [totalStudents, setTotalStudents] = useState<number | undefined>();

  return (
    <div className="flex flex-col gap-8">
      <PageMeta
        openMenuKeys={['classes']}
        selectedMenuKeys={['classes-students']}
        title={t('classes')}
      />
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="font-bold">{t('allSchoolStudents')}</h1>
          <span className="text-gray-108 font-medium">
            {t('total')} {totalStudents === undefined ? <LoadingOutlined /> : totalStudents || 0}
          </span>
        </div>
        <Button
          onClick={() => setPartialActioned({ action: 'createStudent' })}
          rightIcon={<AddIcon className="w-4 h-4" />}
          variant="filled"
        >
          {t('classesPage.addStudent')}
        </Button>
      </div>

      <StudentsTable
        actioned={actioned as IStudentsPageActions}
        filtered={filtered}
        groupIds={[]}
        onFilter={onFilter}
        schoolId={school?.schoolId}
        setFiltered={setFiltered}
        setPartialActioned={setPartialActioned}
        setTotalStudents={setTotalStudents}
        showTabs
      />
    </div>
  );
};
