import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Modal, Skeleton, Switch, Title } from '@mantine/core';

import {
  api,
  IEnumClassQuizStudentExerciseStatus,
  IEnumClassQuizStudentExerciseStatusKeys,
  IEnumReviewContentType,
  IExercise,
  IStudentSmall,
} from '@chess-class/sandbox';
import { ChessboardExercise, ExerciseCustomProblem } from '@chess-class/sandbox/components';

import { ReviewContent } from '~/components/organisms/ReviewContent';

import {
  ExerciseAnswersAccordion,
  ExerciseAnswersAccordionProps,
} from '~mClassQuizzes/components/molecules/ExerciseAnswersAccordion';

type IExerciseProblemModal = {
  exerciseId: string;
  isHomeWork?: boolean;
  isOpen: boolean;
  isView?: boolean;
  onCancel: () => void;
};

type IExerciseAccordion = {
  status?: ExerciseAnswersAccordionProps['status'];
  students: ExerciseAnswersAccordionProps['students'];
  title: string;
  totalStudents: number;
};

export const ExerciseProblemModal: FC<IExerciseProblemModal> = ({
  exerciseId,
  isHomeWork = false,
  isOpen,
  isView = false,
  onCancel,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [showAnswer, setShowAnswer] = useState(false);

  const exerciseQuery = api.content.exercises.useExercise({ id: exerciseId });
  const exercise = exerciseQuery.data?.data as IExercise;

  const classQuizExerciseAnswersQuery = api.education.classQuiz.useCoachClassQuizExerciseAnswers({
    enabled: !isView && !!exerciseId && !isHomeWork,
    exerciseId: exerciseId,
    id: id ?? '',
  });
  const classQuizExerciseAnswers = classQuizExerciseAnswersQuery.data?.data ?? [];

  const homeworkExerciseAnswersQuery = api.education.homework.useCoachHomeworkExerciseAnswers({
    enabled: !isView && !!exerciseId && isHomeWork,
    exerciseId: exerciseId,
    id: id ?? '',
  });

  const homeworkExerciseAnswers = homeworkExerciseAnswersQuery.data?.data ?? [];

  const exercises = isHomeWork ? homeworkExerciseAnswers : classQuizExerciseAnswers;

  const accordionsMemo = useMemo<IExerciseAccordion[]>(() => {
    return Object.keys(IEnumClassQuizStudentExerciseStatus).map(
      (status: IEnumClassQuizStudentExerciseStatusKeys | string) => ({
        status: status == 'SOLVED' ? 'good' : status == 'FAILED' ? 'fail' : 'unsolved',
        students: exercises
          .filter((ex) => ex.status == status)
          .map((ex) => ex.student) as IStudentSmall[],
        title: t(`exerciseStatus.${status as IEnumClassQuizStudentExerciseStatusKeys}`),
        totalStudents: exercises.length || 0,
      }),
    );
  }, [exercise, exercises, showAnswer]);

  useEffect(() => {
    setShowAnswer(false);
  }, [exercise]);

  return (
    <>
      <Modal
        classNames={{
          body: 'p-0 m-0 bg-gray-116 rounded-b-xl p-10',
          content:
            'p-4 md:p-8 lg:px-[5%] xl:px-[10%] bg-transparent shadow-none rounded-xl max-h-full',
          header: 'p-4 md:p-6 gap-4 md:gap-8',
          inner: 'p-0 m-0',
          root: 'p-0 m-0',
        }}
        onClose={onCancel}
        opened={isOpen}
        size="100%"
        title={
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex items-center">
              <img
                alt="Test"
                className="w-12 h-12 mr-6 object-contain"
                src="/images/illustrations/problem-img.png"
              />{' '}
              <Title size={24}>
                {t('exercise')} #{exercise?.numId}
              </Title>
            </div>
            <div className="flex flex-row items-center">
              {exercise?.type !== 'CUSTOM_PROBLEM' && (
                <Switch
                  checked={showAnswer}
                  className="mr-4"
                  label={t('studiosPage.showAnswer')}
                  labelPosition="left"
                  onChange={() => {
                    if (showAnswer) {
                      lichess.board?.hideSolution();
                      setShowAnswer(false);
                    } else {
                      lichess.board?.showSolution();
                      setShowAnswer(true);
                    }
                  }}
                />
              )}
              <ReviewContent
                contentId={exercise?.id ?? ''}
                contentType={IEnumReviewContentType.PROBLEM}
                title={t('review.reviewExercise')}
              />
            </div>
          </div>
        }
      >
        {homeworkExerciseAnswersQuery.isFetching ||
          classQuizExerciseAnswersQuery.isFetching ||
          exerciseQuery.isFetching ||
          (!isView &&
            !classQuizExerciseAnswersQuery.isFetched &&
            !homeworkExerciseAnswersQuery.isFetched && (
              <div className="flex flex-col gap-10 items-center">
                <div className="flex lg:flex-row flex-wrap gap-8 w-full items-center lg:items-start lg:justify-center">
                  <Skeleton className="w-full lg:w-auto flex-grow aspect-square" radius="md" />
                  <div className="w-[420px] flex flex-col gap-4 m-auto lg:m-0">
                    <Skeleton className="w-full h-20" />
                    <Skeleton className="w-full h-20" />

                    {!isView && (
                      <div className="flex flex-col gap-4 w-full items-center mt-10">
                        <Skeleton className="w-full h-16" />
                        <Skeleton className="w-full h-16" />
                        <Skeleton className="w-full h-16" />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        {exercise && !exercise.customProblem && (
          <ChessboardExercise
            exercise={exercise}
            sidebar={{
              moveActions: true,
              solutionBox: { initialShowMoves: showAnswer, show: true },
            }}
            sidebarChildren={
              !isView && (
                <div className="flex flex-col gap-4">
                  {accordionsMemo?.map((accord, index) => (
                    <ExerciseAnswersAccordion
                      key={`accord-${index}`}
                      status={accord.status}
                      students={accord.students}
                      title={accord.title}
                      totalStudents={accord.totalStudents}
                    />
                  ))}
                </div>
              )
            }
            sidebarClassName="lg:w-[420px]"
          />
        )}
        {exercise && exercise.customProblem && (
          <div className="flex flex-col lg:flex-row min-h-full w-full gap-8 justify-center">
            <ExerciseCustomProblem customProblem={exercise.customProblem} />
            {!isView && (
              <div className="flex flex-col gap-4">
                {accordionsMemo?.map((accord, index) => (
                  <ExerciseAnswersAccordion
                    key={`accord-${index}`}
                    status={accord.status}
                    students={accord.students}
                    title={accord.title}
                    totalStudents={accord.totalStudents}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};
