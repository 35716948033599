import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { Menu, RingProgress } from '@mantine/core';

import { IClassQuizSession, IEnumEducationFormatKeys } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import { colors } from '@chess-class/sandbox/consts';

import { ChaptersPopover } from '~mClassQuizzes/components/molecules/ChaptersPopover';

export const studentClassQuizSessionColumns: (
  educationFormat: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IClassQuizSession>[] = (educationFormat: IEnumEducationFormatKeys) => {
  return [
    {
      accessorFn: (row) => (
        <Link to={`/classQuiz/${row.classQuiz.id}`}>
          {`${t('assignment')} #${row.classQuiz.orderByClass}`}
        </Link>
      ),
      accessorKey: 'id',
      header: t('title'),
    },
    {
      accessorFn: (row) => row.classQuiz.groupName || '-',
      accessorKey: 'classQuiz.groupName',
      header: educationFormat === 'GENERAL' ? t('class') : t('group'),
    },
    {
      accessorFn: (row) =>
        row.classQuiz.chapterIds && row.classQuiz.chapterIds.length != 0 ? (
          <Menu closeDelay={400} openDelay={100} trigger="hover" variant="actions" withArrow>
            <Menu.Target>
              <span className="cursor-pointer text-[#5D70FF]">{`${
                row.classQuiz.chapterIds.length
              } ${t('chapter', { count: row.classQuiz.chapterIds.length })}`}</span>
            </Menu.Target>
            <ChaptersPopover chapterIds={row.classQuiz.chapterIds} />
          </Menu>
        ) : (
          '-'
        ),
      accessorKey: 'classQuiz',
      header: t('chapters'),
    },
    {
      accessorFn: (row) => moment(row.startAt).format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'startAt',
      header: t('startAt'),
    },
    {
      accessorFn: (row) => moment(row.finishAt).format('DD/MM/YYYY, HH:mm'),
      accessorKey: 'finishAt',
      header: t('finishAt'),
    },
    {
      accessorFn: ({ studentExercises, totalSolved }) => {
        const successPercent = Math.round((totalSolved * 100) / studentExercises.length) || 0;
        const failPercent = Math.round(
          (studentExercises.filter(({ status }) => status == 'FAILED').length /
            studentExercises.length) *
            100,
        );

        return (
          <div className="flex items-center gap-3">
            <RingProgress
              sections={[
                {
                  color: colors.red['500'],
                  value: failPercent,
                },
                {
                  color: colors.green['500'],
                  value: successPercent,
                },
              ]}
              size={32}
              thickness={4}
            />
            <span className="leading-normal whitespace-nowrap">
              {successPercent}%{failPercent ? ` / ${failPercent}%` : ''}
            </span>
          </div>
        );
      },
      accessorKey: 'totalSolved',
      header: t('report'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      header: t('status'),
    },
  ];
};
