import { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Divider, Modal } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachHomeworkEditRequest, IHomeworkSession } from '@chess-class/sandbox';
import { ClockIcon } from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

type IHomeworkEditModal = {
  homeworkId: string;
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
  refetch?: () => void;
  sessions: IHomeworkSession[];
};

export const HomeworkEditModal: FC<IHomeworkEditModal> = ({
  homeworkId,
  id = '',
  isOpen,
  onCancel,
  refetch,
  sessions,
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const homeworkEdit = api.education.homework.useCoachHomeworkEdit();

  const homeworkQuery = api.education.homework.useCoachHomework({ id: homeworkId });
  const homework = homeworkQuery.data?.data;

  const form = useForm<ICoachHomeworkEditRequest>({
    initialValues: api.education.homework.helpers.coachHomeworkEditInitial,
    validate: yupResolver(api.education.homework.helpers.coachHomeworkEditValidation),
  });

  const session = useMemo(() => {
    return sessions.find((session) => session.id == id);
  }, [sessions, id]);

  useEffect(() => {
    if (homework && session) {
      form.setValues({
        finishAt: new Date(session.finishAt),
        id: homework?.id,
        sessionId: session ? session?.id : id,
      });
    }
  }, [homework, session]);

  return (
    <Modal
      centered
      closeButtonProps={{
        iconSize: 24,
      }}
      onClose={onCancel}
      opened={isOpen}
      size={770}
      title={t('homeworksPage.editDueDate')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          homeworkEdit
            .mutateAsync({
              ...values,
              finishAt: moment(values.finishAt)
                .utcOffset('GMT+00:00')
                .format('yyyy-MM-DD HH:mm:ss'),
            })
            .then(() => {
              notify('success', t('homeworksPage.successEditDueDateChange'));
              onCancel();
              refetch?.();
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="p-5">
          <span className="text-gray-102 flex items-center gap-1 mr-8 py-6">
            <ClockIcon className="w-4 h-4" />
            <span className="font-semibold">{t('homeworksPage.currentDeadLine')}:</span>{' '}
            {moment.utc(session?.finishAt).utcOffset('GMT+05:00').format('DD.MM.YYYY, HH:mm')}
          </span>
          <DateTimePicker
            label={t('homeworksPage.newDeadLine')}
            locale={language}
            minDate={new Date()}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
            placeholder="DD.MM.YYYY, HH:mm"
            required
            valueFormat="DD.MM.YYYY, HH:mm"
            {...form.getInputProps('finishAt')}
          />
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="flex justify-center gap-4 pt-0">
          <Button className="w-[220px]" onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
          <Button className="w-[220px]" loading={homeworkEdit.isLoading} type="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
