import { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Select, Skeleton } from '@mantine/core';

import { NotFoundResult } from '@chess-class/sandbox/components';
import {
  ChevronDownIcon,
  FiltersIcon,
  IllustrationHorseOrangeIcon,
  IllustrationPawnSearchYellowIcon,
} from '@chess-class/sandbox/icons';

import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';
import useClassGuidelineChapters from '~/hooks/useClassGuidelineChapters';

import StudioClassChapterBox from '~mStudio/components/molecules/StudioClassChapterBox';

export default function StudioGroupPage() {
  const { id = '' } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { coachMe, myClasses, school } = useCoachContext();
  const { classGuidelineChapters, isLoading, selectedClass } = useClassGuidelineChapters({
    groupId: id,
  });

  //REMOVE AFTER DEMO
  const demoItems = [1, 2, 3, 19, 20, 21, 37, 38, 39, 61, 62, 63];

  const chapters =
    school?.educationFormat == 'GENERAL'
      ? classGuidelineChapters
      : coachMe?.email == 'demochessclass+2@gmail.com' ||
          coachMe?.email == 'demochessclass+5@gmail.com'
        ? classGuidelineChapters
            ?.sort((a, b) => a.numId - b.numId)
            .filter((item) => demoItems.includes(item.numId))
        : classGuidelineChapters?.sort((a, b) => a.numId - b.numId);

  const chapterRefs = useRef(chapters?.map(() => createRef<HTMLDivElement>()));
  const { hash } = useLocation();
  const [statusFilter, setStatusFilter] = useState<string | null>(null);

  useEffect(() => {
    const formattedHash = hash.substring(1);
    if (chapterRefs && formattedHash && chapterRefs.current?.[formattedHash]) {
      chapterRefs.current?.[formattedHash]?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chapterRefs, hash]);

  const sortedChapters = chapters?.sort((a, b) => {
    const statusOrder = ['GIVEN', 'AVAILABLE', undefined];
    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
  });

  const filteredChapters = sortedChapters?.filter((chapter) => {
    return statusFilter ? chapter.status === statusFilter : true;
  });

  return (
    <div className="py-4 px-2 flex flex-col gap-10 min-h-full">
      <PageMeta
        openMenuKeys={['studio']}
        selectedMenuKeys={['studio']}
        title={school?.educationFormat == 'GENERAL' ? t('classStudio') : t('groupStudio')}
      />

      <div className="flex gap-4 bg-[#CCD2FF] h-44 items-center justify-between px-9 rounded-xl">
        <span className="font-bold text-xl"> {t('studiosPage.demonstrationsStudio')}</span>
        <img
          alt="Studio banner"
          className="h-24 lg:h-36 place-self-end"
          src="/images/illustrations/demo-studio.png"
        />
        <div className="flex items-center">
          {myClasses?.length ? (
            <Select
              classNames={{ input: 'bg-white' }}
              data={myClasses.map((c) => ({ label: `${c.name}`, value: c.id }))}
              onChange={(id) => navigate(`/studio/${id || ''}`)}
              placeholder={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
              value={id}
            />
          ) : (
            !myClasses && <Skeleton height={52} radius="md" width={220} />
          )}
        </div>
      </div>

      {isLoading ? (
        <Skeleton className="w-full h-64" radius="lg" />
      ) : (
        <div className="w-full min-h-full bg-white rounded-xl border flex flex-col gap-6 p-8">
          {myClasses && !myClasses.length ? (
            <NotFoundResult
              Icon={IllustrationHorseOrangeIcon}
              subTitle={
                school?.educationFormat == 'GENERAL'
                  ? t('notFoundResult.createClassHelpMessage')
                  : t('notFoundResult.createGroupHelpMessage')
              }
              title={
                school?.educationFormat == 'GENERAL'
                  ? t('notFoundResult.createClass')
                  : t('notFoundResult.createGroup')
              }
            />
          ) : (
            <>
              <div className="flex gap-4 justify-between">
                <h3 className="text-[#667080] font-bold">{t('chaptersPage.chapterList')}</h3>
                <div className="flex flex-row items-center gap-4">
                  <span className="text-[#C7C6C7]">
                    {t('passed').toLowerCase()}:{' '}
                    {classGuidelineChapters?.filter(
                      (chap) => !!chap.status && chap.status === 'GIVEN',
                    ).length || 0}
                    /{classGuidelineChapters?.length || 0}
                  </span>
                  <Select
                    className="bg-white"
                    clearable
                    data={[
                      { label: t('statuses.ALL'), value: '' },
                      { label: t(`statuses.GIVEN`), value: 'GIVEN' },
                      { label: t('statuses.AVAILABLE'), value: 'AVAILABLE' },
                    ]}
                    icon={<FiltersIcon className="w-5 h-5" />}
                    onChange={(value) => setStatusFilter(value)}
                    placeholder={t('filter')}
                    prefix="prefix"
                    rightSection={
                      <ChevronDownIcon className="w-3.5 h-3.5 rotate-180 text-gray-109" />
                    }
                    value={statusFilter}
                  />
                </div>
              </div>

              {!classGuidelineChapters?.length ? (
                selectedClass ? (
                  <NotFoundResult
                    Icon={IllustrationPawnSearchYellowIcon}
                    title={t('notFoundResult.noChapters')}
                  />
                ) : (
                  <NotFoundResult
                    Icon={IllustrationHorseOrangeIcon}
                    subTitle={t('studiosPage.weWillSuggest')}
                    title={
                      school?.educationFormat == 'GENERAL'
                        ? t('studiosPage.chooseClass')
                        : t('studiosPage.chooseGroup')
                    }
                  />
                )
              ) : (
                filteredChapters?.map(({ exercisesCount, id, numId, status }) => (
                  <div key={id} ref={chapterRefs.current?.[numId]}>
                    <StudioClassChapterBox
                      chapterId={id}
                      count={exercisesCount || 0}
                      status={status}
                    />
                  </div>
                ))
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
