import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';

import { IEnumReviewContentType, IGuidelineVideo, ILocalization } from '@chess-class/sandbox';
import { PlyrPlayer } from '@chess-class/sandbox/components';

import { ReviewContent } from '~/components/organisms/ReviewContent';

import '~/assets/chess-sandbox/plyr.css';

export default function StudioDemoVideo({
  onBack,
  onNext,
  video,
}: {
  onBack?: () => void;
  onNext?: () => void;
  video: IGuidelineVideo;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const [isVideoEnd, setIsVideoEnd] = useState(false);
  const [openReviewContentModal, setOpenReviewContentModal] = useState(false);

  useEffect(() => {
    if (isVideoEnd) {
      setOpenReviewContentModal(true);
    }
  }, [isVideoEnd]);

  return (
    <div className="py-5 px-5 md:py-10 md:px-10 lg:px-20 flex flex-col mx-auto min-h-full overflow-hidden flex-grow w-full lg:w-3/4 sm:max-w-[125vh]">
      <div className="w-full flex flex-col p-6 gap-6 rounded-lg bg-white mx-auto">
        <PlyrPlayer
          className="w-full aspect-video rounded-lg"
          localization={language as ILocalization}
          onEnd={(v) => setIsVideoEnd(v)}
          video={video}
        />
        <div className="flex flex-col">
          <h3 className="text-base font-bold">
            {video.translations?.[language]?.fullTitle ?? video.translations?.[language]?.title}
          </h3>
          {!!video.test && (
            <>
              <span className="text-[#667080] font-medium">
                {t('author')}: {video.translations?.[language]?.videoFile?.author?.name || '-'}
              </span>
              <span className="text-gray-600 font-semibold">
                {video.translations?.[language]?.videoFile?.author?.rank || 'GM'}
              </span>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-6 shrink-0 grow-0 pt-8">
        {!!onBack && (
          <Button className="px-20" onClick={onBack} variant="outline">
            {t('goBack')}
          </Button>
        )}
        {!!onNext && (
          <Button className="px-20" onClick={onNext} variant="primary">
            {t('next')}
          </Button>
        )}
      </div>
      <ReviewContent
        contentId={video.id ?? ''}
        contentType={IEnumReviewContentType.VIDEO}
        onClose={() => setOpenReviewContentModal(false)}
        open={openReviewContentModal}
        title={t('review.reviewVideo')}
      />
    </div>
  );
}
