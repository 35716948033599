import { type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  Button,
  Checkbox,
  clsx,
  Divider,
  Modal,
  ScrollArea,
  Select,
  Skeleton,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachClassQuizEditRequest } from '@chess-class/sandbox';
import { LockIcon } from '@chess-class/sandbox/icons';
import { notify } from '@chess-class/sandbox/utils';

import { useCoachContext } from '~/context/CoachContext';
import useClassGuidelineChapters from '~/hooks/useClassGuidelineChapters';

type IClassQuizEditModal = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const ClassQuizEditModal: FC<IClassQuizEditModal> = ({ id = '', isOpen, onCancel }) => {
  const { myClasses, school } = useCoachContext();
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const classQuizEdit = api.education.classQuiz.useCoachClassQuizEdit();
  const randomExercises = api.content.exercises.useExercisesRandom();

  const classQuizQuery = api.education.classQuiz.useCoachClassQuiz({ id });
  const classQuiz = classQuizQuery.data?.data;

  const form = useForm<ICoachClassQuizEditRequest>({
    initialValues: api.education.classQuiz.helpers.coachClassQuizEditInitial,
    validate: yupResolver(api.education.classQuiz.helpers.coachClassQuizEditValidation),
  });

  const { classGuidelineChapters, isLoading } = useClassGuidelineChapters({
    groupId: classQuiz?.classId,
  });

  useEffect(() => {
    if (classQuiz) {
      form.setValues({
        chapterIds: classQuiz.chapterIds,
        exercises: classQuiz.exercises.map((ex) => ({ ...ex, numId: ex.numId || 0 })),
        finishAt: new Date(classQuiz.finishAt) as unknown as string,
        id: classQuiz?.id,
        startAt: new Date(classQuiz.startAt) as unknown as string,
      });
    }
  }, [classQuiz]);

  return (
    <Modal
      centered
      closeButtonProps={{
        iconSize: 24,
      }}
      onClose={onCancel}
      opened={isOpen}
      scrollAreaComponent={ScrollArea.Autosize}
      size={770}
      title={t('assignmentsPage.editAssignment')}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          randomExercises
            .mutateAsync({
              chapterId: form.values.chapterIds,
              type: 'TEST',
            })
            .then((res) => {
              const exercisesList = res.data.map((item) => {
                return {
                  chapterId: item?.chapter?.id ?? '',
                  id: item.id,
                  numId: item.numId || 0,
                  type: item.type,
                };
              });
              classQuizEdit
                .mutateAsync({
                  ...values,
                  exercises: form.isDirty('chapterIds') ? exercisesList : values.exercises,
                  finishAt: moment(values.finishAt)
                    .utcOffset('GMT+00:00')
                    .format('yyyy-MM-DD HH:mm:ss'),
                  startAt: moment(values.startAt)
                    .utcOffset('GMT+00:00')
                    .format('yyyy-MM-DD HH:mm:ss'),
                })
                .then(() => {
                  notify('success', t('messages.successAssignmentEdit'));
                  onCancel();
                })
                .catch(() => {
                  notify('error', t('tryAgainLater'));
                });
            });
        })}
      >
        <div className="p-5">
          <Select
            data={
              myClasses?.map((group) => ({
                label:
                  school?.educationFormat == 'GENERAL'
                    ? `${group.grade} ${group.symbol}`
                    : group.symbol,
                value: group.id,
              })) || []
            }
            disabled
            label={school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
            name="classId"
            value={classQuiz?.classId}
          />
          <p className="text-center text-gray-500 mt-6 mb-4">
            {' '}
            {t('assignmentsPage.chooseTopics')}
          </p>
          <ScrollArea
            classNames={{
              viewport: 'min-h-[192px] max-h-[256px]',
            }}
          >
            {isLoading ? (
              <Skeleton className="w-full h-48" />
            ) : classGuidelineChapters && classGuidelineChapters?.length != 0 ? (
              <Checkbox.Group
                defaultValue={classQuiz?.chapterIds}
                withAsterisk
                {...form.getInputProps('chapterIds', { type: 'checkbox' })}
              >
                {classGuidelineChapters
                  ?.filter((chapter) => chapter.status === 'GIVEN')
                  .map((chapter) => {
                    const isAvailable = chapter.status == 'GIVEN' || chapter.status == 'AVAILABLE';
                    // const isSelected = form.values.chapterIds.includes(chapter.rootChapterId);

                    return (
                      <Checkbox
                        disabled={chapter.status != 'GIVEN' && chapter.status != 'AVAILABLE'}
                        key={chapter.id}
                        label={
                          <div className="flex gap-3">
                            <span
                              className={clsx(
                                chapter.status == 'AVAILABLE'
                                  ? 'text-primary'
                                  : !isAvailable
                                    ? 'text-gray-200'
                                    : 'text-gray-600',
                              )}
                            >
                              {`${chapter.priority < 10 ? '0' : ''}${chapter.priority}`}
                            </span>
                            {chapter.translations?.[language]?.title || '-'}

                            {!isAvailable && (
                              <LockIcon className="my-auto h-4 w-4 mr-5 text-gray-112/40 ml-auto" />
                            )}

                            {chapter.status == 'GIVEN' && (
                              // && !isSelected
                              <span className="my-auto mr-5 text-gray-112 whitespace-nowrap ml-auto">
                                {t('passed')}
                              </span>
                            )}
                          </div>
                        }
                        max={5}
                        maxLength={5}
                        value={chapter.rootChapterId}
                      />
                    );
                  })}
              </Checkbox.Group>
            ) : (
              <div className="flex items-center flex-col justify-center bg-white h-full py-8">
                <img
                  className="w-28 h-40 object-contain"
                  src="/images/illustrations/pawn-search.png"
                />
                <span className="mt-4 text-gray-500 font-medium">
                  {school?.educationFormat == 'GENERAL'
                    ? t('assignmentsPage.chooseClass')
                    : t('assignmentsPage.chooseGroup')}
                </span>
              </div>
            )}
          </ScrollArea>
          <div className="grid grid-cols-2 gap-4 justify-center mt-6">
            <DateTimePicker
              label={t('startDate')}
              locale={language}
              minDate={new Date()}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder="DD.MM.YYYY, HH:mm"
              valueFormat="DD.MM.YYYY, HH:mm"
              {...form.getInputProps('startAt')}
              required
            />
            <DateTimePicker
              label={t('finishDate')}
              locale={language}
              minDate={new Date()}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              placeholder="DD.MM.YYYY, HH:mm"
              required
              valueFormat="DD.MM.YYYY, HH:mm"
              {...form.getInputProps('finishAt')}
            />
          </div>
        </div>

        <Divider className="my-8 -mx-6" />

        <div className="flex justify-center gap-4 pt-0">
          <Button className="w-[220px]" onClick={onCancel} variant="outline">
            {t('cancel')}
          </Button>
          <Button className="w-[220px]" loading={classQuizEdit.isLoading} type="submit">
            {t('save')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
