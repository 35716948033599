import { t } from 'i18next';

enum StudentGenderEnum {
  BOY = 'MALE',
  GIRL = 'FEMALE',
}

export const getStudentGenders = () => [
  {
    label: t('gender.boy'),
    value: StudentGenderEnum.BOY,
  },
  {
    label: t('gender.girl'),
    value: StudentGenderEnum.GIRL,
  },
];
