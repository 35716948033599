import { useMemo } from 'react';

import { api } from '@chess-class/sandbox';

import { useCoachContext } from '~/context/CoachContext';

export default function useClassGuidelineChapters({ groupId }: { groupId?: string }) {
  const { guidelines, myClasses, school } = useCoachContext();

  const selectedClass = useMemo(
    () => (!myClasses ? null : myClasses?.find((c) => c.id == groupId)),
    [myClasses, groupId],
  );

  const guidelineId =
    selectedClass === null
      ? null
      : guidelines?.find((guide) => guide.grade == selectedClass?.grade)?.id;

  const classChaptersQuery = api.education.classChapters.useClassChapters({
    groupId,
    page: 0,
    size: school?.educationFormat == 'GENERAL' ? 100 : 500,
  });

  const classChapters = classChaptersQuery.data?.data;

  const guidelineChaptersQuery = api.guideline.guidelineChapters.useGuidelineChapters({
    enabled: school?.educationFormat == 'PRIVATE' || !!guidelineId,
    guidelineId:
      school?.educationFormat == 'GENERAL'
        ? (guidelineId as string)
        : guidelines?.map((guid) => guid.id),
    size: 200,
    sort: 'priority',
  });
  const guidelineChapters = guidelineChaptersQuery.data?.data.content;

  const classGuidelineChapters = useMemo(() => {
    return guidelineChapters?.map((guideChapter) => ({
      ...guideChapter,
      ...classChapters?.find((chapter) => chapter.chapterId == guideChapter.id),
    }));
  }, [guidelineChapters, classChapters]);

  return {
    classGuidelineChapters,
    guidelineId,
    isLoading:
      classChaptersQuery.isLoading || guidelineChaptersQuery.isLoading || guidelineId === null,
    selectedClass,
  };
}
