import { type FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import i18n from 'i18next';
import { Button, Image, Modal, ScrollArea, Skeleton, Switch, Title } from '@mantine/core';

import { api, IEnumReviewContentType, IStudentSmall } from '@chess-class/sandbox';
import { TestAnswersBox } from '@chess-class/sandbox/components';

import { ReviewContent } from '~/components/organisms/ReviewContent';

import {
  ExerciseAnswersAccordion,
  ExerciseAnswersAccordionProps,
} from '~mClassQuizzes/components/molecules/ExerciseAnswersAccordion';

type IExerciseTestModal = {
  exerciseId: string;
  isBackButtonDisabled: boolean;
  isHomeWork?: boolean;
  isNextButtonDisabled: boolean;
  isOpen: boolean;
  isView?: boolean;
  onBackButtonClick: () => void;
  onCancel: () => void;
  onNextButtonClick: () => void;
};

type IExerciseAccordion = {
  status?: ExerciseAnswersAccordionProps['status'];
  students: ExerciseAnswersAccordionProps['students'];
  title: string;
  totalStudents: number;
};

export const ExerciseTestModal: FC<IExerciseTestModal> = ({
  exerciseId,
  isBackButtonDisabled,
  isHomeWork = false,
  isNextButtonDisabled,
  isOpen,
  isView = false,
  onBackButtonClick,
  onCancel,
  onNextButtonClick,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [showAnswer, setShowAnswer] = useState(false);

  const exerciseQuery = api.content.exercises.useExercise({ id: exerciseId });
  const exercise = exerciseQuery.data?.data;

  useEffect(() => {
    setShowAnswer(false);
  }, [exercise]);

  const classQuizExerciseAnswersQuery = api.education.classQuiz.useCoachClassQuizExerciseAnswers({
    enabled: !isView && !!exerciseId && !isHomeWork,
    exerciseId: exerciseId,
    id: id ?? '',
  });
  const classQuizExerciseAnswers = classQuizExerciseAnswersQuery.data?.data ?? [];

  const homeworkExerciseAnswersQuery = api.education.homework.useCoachHomeworkExerciseAnswers({
    enabled: !isView && !!exerciseId && isHomeWork,
    exerciseId: exerciseId,
    id: id ?? '',
  });
  const homeworkExerciseAnswers = homeworkExerciseAnswersQuery.data?.data ?? [];

  const exercises = isHomeWork ? homeworkExerciseAnswers : classQuizExerciseAnswers;

  const accordionsMemo = useMemo<IExerciseAccordion[]>(() => {
    const totalStudents = exercises.length || 0;

    const accordions =
      exercise?.test?.translations[i18n.language]?.answerOptions.map((option, index) => ({
        status: !showAnswer
          ? undefined
          : ((exercise?.test?.correctOption == index + 1
              ? 'good'
              : 'fail') as ExerciseAnswersAccordionProps['status']),
        students: exercises
          .filter((exercise) => exercise.answerChoice == index)
          .map((ex) => ex.student) as IStudentSmall[],
        title: `${String.fromCharCode(65 + index)}. ${option}`,
        totalStudents,
      })) || [];

    return [
      ...accordions,
      {
        status: 'unsolved',
        students: exercises
          .filter((ex) => ex.status === 'NA')
          .map((ex) => ex.student) as IStudentSmall[],
        title: t(`exerciseStatus.NA`),
        totalStudents,
      },
    ];
  }, [exercise, exercises, showAnswer]);

  return (
    <>
      <Modal
        classNames={{ body: 'p-12 bg-gray-116' }}
        onClose={onCancel}
        opened={isOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        size="85%"
        title={
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex items-center">
              <img
                alt="Test"
                className="w-12 h-12 mr-6 object-contain"
                src="/images/illustrations/test-img.png"
              />{' '}
              <Title size={24}>{`${t('problem')} #${exercise?.numId ?? ''}`}</Title>
            </div>

            <div className="flex flex-row items-center">
              <Switch
                checked={showAnswer}
                className="mr-4"
                label={t('studiosPage.showAnswer')}
                labelPosition="left"
                onChange={() => setShowAnswer(!showAnswer)}
              />
              {isView && (
                <ReviewContent
                  contentId={exercise?.id ?? ''}
                  contentType={IEnumReviewContentType.TEST}
                  title={t('review.reviewExercise')}
                />
              )}
            </div>
          </div>
        }
      >
        {homeworkExerciseAnswersQuery.isFetching ||
        classQuizExerciseAnswersQuery.isFetching ||
        exerciseQuery.isFetching ||
        (!isView &&
          !classQuizExerciseAnswersQuery.isFetched &&
          !homeworkExerciseAnswersQuery.isFetched) ? (
          <div className="flex flex-col gap-10 items-center">
            <Skeleton className="w-1/2 h-40" radius="md" />
            <Skeleton className="w-32 h-5" />
            {!isView && (
              <div className="flex flex-col gap-4 w-full items-center">
                <Skeleton className="w-full h-16" />
                <Skeleton className="w-full h-16" />
                <Skeleton className="w-full h-16" />
                <Skeleton className="w-full h-16" />
              </div>
            )}
          </div>
        ) : (
          <div>
            {showAnswer && !!exercise?.test?.answerImageUrl ? (
              <Image
                alt=""
                className="object-contain"
                maw={300}
                mx="auto"
                radius="md"
                src={`${exercise?.test?.answerImageUrl}`}
              />
            ) : (
              <Image
                alt=""
                className="object-contain"
                maw={300}
                mx="auto"
                radius="md"
                src={`${exercise?.test?.imageUrl}`}
              />
            )}
            <p className="my-12 text-center text-lg font-bold">
              {exercise?.test?.translations?.[i18n.language]?.question}
            </p>

            {isView && exercise ? (
              <TestAnswersBox
                exercise={exercise}
                // isView={isView}
                onAnswer={(correct) => {
                  setShowAnswer(correct);
                }}
                showAnswer={showAnswer}
              />
            ) : (
              !isView &&
              accordionsMemo?.map((accord, index) => (
                <ExerciseAnswersAccordion
                  key={`accord-${index}`}
                  status={accord.status}
                  students={accord.students}
                  test
                  title={accord.title}
                  totalStudents={accord.totalStudents}
                />
              ))
            )}
          </div>
        )}
        <div className="flex flex-row items-end justify-center mt-8">
          <Button
            className="w-44 mr-2"
            disabled={isBackButtonDisabled}
            onClick={onBackButtonClick}
            variant="outline"
          >
            {t('back')}
          </Button>
          <Button className="w-44" disabled={isNextButtonDisabled} onClick={onNextButtonClick}>
            {t('next')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
