import { Route, Routes } from 'react-router-dom';

import { GroupDetailsPage } from '~mGroups/groups/pages/GroupDetailsPage';
import { GroupsMyPage } from '~mGroups/groups/pages/GroupsMyPage';
import { SchoolGroupsPage } from '~mGroups/groups/pages/SchoolGroupsPage';
import { SchoolStudentsPage } from '~mGroups/groups/pages/SchoolStudentsPage';
import { StudentDetailsPage } from '~mGroups/students/pages/StudentDetailsPage';

export const GroupsRouter = () => {
  return (
    <Routes>
      <Route element={<GroupsMyPage />} index />
      <Route element={<SchoolGroupsPage />} path="school" />
      <Route element={<SchoolStudentsPage />} path="students" />
      <Route element={<GroupDetailsPage />} path=":id" />
      <Route element={<StudentDetailsPage type="homework" />} path="/student/:id" />
      <Route element={<StudentDetailsPage type="classQuiz" />} path="/student/:id/classQuiz" />
    </Routes>
  );
};
