import { Route, Routes } from 'react-router-dom';

import { HomeworkDetailsPage } from '~mHomeworks/pages/HomeworkDetailsPage';
import HomeworksPage from '~mHomeworks/pages/HomeworksPage';

export const HomeworksRouter = () => {
  return (
    <Routes>
      <Route element={<HomeworksPage />} index />
      <Route element={<HomeworkDetailsPage />} path="/:id" />
    </Routes>
  );
};
