import { Route, Routes } from 'react-router-dom';

import CoachPage from '~mCoaches/pages/CoachPage';

export const CoachesRouter = () => {
  return (
    <Routes>
      <Route element={<CoachPage />} index />
    </Routes>
  );
};
