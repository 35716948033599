import { type FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Menu } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';

import { IGroup } from '@chess-class/sandbox';
import { EditIcon, TrashIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';

type ClassCardProps = {
  color: { bgColor: string; textColor: string };
  group: IGroup;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
};

export const GroupCard: FC<ClassCardProps> = ({ color, group, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const { school } = useContext(CoachContext);

  return (
    <Link to={`/classes/${group.id}`}>
      <div className="flex px-4 py-5 border rounded-2.5 bg-white gap-4 items-center w-64 flex-grow hover:border-primary transition-all">
        {school?.educationFormat == 'GENERAL' && (
          <div
            className="rounded-xl flex items-center justify-center w-11 h-11 font-bold text-base shrink-0 leading-3"
            style={{
              backgroundColor: color.bgColor,
              color: color.textColor,
            }}
          >
            {group.grade}
            {group.symbol}
          </div>
        )}

        <div className="flex flex-col flex-grow gap-2">
          <span className="font-semibold leading-none text-primary-900">
            {school?.educationFormat == 'GENERAL'
              ? `${t('class')} ${group.grade} “${group.symbol}”`
              : `${t('group')} ${group.name}`}
          </span>
          <span className="text-gray-108 text-xs leading-none">
            {group.studentsCount || 0}{' '}
            {t('student', { count: group.studentsCount || 0 }).toLowerCase()}
          </span>
        </div>
        <Menu shadow="md" variant="actions" withinPortal zIndex={10}>
          <Menu.Target>
            <Button
              className="rounded-full border-transparent hover:bg-[#F6F4FF] p-0 min-w-[32px] h-[32px]"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              radius="xl"
              variant="outline"
            >
              <IconDotsVertical className="text-gray-102 hover:text-primary-400" size={21} />
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item
              icon={<EditIcon />}
              onClick={(event) => {
                onEdit(group.id);
                event.stopPropagation();
              }}
            >
              {t('edit')}
            </Menu.Item>
            <Menu.Item
              icon={<TrashIcon />}
              onClick={(event) => {
                onDelete(group.id);
                event.stopPropagation();
              }}
            >
              {t('delete')}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </div>
    </Link>
  );
};
