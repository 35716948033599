import { IPageActions } from '@chess-class/sandbox';

export enum IEnumGroupsMyPageActions {
  'editClass',
  'createClass',
  'deleteClass',
  'assignStudent',
  'activateStudent',
  'deactivateStudent',
  'editStudent',
  'createStudent',
  'excludeStudentFromClass',
  'excludeStudentFromSchool',
}

export enum IEnumGroupsMyActionType {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

export type IGroupsMyPageActions = {
  action: keyof typeof IEnumGroupsMyPageActions;
  actionId: string;
  actionType: keyof typeof IEnumGroupsMyActionType;
};

export const groupsMyPageActions: IPageActions<IGroupsMyPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumGroupsMyPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: 'ASSIGNED' },
};
