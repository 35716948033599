import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Select, Skeleton, Title } from '@mantine/core';

import { ChevronDownIcon, IllustrationHorseOrangeIcon } from '@chess-class/sandbox/icons';

import GroupNotFound from '~/components/atoms/GroupNotFound';
import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';

export default function StudioPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { myClasses, school } = useCoachContext();

  useEffect(() => {
    if (myClasses?.length == 1) {
      navigate(`/studio/${myClasses[0].id}`);
    }
  }, [myClasses]);

  return (
    <div className="py-4 px-2 flex flex-col gap-11 h-full">
      <PageMeta openMenuKeys={['studio']} selectedMenuKeys={['studio']} title={t('studio')} />
      <div className="flex items-center gap-4 justify-between">
        <Title order={2}>{t('studiosPage.demonstrationsStudio')}</Title>
        {myClasses?.length ? (
          <Select
            classNames={{ input: 'placeholder-gray-110 bg-white' }}
            data={myClasses.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
            onChange={(id) => navigate(`/studio/${id || ''}`)}
            placeholder={
              school?.educationFormat == 'GENERAL'
                ? t('studiosPage.chooseClass')
                : t('studiosPage.chooseGroup')
            }
            rightSection={<ChevronDownIcon className="w-3.5 h-3.5 rotate-180 text-gray-109" />}
          />
        ) : (
          !myClasses && <Skeleton height={52} radius="md" width={220} />
        )}
      </div>

      {myClasses && !myClasses.length ? (
        <GroupNotFound />
      ) : (
        <div className="w-full h-full bg-white rounded-xl border flex flex-col gap-6 justify-center items-center">
          <IllustrationHorseOrangeIcon className="h-60 w-44 object-contain max-h-[40%]" />
          <Title order={2}>
            {school?.educationFormat == 'GENERAL'
              ? t('studiosPage.chooseClass')
              : t('studiosPage.chooseGroup')}
          </Title>
          <span className="text-gray-400 font-medium">{t('studiosPage.weWillSuggest')}</span>
        </div>
      )}
    </div>
  );
}
