import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Skeleton } from '@mantine/core';

import { api, IEnumClassChapterStatusEnumKeys, IEnumReviewContentType } from '@chess-class/sandbox';
import { GuidelineChapterExercisesIcon } from '@chess-class/sandbox/icons';

import { ReviewContent } from '~/components/organisms/ReviewContent';
import { CoachContext } from '~/context/CoachContext';

export default function StudioClassChapterBox({
  chapterId,
  count,
  status,
}: {
  chapterId: string;
  count: number;
  status?: IEnumClassChapterStatusEnumKeys;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const chapterQuery = api.guideline.guidelineChapters.useGuidelineChapter({ id: chapterId });
  const chapter = chapterQuery.data?.data;
  const { school } = useContext(CoachContext);

  if (chapterQuery.isLoading) {
    return <Skeleton className="w-full h-10" />;
  }

  if (!chapter) {
    return <div />;
  }

  return (
    <div
      className={clsx(
        'flex gap-8 justify-between items-center py-6',
        !status ? 'opacity-40 pointer-events-none' : '',
      )}
      id={
        school?.educationFormat == 'GENERAL'
          ? chapter.priority.toString()
          : chapter.numId.toString()
      }
      key={`box-${chapter.id}`}
    >
      <div className="flex gap-8">
        <Link className="flex justify-center items-center relative" to={chapter.id}>
          <img
            alt="Theme"
            className="w-14 h-14 object-contain"
            src="/images/illustrations/class-chapter-bg.png"
          />
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-3xl font-bold">
            {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}
          </span>
        </Link>
        <div>
          <Link to={chapter.id}>
            <h4 className="font-semibold text-base text-black hover:text-primary">
              {t('chapter')}{' '}
              {school?.educationFormat == 'GENERAL' ? chapter.priority : chapter.numId}:{' '}
              {chapter.translations?.[language]?.title}
            </h4>
          </Link>
          <div className="flex flex-row items-center ">
            <span className="text-[#B9B9B9] flex items-center gap-1">
              <GuidelineChapterExercisesIcon />
              {`${count} ${t('problem', {
                count,
              }).toLowerCase()}`}
            </span>
            <ReviewContent
              contentId={chapter.id ?? ''}
              contentType={IEnumReviewContentType.CHAPTER}
              title={t('review.reviewChapter')}
            />
          </div>
        </div>
      </div>

      <span
        className={clsx(
          'py-2 px-4 rounded-lg whitespace-nowrap',
          status == 'AVAILABLE'
            ? 'bg-[#E4F2FF] text-[#4494F8]'
            : status == 'GIVEN'
              ? 'bg-[#F5FBF8] text-[#359A73]'
              : 'bg-gray-200',
        )}
      >
        {t(`statuses.${!status ? 'NOT_AVAILABLE' : status}`)}
      </span>
    </div>
  );
}
