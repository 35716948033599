import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import { NotFoundResult } from '@chess-class/sandbox/components';

import IndexRoute from '~/components/molecules/routes/IndexRoute';
import AdminLayout from '~/layouts/AdminLayout';
import AuthLayout from '~/layouts/AuthLayout';

import AuthRouter from '~mAuth/AuthRouter';
import { ChaptersRouter } from '~mChapters/ChaptersRouter';
import { ClassQuizRouter } from '~mClassQuizzes/ClassQuizRouter';
import { CoachesRouter } from '~mCoaches/CoachesRouter';
import { DevRouter } from '~mDev/DevRouter';
import { GroupsRouter } from '~mGroups/GroupsRouter';
import { HomeworksRouter } from '~mHomeworks/HomeworksRouter';
import { StudioRouter } from '~mStudio/StudioRouter';

import { CoachContext } from './context/CoachContext';
import { initializeReactGA, logPageView, setUser } from './googleAnalytics';
import { AnalyzeGameRouter } from './modules/analyze-game/AnalyzeGameRouter';

const App = () => {
  const { pathname } = useLocation();
  const { coachMe, school } = useContext(CoachContext);
  const [searchParams] = useSearchParams();
  const languageQuery = searchParams.get('lang');

  useEffect(() => {
    initializeReactGA();
    logPageView();
  }, []);

  useEffect(() => {
    if (school) {
      setUser(school?.educationFormat);
    }
  }, [school]);

  return (
    <Routes>
      <Route
        element={
          <Navigate
            to={`/auth/invitation/${pathname?.replace('/register/', '')}?lang=${languageQuery}`}
          />
        }
        path="register/:link"
      />
      <Route
        element={<Navigate to={`/auth/reset/${pathname?.replace('/reset/', '')}`} />}
        path="reset/:link"
      />
      <Route element={<AuthLayout />}>
        <Route element={<AuthRouter />} path="auth/*" />
      </Route>

      <Route element={<AdminLayout />} path="*">
        <Route element={<IndexRoute />} index />
        <Route element={<ChaptersRouter />} path="chapters/*" />
        {coachMe?.email != 'demochessclass@gmail.com' && (
          <>
            <Route element={<ClassQuizRouter />} path="classQuiz/*" />
            <Route element={<CoachesRouter />} path="coaches/*" />
            <Route element={<DevRouter />} path="dev" />
            <Route element={<GroupsRouter />} path="classes/*" />
            <Route element={<HomeworksRouter />} path="homework/*" />
            <Route element={<StudioRouter />} path="studio/*" />
            <Route element={<AnalyzeGameRouter />} path="analyze-game/*" />
          </>
        )}
        <Route element={<NotFoundResult />} path="*" />
      </Route>
    </Routes>
  );
};

export default App;
