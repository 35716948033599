import { IPageActions } from '@chess-class/sandbox';

enum IClassQuizTestPageActionsEnum {
  'view',
}

enum IClassQuizTestPageActionTypesEnum {
  'test',
  'exercise',
}

export type IExercisePageActions = {
  action: keyof typeof IClassQuizTestPageActionsEnum;
  actionId: string;
  actionType: keyof typeof IClassQuizTestPageActionTypesEnum;
};

export const classQuizTestPageActions: IPageActions<IExercisePageActions> = {
  action: {
    allowed: (value) => Object.keys(IClassQuizTestPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: '' },
};
