import { Link } from 'react-router-dom';
import i18n, { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { Menu, RingProgress } from '@mantine/core';

import { IEnumEducationFormatKeys, IHomeworkSession } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import { colors } from '@chess-class/sandbox/consts';

import { ChaptersPopover } from '~mClassQuizzes/components/molecules/ChaptersPopover';

export const studentHomeworkSessionColumns: (
  educationFormat: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IHomeworkSession>[] = (educationFormat: IEnumEducationFormatKeys) => {
  return [
    {
      accessorFn: (row) => (
        <Link to={`/homework/${row.homeWorkId}`}>
          {t('homework')} #{row?.orderByGroup}
        </Link>
      ),
      accessorKey: 'id',
      header: t('title'),
    },
    {
      accessorFn: (row) => {
        if (row.chapter) {
          return (
            <Menu closeDelay={400} openDelay={100} trigger="hover" withArrow>
              <Menu.Target>
                <span className="text-[#5D70FF] cursor-pointer">
                  {row.chapter.translations
                    ? row.chapter.translations?.[i18n.language]?.title
                    : `${t('chapter')} #${row.chapter.priority}`}
                </span>
              </Menu.Target>
              <ChaptersPopover chapterIds={[row.chapterId ?? '']} />
            </Menu>
          );
        }

        return '-';
      },
      accessorKey: 'chapter',
      header: t('chapter'),
    },
    {
      accessorKey: 'groupName',
      header: educationFormat == 'GENERAL' ? t('class') : t('group'),
    },
    {
      accessorFn: (row) => {
        return row.startAt ? moment(row.startAt).format('DD/MM/YYYY, HH:mm') : '-';
      },
      accessorKey: 'startAt',
      header: t('startAt'),
    },
    {
      accessorFn: ({ studentExercises, totalSolved }) => {
        const successPercent = Math.round((totalSolved * 100) / studentExercises.length) || 0;
        const failPercent = Math.round(
          (studentExercises.filter(({ status }) => status == 'FAILED').length /
            studentExercises.length) *
            100,
        );

        return (
          <div className="flex items-center gap-3">
            <RingProgress
              sections={[
                {
                  color: colors.red['500'],
                  value: failPercent,
                },
                {
                  color: colors.green['500'],
                  value: successPercent,
                },
              ]}
              size={32}
              thickness={4}
            />
            <span className="leading-normal whitespace-nowrap">
              {successPercent}%{failPercent ? ` / ${failPercent}%` : ''}
            </span>
          </div>
        );
      },
      accessorKey: 'totalSolved',
      header: t('report'),
    },
    {
      accessorFn: (row) => {
        return row.finishAt ? moment(row.finishAt).format('DD/MM/YYYY, HH:mm') : '-';
      },
      accessorKey: 'finishAt',
      header: t('finishDate'),
    },
    {
      accessorFn: (row) => {
        return row.updatedDate && row.status === 'FINISHED'
          ? moment(row.updatedDate).format('DD/MM/YYYY, HH:mm')
          : '-';
      },
      accessorKey: 'updatedDate',
      header: t('finishAt'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      header: t('status'),
    },
  ];
};
