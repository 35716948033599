export const classColors = [
  {
    bgColor: '#F7F7FB',
    textColor: '#5D70FF',
  },
  {
    bgColor: '#FFFAF1',
    textColor: '#FFA90D',
  },
  {
    bgColor: '#F0F5FF',
    textColor: '#4F5672',
  },
  {
    bgColor: '#FFF1F0',
    textColor: '#D4380D',
  },
  {
    bgColor: '#FFF2E8',
    textColor: '#FA541C',
  },
  {
    bgColor: '#FCFFE6',
    textColor: '#7CB305',
  },
  {
    bgColor: '#FFF0F6',
    textColor: '#F759AB',
  },
  {
    bgColor: '#F9F0FF',
    textColor: '#722ED1',
  },
];
