import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment/moment';
import { RingProgress } from '@mantine/core';

import { IEnumEducationFormatKeys, IHomeworkSession } from '@chess-class/sandbox';
import { StatusTag } from '@chess-class/sandbox/components';
import { colors } from '@chess-class/sandbox/consts';

export const homeworkSessionsColumns: (
  educationFormat?: IEnumEducationFormatKeys,
) => MRT_ColumnDef<IHomeworkSession>[] = () => {
  return [
    {
      accessorFn: (row) => {
        return row ? (
          <Link
            className={'whitespace-nowrap'}
            to={`/classes/student/${row.student.id}`}
          >{`${row.student.lastName} ${row.student.firstName} ${row.student.middleName}`}</Link>
        ) : (
          '-'
        );
      },
      accessorKey: 'id',
      header: t('fio'),
    },
    {
      accessorFn: (row) => (
        <span className="whitespace-nowrap">
          {row.startAt ? moment(row.startAt).format('DD/MM/YYYY, HH:mm') : '-'}
        </span>
      ),
      accessorKey: 'startAt',
      header: t('startAt'),
    },
    {
      accessorFn: (row) => (
        <span className="whitespace-nowrap">
          {row.finishAt ? moment(row.finishAt).format('DD/MM/YYYY, HH:mm') : '-'}
        </span>
      ),
      accessorKey: 'finishAt',
      header: t('finishDate'),
    },
    {
      accessorFn: ({ studentExercises, totalSolved }) => {
        const successPercent = Math.round((totalSolved * 100) / studentExercises.length) || 0;
        const failPercent = Math.round(
          (studentExercises.filter(({ status }) => status == 'FAILED').length /
            studentExercises.length) *
            100,
        );

        return (
          <div className="flex items-center gap-3">
            <RingProgress
              sections={[
                {
                  color: colors.red['500'],
                  value: failPercent,
                },
                {
                  color: colors.green['500'],
                  value: successPercent,
                },
              ]}
              size={32}
              thickness={4}
            />
            <span className="leading-normal whitespace-nowrap">
              {successPercent}%{failPercent ? ` / ${failPercent}%` : ''}
            </span>
          </div>
        );
      },
      accessorKey: 'totalSolved',
      header: t('report'),
    },
    {
      accessorFn: (row) => <StatusTag status={row.status} />,
      accessorKey: 'status',
      filterVariant: 'select',
      header: t('status'),
    },
  ];
};
