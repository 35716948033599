import { IFilters } from '@chess-class/sandbox';
import { modelKeys, paginationBaseFilters } from '@chess-class/sandbox/consts';

export const coachFilters: IFilters = {
  ...paginationBaseFilters,
  [modelKeys.user.coachToSchool.firstName]: {
    sort: true,
  },
  [modelKeys.user.coachToSchool.phone]: {
    sort: true,
  },
  [modelKeys.user.coachToSchool.email]: {
    sort: true,
  },
  [modelKeys.user.coachToSchool.status]: {
    sort: true,
  },
};
