import { Route, Routes } from 'react-router-dom';

import AnalyzeGamePage from './pages/AnalyzeGamePage';

export const AnalyzeGameRouter = () => {
  return (
    <Routes>
      <Route element={<AnalyzeGamePage />} index />
    </Routes>
  );
};
