import { Tabs } from '@mantine/core';

import { PageMeta } from '~/context/PageMetaContext';

import DevColorsTab from '~mDev/components/molecules/DevColorsTab';
import DevFormTab from '~mDev/components/molecules/DevFormTab';
import DevTranslationsTab from '~mDev/components/molecules/DevTranslationsTab';

export const DevPage = () => {
  return (
    <div>
      <PageMeta selectedMenuKeys={['dev']} title="DEV разработка" />

      <Tabs classNames={{ tabsList: 'gap-6' }} defaultValue="translations">
        <Tabs.List>
          <Tabs.Tab value="translations">Переводы</Tabs.Tab>
          <Tabs.Tab value="colors">Цвета</Tabs.Tab>
          <Tabs.Tab value="form">Формы</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel pt="lg" value="translations">
          <DevTranslationsTab />
        </Tabs.Panel>

        <Tabs.Panel pt="lg" value="colors">
          <DevColorsTab />
        </Tabs.Panel>

        <Tabs.Panel pt="lg" value="form">
          <DevFormTab />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
