import { type FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment';
import { Button, Loader, Menu, Tabs } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { UseQueryResult } from '@tanstack/react-query';

import {
  api,
  IHomeworkSession,
  IPageAbleResponse,
  IStudentClassQuizzesRequest,
  IStudentHomeworkRequest,
} from '@chess-class/sandbox';
import { NotFoundResult, QueryTable, StatusTag } from '@chess-class/sandbox/components';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';
import {
  EditIcon,
  LockKeyOpenIcon,
  PauseIcon,
  PlayCircleIcon,
  SettingsIcon,
  StudentIcon,
  TrashIcon,
} from '@chess-class/sandbox/icons';

import { useCoachContext } from '~/context/CoachContext';
import { PageMeta } from '~/context/PageMetaContext';
import { HomeworkEditModal } from '~/modules/homeworks/components/organisms/HomeworkEditModal';
import { homeworksFilters } from '~/modules/homeworks/consts/filters/homeworksFilters';

import { classQuizzesFilters } from '~mClassQuizzes/consts/filters/classQuizzesFilters';
import { classQuizzesPageActions } from '~mClassQuizzes/consts/pageActions/IClassQuizzesPageActions';
import { getStudentGenders } from '~mGroups/groups/consts/genders';
import { ActivateStudentModal } from '~mGroups/students/components/molecules/ActivateStudentModal';
import { ExcludeStudentModal } from '~mGroups/students/components/molecules/ExcludeStudentModal';
import { AssignStudentClassModal } from '~mGroups/students/components/organisms/AssignStudentClassModal';
import { CreateStudentModal } from '~mGroups/students/components/organisms/CreateStudentModal';
import { EditStudentModal } from '~mGroups/students/components/organisms/EditStudentModal';
import { studentClassQuizSessionColumns } from '~mGroups/students/consts/columns/studentClassQuizSessionColumns';
import { studentHomeworkSessionColumns } from '~mGroups/students/consts/columns/studentHomeworkSessionColumns';
import {
  IStudentHomeworksPageActions,
  studentHomeworksPageActions,
} from '~mGroups/students/consts/pageActions/IStudentHomeworksPageActions';
import { IStudentsPageActions } from '~mGroups/students/consts/pageActions/IStudentsPageActions';

export const StudentDetailsPage: FC<{ type: 'homework' | 'classQuiz' }> = ({ type }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { school } = useCoachContext();
  const navigate = useNavigate();

  const { actioned, clearParams, filtered, onFilter, setActioned } = useUrlFilteredActioned<
    IStudentHomeworkRequest | IStudentClassQuizzesRequest,
    IStudentHomeworksPageActions | IStudentsPageActions
  >(
    type == 'homework' ? homeworksFilters : classQuizzesFilters,
    type == 'homework' ? studentHomeworksPageActions : classQuizzesPageActions,
  );

  const [opened, { close, open }] = useDisclosure(false);
  const [currentHomework, setCurrentHomework] = useState<IHomeworkSession>();

  const studentQuery = api.user.students.useStudent({ id: id ?? '' });
  const student = studentQuery.data?.data;

  const quizQuery =
    type == 'homework'
      ? api.education.homework.useStudentHomeworks({
          ...filtered,
          studentId: id,
        })
      : api.education.classQuiz.useStudentClassQuizzes({
          ...filtered,
          studentId: id,
        });

  const studentResetPassword = api.user.students.useStudentResetPassword();

  return (
    <div>
      <PageMeta
        goBack={{ i18Title: 'back', url: '/classes' }}
        openMenuKeys={['classes']}
        selectedMenuKeys={['classes-my']}
        title={t('studentDetails')}
      />

      {studentQuery.isLoading ? (
        <div className="flex justify-center">
          <Loader />
        </div>
      ) : !student ? (
        <NotFoundResult subTitle="" title={t('notFoundResult.noStudent')} />
      ) : (
        <>
          <span className="text-2xl font-bold">{t('studentProfile')}</span>
          <div className="p-6 border border-gray-200 my-8 rounded-lg bg-white">
            <div className="flex justify-between items-center">
              <div className="flex gap-6">
                <div className="w-12 h-12 flex items-center justify-center rounded-lg border-2 border-primary-400 bg-[#F1ECFE]">
                  <StudentIcon className="text-primary-400" />
                </div>
                <div className="flex flex-col">
                  <span className="font-bold text-lg">{`${student?.firstName} ${student?.lastName} ${student?.middleName}`}</span>
                  <span className="text-gray-500">
                    {student?.birthDate ? moment(student.birthDate).format('DD.MM.YYYY') : '-'}
                  </span>
                </div>
              </div>

              <div className="flex items-center gap-4">
                {student?.groupName && (
                  <Button classNames={{ root: 'py-1.5 px-4' }} color="gray" variant="outline">{`${
                    school?.educationFormat === 'GENERAL' ? t('class') : t('group')
                  } ${student.groupName}`}</Button>
                )}
                <StatusTag status={student?.status ?? 'DRAFT'} />

                <Menu shadow="md" variant="actions" withinPortal zIndex={10}>
                  <Menu.Target>
                    <Button
                      className="rounded-full border-transparent bg-[#F6F4FF] p-0 min-w-[32px] h-[32px]"
                      radius="xl"
                      variant="outline"
                    >
                      <SettingsIcon className="text-primary-500" />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      icon={<EditIcon />}
                      onClick={() => {
                        setActioned({ action: 'editStudent', actionId: student.id });
                      }}
                    >
                      {t('edit')}
                    </Menu.Item>
                    <Menu.Item
                      icon={<TrashIcon />}
                      onClick={() => {
                        setActioned({ action: 'excludeStudentFromClass', actionId: student.id });
                      }}
                    >
                      {school?.educationFormat == 'GENERAL'
                        ? t('classesPage.excludeFromClass')
                        : t('classesPage.excludeFromGroup')}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!school?.isSuperCoach || student.schoolId !== school?.schoolId}
                      icon={<TrashIcon />}
                      onClick={() => {
                        setActioned({ action: 'excludeStudentFromSchool', actionId: student.id });
                      }}
                    >
                      {t('classesPage.excludeFromSchool')}
                    </Menu.Item>
                    <Menu.Item
                      icon={student?.status === 'ACTIVE' ? <PauseIcon /> : <PlayCircleIcon />}
                      onClick={() => {
                        setActioned({
                          action:
                            student.status === 'ACTIVE' ? 'deactivateStudent' : 'activateStudent',
                          actionId: student.id ?? '',
                        });
                      }}
                    >
                      {student?.status === 'ACTIVE' ? t('pause') : t('activate')}
                    </Menu.Item>
                    <Menu.Item
                      icon={<LockKeyOpenIcon />}
                      onClick={() => {
                        studentResetPassword.mutateAsync({ id: student.id ?? '' });
                      }}
                    >
                      {t('classesPage.resetPassword')}
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            </div>

            <div className="grid grid-cols-2 my-8">
              <div className="flex flex-col gap-6">
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">{t('email')}</span>
                  <span>{student?.email}</span>
                </div>
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">{t('birthDate')}</span>
                  <span>
                    {student?.birthDate ? moment(student.birthDate).format('DD.MM.YYYY') : '-'}
                  </span>
                </div>
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">
                    {school?.educationFormat == 'GENERAL' ? t('class') : t('group')}
                  </span>
                  <span>{student?.groupName}</span>
                </div>
              </div>
              <div className="flex flex-col gap-6">
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">{t('gender.title')}</span>
                  <span>
                    {getStudentGenders().find((gender) => gender.value === student?.gender)
                      ?.label || '-'}
                  </span>
                </div>
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">{t('phone')}</span>
                  <span>{student?.phone || '-'}</span>
                </div>
                <div className="flex flex-col text-[#5f6574]">
                  <span className="font-bold">{t('iin')}</span>
                  <span>{student?.iin || '-'}</span>
                </div>
              </div>
            </div>
          </div>
          <Tabs
            defaultValue="homework"
            onTabChange={(value) => {
              navigate(`/classes/student/${id}/${value === 'classQuiz' ? 'classQuiz' : ''}`);
            }}
            unstyled
          >
            <Tabs.List className="mb-5 bg-transparent">
              <Tabs.Tab className="mr-4" value="homework">
                {t('homework')}
              </Tabs.Tab>
              <Tabs.Tab value="classQuiz">{t('assignment')}</Tabs.Tab>
            </Tabs.List>
          </Tabs>
          <QueryTable
            actions={(row) => [
              ...(type == 'homework'
                ? [
                    {
                      icon: <EditIcon />,
                      onClick: () => {
                        setCurrentHomework(row);
                        open();
                      },
                      title: t('homeworksPage.editDueDate'),
                    },
                  ]
                : []),
            ]}
            columns={
              type == 'homework'
                ? studentHomeworkSessionColumns(school?.educationFormat ?? 'GENERAL')
                : (studentClassQuizSessionColumns(
                    school?.educationFormat ?? 'GENERAL',
                  ) as MRT_ColumnDef<IHomeworkSession>[])
            }
            empty={{ description: '', title: t('classesPage.noHomework') }}
            query={
              quizQuery as UseQueryResult<
                AxiosResponse<IPageAbleResponse<IHomeworkSession>>,
                AxiosError
              >
            }
            tableFilter={{
              filtered,
              filters: type == 'homework' ? homeworksFilters : classQuizzesFilters,
              onFilter,
            }}
          />
          <AssignStudentClassModal
            id={actioned.actionId}
            isOpen={actioned.action === 'assignStudent'}
            onCancel={clearParams}
          />
          <CreateStudentModal isOpen={actioned.action === 'createStudent'} onCancel={clearParams} />
          <EditStudentModal
            id={actioned.actionId}
            isOpen={actioned.action === 'editStudent'}
            onCancel={clearParams}
          />
          <ExcludeStudentModal
            actionType={actioned.action}
            id={actioned.actionId}
            isOpen={
              actioned.action === 'excludeStudentFromClass' ||
              actioned.action === 'excludeStudentFromSchool'
            }
            onCancel={clearParams}
          />
          <ActivateStudentModal
            id={actioned.actionId}
            isOpen={
              actioned.action === 'deactivateStudent' || actioned.action === 'activateStudent'
            }
            onClose={clearParams}
          />
          <HomeworkEditModal
            homeworkId={currentHomework?.homeWorkId ?? ''}
            id={currentHomework?.id}
            isOpen={opened}
            onCancel={close}
            refetch={quizQuery.refetch}
            sessions={currentHomework ? [currentHomework] : []}
          />
        </>
      )}
    </div>
  );
};
