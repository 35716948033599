import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal } from '@mantine/core';

import { api } from '@chess-class/sandbox';

type IHomeworkDeleteModal = {
  id?: string;
  isOpen: boolean;
  onCancel: () => void;
};

export const HomeworkDeleteModal: FC<IHomeworkDeleteModal> = ({ id = '', isOpen, onCancel }) => {
  const { t } = useTranslation();
  const homeworkQuery = api.education.homework.useCoachHomework({ id });
  const homeworkDelete = api.education.homework.useCoachHomeworkDelete();

  const homework = homeworkQuery.data?.data;

  return (
    <Modal centered onClose={onCancel} opened={isOpen} size={570}>
      <div className="mb-13 max-w-[220px] mx-auto mt-5 text-center font-semibold text-gray-600 text-base">
        {t('homeworksPage.deleteHomeworkMessage', {
          name: homework?.id,
        })}
      </div>

      <Divider className="mb-8 -mx-6" />

      <div className="grid grid-cols-2 gap-4">
        <Button
          loading={homeworkDelete.isLoading}
          onClick={() => {
            homeworkDelete.mutateAsync({ id }).then(() => {
              onCancel();
            });
          }}
        >
          {t('delete')}
        </Button>
        <Button onClick={onCancel} variant="outline">
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
