import { type FC } from 'react';
import ReactGA from 'react-ga4';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Modal } from '@mantine/core';

import { api } from '@chess-class/sandbox';

type ActivateStudentModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ActivateStudentModal: FC<ActivateStudentModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const studentQuery = api.user.students.useStudent({ id });
  const activateStudent = api.user.students.useStudentActivate();
  const deactivateStudent = api.user.students.useStudentDeactivate();

  const student = studentQuery.data?.data;

  const fullName = student
    ? `${student?.lastName} ${student?.firstName} ${student?.middleName}`
    : '';

  const handleSubmit = () => {
    if (student?.status === 'ACTIVE') {
      deactivateStudent.mutateAsync({ id: student.id }).then(() => {
        onClose();
      });
      ReactGA.event({
        action: 'suspend_student',
        category: 'suspend_student',
        label: 'suspend_student',
      });
    } else {
      activateStudent.mutateAsync({ id: student?.id ?? '' }).then(() => {
        onClose();
      });
      ReactGA.event({
        action: 'activate_student',
        category: 'activate_student',
        label: 'activate_student',
      });
    }
  };

  return (
    <Modal centered onClose={onClose} opened={isOpen} size={776}>
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold mb-13 mt-5">
        {student?.status === 'ACTIVE'
          ? t('classesPage.deactivateStudentMessage', { fullName })
          : t('classesPage.activateStudentMessage', { fullName })}
      </div>

      <Divider className="mb-8 -mx-6" />

      <div className="grid gap-4 grid-cols-2">
        <Button
          loading={activateStudent.isLoading || deactivateStudent.isLoading}
          onClick={handleSubmit}
        >
          {student?.status === 'ACTIVE' ? t('pause') : t('activate')}
        </Button>
        <Button onClick={onClose} variant="outline">
          {t('cancel')}
        </Button>
      </div>
    </Modal>
  );
};
