import { t } from 'i18next';

export enum TimeSortEnum {
  NEWEST = 'NEWEST',
  OLDEST = 'OLDEST',
}

export const getTimeSort = [
  {
    label: t('oldestFirst'),
    value: TimeSortEnum.OLDEST,
  },
  {
    label: t('newestFirst'),
    value: TimeSortEnum.NEWEST,
  },
];
