import { type FC } from 'react';
import { Accordion, clsx, RingProgress } from '@mantine/core';

import { colors } from '@chess-class/sandbox/consts';
import { Check2Icon, Student2Icon } from '@chess-class/sandbox/icons';

export type ExerciseAnswersAccordionProps = {
  status?: 'good' | 'unsolved' | 'fail';
  students: {
    email?: string;
    firstName: string;
    id: string;
    lastName: string;
    middleName: string;
  }[];
  test?: boolean;
  title: string;
  totalStudents?: number;
};

export const ExerciseAnswersAccordion: FC<ExerciseAnswersAccordionProps> = ({
  status = false,
  students,
  test,
  title,
  totalStudents = 0,
}) => {
  return (
    <Accordion
      classNames={{
        content: 'p-3 lg:px-6 lg:py-5',
        control: 'p-3 mb-0 lg:px-6 lg:py-5 flex items-center',
        label: 'p-0 flex items-center gap-4 sm:gap-6 justify-between',
      }}
    >
      <Accordion.Item value="exercise-answer">
        <Accordion.Control disabled={!students.length}>
          <div className="flex gap-3 items-center text-primary-103 font-medium flex-grow">
            {status != 'unsolved' && test ? (
              <div
                className={clsx(
                  'rounded-full h-6 w-6 p-0.5 border',
                  status == 'good'
                    ? 'border-green-101/50'
                    : status == 'fail'
                      ? 'border-red-101/50'
                      : 'border-gray-100/50',
                )}
              >
                <div
                  className={clsx(
                    'w-full h-full rounded-full flex items-center justify-center pr-[1px]',
                    status == 'good'
                      ? 'bg-green-101'
                      : status == 'fail'
                        ? 'bg-red-101'
                        : 'bg-gray-100',
                  )}
                >
                  {status == 'good' && <Check2Icon className="w-full h-full text-white" />}
                </div>
              </div>
            ) : (
              <div
                className={clsx(
                  'p-2 rounded-lg',
                  status == 'good'
                    ? 'bg-green-102 text-green-103'
                    : status == 'fail'
                      ? 'bg-red-102 text-red-103'
                      : status == 'unsolved'
                        ? 'bg-orange-101 text-orange-102'
                        : 'bg-gray-100 text-gray-300',
                )}
              >
                <Student2Icon />
              </div>
            )}
            {title}
          </div>

          <div className="flex gap-8">
            <div className="font-semibold whitespace-nowrap">
              <span className="text-primary text-lg">{students.length}</span>
              <span className="text-gray-600"> / {totalStudents}</span>
            </div>

            <div className="flex flex-row items-center gap-1 text-gray-600 font-medium">
              <RingProgress
                rootColor={colors.gray['115']}
                sections={[
                  {
                    color: colors.primary['400'],
                    value: Math.round((students.length / totalStudents) * 100) || 0,
                  },
                ]}
                size={24}
                thickness={4}
              />
              {Math.round((students.length / totalStudents) * 100) || 0}%
            </div>
          </div>
        </Accordion.Control>

        <Accordion.Panel>
          {students.map((student) => (
            <div
              className="py-3 px-6 bg-gray-114 rounded-lg mb-3"
              key={student.id}
            >{`${student.lastName} ${student.firstName} ${student.middleName}`}</div>
          ))}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
