import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, PasswordInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import { api, ICoachResetConfirmPasswordType } from '@chess-class/sandbox';

import { PageMeta } from '~/context/PageMetaContext';

const AuthResetPasswordPage: FC = () => {
  const { link } = useParams();
  const { t } = useTranslation();
  const resetPassword = api.user.coachAuth.useCoachResetPassword();
  const navigate = useNavigate();

  const form = useForm<ICoachResetConfirmPasswordType>({
    initialValues: {
      ...api.user.coachAuth.helpers.coachResetPasswordInitial,
      linkCode: link || '',
    },
    validate: yupResolver(api.user.coachAuth.helpers.coachResetPasswordValidation),
  });

  return (
    <>
      <PageMeta title={t('auth.resetPassword')} />
      <form
        className="flex flex-col gap-8"
        onSubmit={form.onSubmit((values) => {
          resetPassword.mutateAsync(values).then(() => {
            navigate('/auth/signin');
          });
        })}
      >
        <div className="flex flex-col gap-6 text-center">
          <h2 className="text-xl sm:text-xl md:text-3xl text-gray-900 font-bold">
            {t('auth.recoverPassword')}
          </h2>
          <span className="text-gray-110 font-medium">{t('auth.enterNewPassword')}</span>
        </div>

        <PasswordInput
          label={t('auth.newPassword')}
          maxLength={30}
          name="password"
          placeholder="********"
          required
          {...form.getInputProps('password')}
        />

        <PasswordInput
          label={t('auth.confirmPassword')}
          maxLength={30}
          name="confirmPassword"
          placeholder="********"
          required
          {...form.getInputProps('confirmPassword')}
        />

        <div className="flex flex-col gap-4">
          <Button className="w-full text-base" loading={false} type="submit">
            {t('save')}
          </Button>
          <Link className="text-center" to="/auth/signin">
            <Button className="w-full text-base" loading={resetPassword.isLoading} variant="subtle">
              {t('goBack')}
            </Button>
          </Link>
        </div>
      </form>
    </>
  );
};

export default AuthResetPasswordPage;
