import { useTranslation } from 'react-i18next';
import { Button, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { IExercise } from '@chess-class/sandbox';
import { ChessboardExercise } from '@chess-class/sandbox/components';
import { INITIAL_FEN, storageKeys } from '@chess-class/sandbox/consts';

import { PageMeta } from '~/context/PageMetaContext';

import { BoardEditorModal } from '../components/organisms/BoardEditorModal';

export default function AnalyzeGamePage() {
  const exercise = {
    problem: {
      boardState: localStorage.getItem(storageKeys.fen)
        ? localStorage.getItem(storageKeys.fen)
        : INITIAL_FEN,
    },
  };
  const { t } = useTranslation();
  const [isBoardEditorOpen, boardEditorOpen] = useDisclosure(false);

  return (
    <div className="flex flex-col gap-10">
      <PageMeta selectedMenuKeys={['analyze-game']} title={t('boardEditorPage.boardEditor')} />
      <div className="flex items-center gap-4 justify-between">
        <Title order={2}>{t('analysisBoard')}</Title>
        <Button className="py-4 h-13" onClick={boardEditorOpen.open} variant="filled">
          {t('boardEditorPage.boardEditor')}
        </Button>
      </div>
      <div className="py-5 px-5 flex h-full gap-8 justify-center">
        <ChessboardExercise
          chessBoardClassName="!w-auto lg:w-auto"
          exercise={exercise as IExercise}
          sidebar={{
            moveActions: true,
            solutionBox: {
              initialShowMoves: true,
              show: true,
              solutionButton: true,
              stockfish: true,
            },
            yourMoveBox: { hintButton: false, show: false },
          }}
          wrapperClassName="flex-wrap"
        />
      </div>
      <BoardEditorModal onClose={boardEditorOpen.close} opened={isBoardEditorOpen} />
    </div>
  );
}
