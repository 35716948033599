import { Route, Routes } from 'react-router-dom';

import { ClassQuizDetailsPage } from '~mClassQuizzes/pages/ClassQuizDetailsPage';
import ClassQuizzesPage from '~mClassQuizzes/pages/ClassQuizzesPage';

export const ClassQuizRouter = () => {
  return (
    <Routes>
      <Route element={<ClassQuizzesPage />} index />
      <Route element={<ClassQuizDetailsPage />} path="/:id" />
    </Routes>
  );
};
