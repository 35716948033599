import { IPageActions } from '@chess-class/sandbox';

enum IChaptersPageActionsEnum {
  'rate',
}

export type IChapterPageActions = {
  action: keyof typeof IChaptersPageActionsEnum;
  actionId: string;
};

export const chapterPageActions: IPageActions<IChapterPageActions> = {
  action: {
    allowed: (value) => Object.keys(IChaptersPageActionsEnum).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
