export const groupBy = <T extends object>(items?: T[], key = ''): Record<string, T[]> =>
  !items?.length
    ? {}
    : items.reduce(
        (result, item) => ({
          ...result,
          [item[key]]: [...(result[item[key]] || []), item],
        }),
        {},
      );
