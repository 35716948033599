import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Skeleton, Tabs } from '@mantine/core';

import { api, IClassQuizExercise, IExercisesRequest } from '@chess-class/sandbox';
import { NotFoundResult, QueryTableStatic } from '@chess-class/sandbox/components';
import { paginationBaseFilters } from '@chess-class/sandbox/consts';
import { useUrlFilteredActioned } from '@chess-class/sandbox/hooks';

import { PageMeta } from '~/context/PageMetaContext';
import { groupBy } from '~/utils/groupBy';

import ClassQuizCardTable from '~mClassQuizzes/components/molecules/ClassQuizCardTable';
import ClassQuizBox from '~mClassQuizzes/components/organisms/ClassQuizBox';
import { ExerciseProblemModal } from '~mClassQuizzes/components/organisms/ExerciseProblemModal';
import { classQuizSessionsColumns } from '~mClassQuizzes/consts/columns/classQuizSessionsColumns';
import {
  classQuizTestPageActions,
  IExercisePageActions,
} from '~mClassQuizzes/consts/pageActions/IExercisePageActions';

import { ExerciseTestModal } from '../components/organisms/ExerciseTestModal';

export const ClassQuizDetailsPage: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { actioned, clearParams, setPartialActioned } = useUrlFilteredActioned<
    IExercisesRequest,
    IExercisePageActions
  >(paginationBaseFilters, classQuizTestPageActions);

  const classQuizQuery = api.education.classQuiz.useCoachClassQuiz({ id });
  const classQuiz = classQuizQuery.data?.data;

  const classQuizStudentsQuery = api.education.classQuiz.useCoachClassQuizStudents({ id });
  const classQuizStudents = classQuizStudentsQuery?.data?.data;

  const groupedClassQuizzes = useMemo(() => {
    return classQuiz?.exercises ? groupBy(classQuiz?.exercises, 'chapterId') : [];
  }, [classQuiz]);

  const exerciseIndex = classQuiz?.exercises.findIndex((ex) => ex.id === actioned.actionId) ?? 0;

  return (
    <>
      <PageMeta
        goBack={{ i18Title: 'back', url: '/classQuiz' }}
        openMenuKeys={['classQuiz']}
        selectedMenuKeys={['classQuiz']}
        title={t('assignment')}
      />
      <div className="flex justify-between mb-8">
        <div className="flex flex-col">
          <h1 className="font-bold">
            {t('assignment')} {classQuiz ? `#${classQuiz.orderByClass}` : ''}
          </h1>
        </div>
      </div>

      {classQuizQuery.isLoading ? (
        <div className="flex flex-col gap-8">
          <Skeleton className="w-full h-40" />
          <Skeleton className="w-full h-40" />
        </div>
      ) : !classQuiz ? (
        <NotFoundResult
          subTitle={t('notFoundResult.goBackToAssignments')}
          title={t('notFoundResult.noAssignment')}
        />
      ) : (
        <>
          <ClassQuizBox classQuiz={classQuiz} />
          <Tabs defaultValue="students" unstyled>
            <Tabs.List className="mt-8 mb-5 bg-transparent">
              <Tabs.Tab className="mr-4" value="students">
                {t('students')}
              </Tabs.Tab>
              <Tabs.Tab value="problems">{t('problems')}</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="students">
              <QueryTableStatic
                columns={classQuizSessionsColumns}
                data={classQuizStudents || []}
                empty={{ title: t('notFoundResult.noChapterExercises') }}
                // enableFilters
                status={(row) => row}
              />
            </Tabs.Panel>
            <Tabs.Panel value="problems">
              {Object.values(groupedClassQuizzes).length != 0 &&
                Object.values(groupedClassQuizzes).map((classQuiz, index) => (
                  <ClassQuizCardTable
                    classQuiz={classQuiz as IClassQuizExercise[]}
                    index={index}
                    key={index}
                    setPartialActioned={setPartialActioned}
                  />
                ))}
              {Object.values(groupedClassQuizzes).length == 0 && (
                <div className="flex items-center flex-col justify-center py-8 gap-4">
                  <img
                    className="h-40 object-contain w-40"
                    src="/images/illustrations/pawn-search.png"
                  />
                  <div className="text-center flex flex-col gap-2">
                    <span className="text-lg text-primary-900 font-semibold">{t('noResult')}</span>
                  </div>
                </div>
              )}
            </Tabs.Panel>
          </Tabs>
          <ExerciseTestModal
            exerciseId={actioned.actionId ?? ''}
            isBackButtonDisabled={exerciseIndex == 0}
            isNextButtonDisabled={exerciseIndex >= classQuiz?.exercises.length - 1}
            isOpen={actioned.action === 'view' && actioned.actionType === 'test'}
            onBackButtonClick={() =>
              setPartialActioned({
                action: 'view',
                actionId: `${classQuiz.exercises[exerciseIndex - 1].id}`,
                actionType: 'test',
              })
            }
            onCancel={clearParams}
            onNextButtonClick={() => {
              setPartialActioned({
                action: 'view',
                actionId: `${classQuiz.exercises[exerciseIndex + 1].id}`,
                actionType: 'test',
              });
            }}
          />
          <ExerciseProblemModal
            exerciseId={actioned.actionId ?? ''}
            isOpen={actioned.action === 'view' && actioned.actionType === 'exercise'}
            onCancel={clearParams}
          />
        </>
      )}
    </>
  );
};
