import { IPageActions } from '@chess-class/sandbox';

export enum IEnumStudentsPageActions {
  'editStudent',
  'assignStudent',
  'createStudent',
  'activateStudent',
  'deactivateStudent',
  'excludeStudentFromClass',
  'excludeStudentFromSchool',
}

export type IStudentsPageActions = {
  action: keyof typeof IEnumStudentsPageActions;
  actionId: string;
  actionType: 'ASSIGNED' | 'UNASSIGNED';
};

export const studentsPageActions: IPageActions<IStudentsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumStudentsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
  actionType: { default: 'ASSIGNED' },
};
