import { t } from 'i18next';

export enum LanguageEnum {
  DE = 'de',
  EN = 'en',
  FR = 'fr',
  KZ = 'kz',
  RU = 'ru',
}

export const classLanguageList = [
  {
    label: 'Қазақша',
    value: LanguageEnum.KZ,
  },
  {
    label: 'Русский',
    value: LanguageEnum.RU,
  },
  {
    label: 'English',
    value: LanguageEnum.EN,
  },
];

export const getLanguageList = () => [
  {
    label: t('language.kz'),
    value: LanguageEnum.KZ,
  },
  {
    label: t('language.ru'),
    value: LanguageEnum.RU,
  },
  {
    label: t('language.en'),
    value: LanguageEnum.EN,
  },
  {
    label: t('language.fr'),
    value: LanguageEnum.FR,
  },
  {
    label: t('language.de'),
    value: LanguageEnum.DE,
  },
];
