import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { api, ICoach, ICoachSchool, IGroup, IGuideline } from '@chess-class/sandbox';
import { transliterate } from '@chess-class/sandbox/utils';

import { storageKeys } from '~/consts/storageKeys';

type CoachContextValues = {
  coachMe?: ICoach;
  coachMeError: boolean;
  coachMeFetching: boolean;
  coachMeLoading: boolean;
  guidelines?: IGuideline[];
  myClasses?: IGroup[];
  onChangeSchool: (school: ICoachSchool) => void;
  school?: ICoachSchool;
  schoolLoading?: boolean;
};

export const CoachContext = createContext<CoachContextValues>({
  coachMe: undefined,
  coachMeError: false,
  coachMeFetching: !!localStorage.getItem(storageKeys.tokenAccess),
  coachMeLoading: !!localStorage.getItem(storageKeys.tokenAccess),
  guidelines: [],
  myClasses: undefined,
  onChangeSchool: () => {
    //
  },
  school: undefined,
  schoolLoading: true,
});

export const CoachContextProvider: FC<{ children?: ReactNode }> = (props) => {
  const [school, setSchool] = useState<CoachContextValues['school']>();

  const coachMeQuery = api.user.coachAuth.useCoachMe();
  const coachMe = coachMeQuery.data;
  const schoolQuery = api.schools.schools.useSchool({ id: school?.schoolId || '' });
  const guidelines = useMemo(
    () => schoolQuery.data?.data.guidelines?.sort((a, b) => a.grade - b.grade) || [],
    [schoolQuery.data?.data.guidelines],
  );

  const classesQuery = api.schools.groups.useGroups({
    coachId: coachMe?.id,
    enabled: !!coachMe?.id && !!school?.schoolId,
    page: 0,
    refetchOnMount: false,
    schoolIds: [school?.schoolId || ''],
    size: 9999,
    sort: 'grade',
  });

  const myClasses = useMemo(
    () =>
      classesQuery.data?.data.content.sort(
        ({ grade: aGrade, symbol: aSymbol }, { grade: bGrade, symbol: bSymbol }) => {
          const grade = (aGrade as number) - (bGrade as number);
          if (grade != 0) {
            return grade;
          }
          const aTransliterated = transliterate(aSymbol || '');
          const bTransliterated = transliterate(bSymbol || '');
          return aTransliterated == bTransliterated ? 0 : Number(aTransliterated < bTransliterated);
        },
      ),
    [classesQuery.data?.data.content],
  );

  const onChangeSchool = (school?: ICoachSchool) => {
    if (school?.schoolId) {
      localStorage.setItem(storageKeys.schoolId, school.schoolId);
    } else {
      localStorage.removeItem(storageKeys.schoolId);
    }
    setSchool(school);
  };

  useEffect(() => {
    if (coachMe?.schools?.length) {
      const storageSchool = coachMe.schools.find(
        (school) => school.schoolId === localStorage.getItem(storageKeys.schoolId),
      );

      if (storageSchool?.status == 'ACTIVE') {
        onChangeSchool(storageSchool);
      } else {
        onChangeSchool(coachMe.schools.find((school) => school.status == 'ACTIVE'));
      }
    }
  }, [coachMe?.schools]);

  return (
    <CoachContext.Provider
      value={{
        coachMe: coachMe,
        coachMeError: coachMeQuery.isError,
        coachMeFetching: coachMeQuery.isFetching,
        coachMeLoading: coachMeQuery.isLoading,
        guidelines,
        myClasses,
        onChangeSchool,
        school,
        schoolLoading: coachMeQuery.isLoading || schoolQuery.isLoading || schoolQuery.isLoading,
      }}
    >
      {props.children}
    </CoachContext.Provider>
  );
};

export const useCoachContext = () => {
  const coachContext = useContext(CoachContext);

  if (typeof coachContext === 'undefined') {
    throw new Error('useCoachContext must be used within a CoachContextProvider');
  }

  return coachContext;
};
