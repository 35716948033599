import { useContext } from 'react';
import { t } from 'i18next';
import { Title } from '@mantine/core';

import { IllustrationHorseOrangeIcon } from '@chess-class/sandbox/icons';

import { CoachContext } from '~/context/CoachContext';

export default function GroupNotFound() {
  const { school } = useContext(CoachContext);
  return (
    <div className="w-full h-full bg-white rounded-xl border flex flex-col gap-6 justify-center items-center p-6 mt-6">
      <IllustrationHorseOrangeIcon className="h-60 w-44 object-contain max-h-[40%]" />
      <Title order={2}>
        {school?.educationFormat == 'GENERAL'
          ? t('notFoundResult.createClass')
          : t('notFoundResult.createGroup')}
      </Title>
      <span className="text-gray-400 font-medium">
        {school?.educationFormat == 'GENERAL'
          ? t('notFoundResult.createClassHelpMessage')
          : t('notFoundResult.createGroupHelpMessage')}
      </span>
    </div>
  );
}
