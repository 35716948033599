import { Tooltip } from '@mantine/core';

import { colors } from '@chess-class/sandbox/consts';

export default function DevColorsTab() {
  return (
    <div className="flex flex-col gap-12">
      {Object.entries(colors).map(([colorName, group]) => (
        <div className="flex flex-col gap-4" key={colorName}>
          <span
            className="text-2xl font-semibold capitalize"
            style={{ color: group['400'] || group['101'] }}
          >
            {colorName}
          </span>
          <div className="flex flex-wrap">
            {Object.entries(group).map(([color, colorHex]) => (
              <Tooltip key={`${colorName}-${color}`} label={colorHex as string}>
                <div className="w-36 flex flex-col items-center">
                  <div className="h-8 w-full" style={{ backgroundColor: colorHex as string }} />
                  <span className="text-gray-500">{`${colorName}-${color}`}</span>
                </div>
              </Tooltip>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
